import { useTranslation } from 'react-i18next';
import ActionButton, {
    type ActionType,
} from 'src/features/devices/tablets/components/actionButton/ActionButton';
import TabletScreen from 'src/features/devices/tablets/components/screens/TabletScreen';
import { type Device } from 'src/utils/global/types';

interface NextButtonScreenProps {
    deviceColor: Device;
    action: ActionType;
}
const NextButtonScreen: React.FC<NextButtonScreenProps> = ({
    deviceColor,
    action,
}) => {
    const { t } = useTranslation('global');
    return (
        <TabletScreen
            typeBackground="circle"
            step="overlay"
            deviceColor={deviceColor}
        >
            <ActionButton
                title={t('buttons.next')}
                action={action}
                type="neutral"
            />
        </TabletScreen>
    );
};

export default NextButtonScreen;
