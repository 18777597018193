import { useEffect } from 'react';

import styles from 'src/features/devices/tv/components/games/game3/Game3Action.module.scss';
import Board from 'src/features/devices/tv/components/games/game3/board/Board';

import { useTetris } from 'src/features/devices/tv/components/games/game3/hooks/useTetris';
import UpcomingBlocks from 'src/features/devices/tv/components/games/game3/upcoming/UpcomingBlocks';
import { useAppDispatch, useAppSelector } from 'src/services/hooks';
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import {
    selectButtonTetris,
    selectItemsFoundGame3,
    selectItemsSelectedGame3,
    selectStatusTetris,
} from 'src/services/reducers/games/game3Slice';
import { selectRoomName } from 'src/services/reducers/roomSlice';
import {
    handleTabletStatus,
    validateGameAction,
} from 'src/utils/device/deviceHandleFunctions';
import { getSocket } from 'src/utils/global/globalHandleFunctions';
import {
    type Block,
    type GameStatusActionWithoutGame5,
} from 'src/utils/global/types';

const Game3: React.FC = () => {
    const socket = getSocket();
    const dispatch = useAppDispatch();

    const {
        board,
        startTetrisGame,
        upcomingBlocks,
        handleClick,
        updateUpcomingBlocks,
        tetrisFinish,
        tetrisAutoCompletion,
    } = useTetris();

    const roomName = useAppSelector(selectRoomName);

    const statusTetris = useAppSelector(selectStatusTetris);
    const buttonTetris = useAppSelector(selectButtonTetris);
    const itemsSelected = useAppSelector(selectItemsSelectedGame3);
    const itemsFound = useAppSelector(selectItemsFoundGame3);
    const currentGameStatus = useAppSelector(selectCurrentGameStatus);

    useEffect(() => {
        const selectedBlocks = [];
        switch (statusTetris) {
            case 'start':
                startTetrisGame(itemsSelected);
                break;
            case 'not-ready':
                if (itemsSelected?.tetrisfanny) {
                    selectedBlocks.push({
                        tetris: itemsSelected.tetrisfanny,
                        character: 'fanny',
                    } as Block);
                }
                if (itemsSelected?.tetrispaul) {
                    selectedBlocks.push({
                        tetris: itemsSelected.tetrispaul,
                        character: 'paul',
                    } as Block);
                }
                if (itemsSelected?.tetriseric) {
                    selectedBlocks.push({
                        tetris: itemsSelected.tetriseric,
                        character: 'eric',
                    } as Block);
                }
                updateUpcomingBlocks(selectedBlocks);
                break;
            case 'tetris-auto-completed':
                tetrisAutoCompletion(itemsSelected?.emotion?.id);
                break;
            default:
                break;
        }
    }, [
        itemsSelected,
        startTetrisGame,
        statusTetris,
        updateUpcomingBlocks,
        tetrisAutoCompletion,
        roomName,
    ]);

    useEffect(() => {
        if (buttonTetris.typeMouse && buttonTetris.actionMouse)
            handleClick(buttonTetris.typeMouse, buttonTetris.actionMouse);
    }, [buttonTetris.actionMouse, buttonTetris.typeMouse, handleClick]);
    useEffect(() => {
        if (
            itemsSelected.emotion &&
            itemsSelected.tetriseric &&
            itemsSelected.tetrisfanny &&
            itemsSelected.tetrispaul &&
            tetrisFinish
        ) {
            handleTabletStatus(
                {
                    blue: false,
                    green: false,
                    orange: false,
                    red: true,
                },
                roomName
            );
            validateGameAction({
                itemsSelected,
                currentBoard: board,
                gameStatus: currentGameStatus as GameStatusActionWithoutGame5,
                roomName,
            });

            socket?.emit('send_update_status_tetris', {
                roomName,
                statusTetris: 'waiting-validation',
            });
        }
    }, [
        board,
        currentGameStatus,
        dispatch,
        itemsFound,
        itemsSelected,
        roomName,
        socket,
        tetrisFinish,
    ]);

    return (
        <div className={styles.boardTetris}>
            <Board currentBoard={board} />
            <UpcomingBlocks upcomingBlocks={upcomingBlocks} />
        </div>
    );
};

export default Game3;
