import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from 'src/services/store';

// utils
import {
    GameStatus,
    type Emotion,
    type Game3Action,
    type HeaderContent,
} from 'src/utils/global/types';

import headerDatas from 'src/services/datas/actionContent.json';
import dataGlobal from 'src/services/datas/global.json';

interface UpdateItemsSelectedPayload {
    item: Emotion | string;
    itemKey: keyof Game3Action;
}

interface ButtonTetris {
    typeMouse: string | undefined;
    actionMouse: string | undefined;
}

export interface Game3State {
    itemsSelected: Game3Action;
    itemsFound: Emotion[];
    headerContent: HeaderContent;
    statusTetris: string;
    buttonTetris: ButtonTetris;
}

const initialState: Game3State = {
    itemsSelected: {} as Game3Action,
    itemsFound: new Array(dataGlobal.emotions.length).fill(null),
    headerContent: headerDatas[GameStatus.GAME3ACTION].tv.headerContent,
    statusTetris: 'not-ready',
    buttonTetris: {
        typeMouse: undefined,
        actionMouse: undefined,
    },
};

export const Game3Slice = createSlice({
    name: 'game3',
    initialState,
    reducers: {
        updateItemsSelectedGame3: (
            state,
            action: PayloadAction<UpdateItemsSelectedPayload>
        ) => {
            const { itemKey: type, item } = action.payload;
            state.itemsSelected = {
                ...state.itemsSelected,
                [type]: item,
            };
        },
        resetItemsSelectedGame3: (state) => {
            state.itemsSelected = initialState.itemsSelected;
        },
        updateItemsFoundGame3: (state, action: PayloadAction<Emotion>) => {
            const indexItem = action.payload.id - 1;
            state.itemsFound[indexItem] = action.payload;
        },
        updateStatusTetris: (state, action: PayloadAction<string>) => {
            state.statusTetris = action.payload;
        },
        clickButtonTetris: (state, action: PayloadAction<ButtonTetris>) => {
            state.buttonTetris.typeMouse = action.payload.typeMouse;
            state.buttonTetris.actionMouse = action.payload.actionMouse;
        },
        resetItemsFoundGame3: (state) => {
            state.itemsFound = initialState.itemsFound;
        },
    },
});

export const {
    updateItemsSelectedGame3,
    resetItemsSelectedGame3,
    updateItemsFoundGame3,
    updateStatusTetris,
    clickButtonTetris,
    resetItemsFoundGame3,
} = Game3Slice.actions;

export const selectItemsFoundGame3 = (state: RootState): Emotion[] =>
    state.game3.itemsFound;

export const selectItemsSelectedGame3 = (state: RootState): Game3Action =>
    state.game3.itemsSelected;

export const selectStatusTetris = (state: RootState): string =>
    state.game3.statusTetris;

export const selectButtonTetris = (state: RootState): ButtonTetris =>
    state.game3.buttonTetris;

export default Game3Slice.reducer;
