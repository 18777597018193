import styles from 'src/features/devices/tv/components/games/game3/Game3Action.module.scss';
import { useAppSelector } from 'src/services/hooks';
import { selectItemsSelectedGame3 } from 'src/services/reducers/games/game3Slice';
import { type Block } from 'src/utils/global/types';

interface CellProps {
    type: Block;
    rowIndex: number;
    colIndex: number;
}

const Cell: React.FC<CellProps> = ({ type, colIndex, rowIndex }: CellProps) => {
    const itemsSelected = useAppSelector(selectItemsSelectedGame3);

    return (
        <div
            className={`${styles.cell} ${
                styles[
                    `row-${rowIndex}-col-${colIndex}-emotion-${itemsSelected?.emotion?.id}`
                ]
            } ${`${styles[`${type.tetris}`]}`} ${`${styles[`${type.character}`]}`}`}
        />
    );
};

export default Cell;
