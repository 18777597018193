import { motion } from 'framer-motion';
import { useAppSelector } from 'src/services/hooks';

// scss
import globalStyle from 'src/styles/app.module.scss';
import styles from '/src/features/devices/tablets/orange/games/game4/chooseBehavior.module.scss';

// components
import TabletScreen from 'src/features/devices/tablets/components/screens/TabletScreen';

// utils
import {
    handleItemSelected,
    handleSendCarouselValues,
    handleTabletStatus,
} from 'src/utils/device/deviceHandleFunctions';
import { type Behavior, Device, type ItemsSelected } from 'src/utils/global/types';

// services
import { useEffect } from 'react';
import { RightArrow } from 'src/assets/images/tablets';
import { Swipe } from 'src/assets/sounds/soundsEffect';
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import {
    selectCarouselValues,
    selectItemsSelectedGame4,
    selectListBehaviors,
} from 'src/services/reducers/games/game4Slice';
import { selectRoomName } from 'src/services/reducers/roomSlice';
import { playSound } from 'src/utils/global/globalHandleFunctions';

const ChooseBehavior: React.FC = () => {
    const roomName = useAppSelector(selectRoomName);
    const currentGameStatus = useAppSelector(selectCurrentGameStatus);
    const deviceColor = Device.orange;

    const itemsSelected = useAppSelector(selectItemsSelectedGame4);
    const { rightIndex, centerIndex, leftIndex } =
        useAppSelector(selectCarouselValues);
    const listBehaviors = useAppSelector(selectListBehaviors);

    const itemsFound = useAppSelector(selectItemsSelectedGame4);

    const getBehavior = (
        currentId: number,
        direction: 'next' | 'previous'
    ): Behavior | undefined => {
        const behaviorsCount = listBehaviors.length;
        let newId = direction === 'next' ? currentId - 1 : currentId + 1;
        newId = ((newId + behaviorsCount - 1) % behaviorsCount) + 1;

        return listBehaviors.find((behavior) => behavior.id === newId);
    };

    const handleNextItemSelected = (type: 'next' | 'previous') => {
        if (!itemsSelected.behavior) return;

        const item = getBehavior(itemsSelected.behavior.id, type);
        if (item) {
            handleItemSelected(
                item as unknown as ItemsSelected,
                'behavior',
                currentGameStatus,
                roomName
            );
        }
    };

    const handleIndexChange = (
        indexName: 'leftIndex' | 'rightIndex' | 'centerIndex',
        mode: 'increment' | 'decrement',
        currentIndex: number,
        roomName: string
    ) => {
        const behaviorsCount = listBehaviors.length;

        let newIndex = 0;
        if (mode === 'increment') {
            newIndex = (currentIndex + 1) % behaviorsCount;
        } else if (mode === 'decrement') {
            newIndex = (currentIndex - 1 + behaviorsCount) % behaviorsCount;
        }

        handleSendCarouselValues(indexName, newIndex, roomName);
    };

    const handleButtonClick = (direction: 'next' | 'previous') => {
        const flowDirection = direction === 'next' ? 0 : 1;
        const indexChange = direction === 'next' ? 'decrement' : 'increment';

        handleNextItemSelected(direction);
        handleSendCarouselValues('flowDirection', flowDirection, roomName);
        handleIndexChange('leftIndex', indexChange, leftIndex, roomName);
        handleIndexChange('centerIndex', indexChange, centerIndex, roomName);
        handleIndexChange('rightIndex', indexChange, rightIndex, roomName);
    };

    useEffect(() => {
        if (itemsSelected.behavior?.behaviorFound) {
            handleTabletStatus(
                {
                    blue: false,
                    green: false,
                    orange: true,
                    red: false,
                },
                roomName
            );
        } else {
            handleTabletStatus(
                {
                    blue: true,
                    green: true,
                    orange: true,
                    red: false,
                },
                roomName
            );
        }
    }, [itemsSelected, roomName, itemsFound]);

    return (
        <div className={globalStyle.itemBoxContainer}>
            <TabletScreen
                step="action"
                deviceColor={deviceColor}
                typeBackground="banner"
            >
                <div className={styles.carouselBtns}>
                    <motion.button
                        whileTap={{ scale: 0.8 }}
                        onClick={() => {
                            handleButtonClick('previous');
                            playSound(Swipe);
                        }}
                        style={{
                            scaleX: -1,
                        }}
                    >
                        <img src={RightArrow} alt="flèche" />
                    </motion.button>
                    <motion.button
                        whileTap={{ scale: 0.8 }}
                        onClick={() => {
                            handleButtonClick('next');
                            playSound(Swipe);
                        }}
                    >
                        <img src={RightArrow} alt="flèche" />
                    </motion.button>
                </div>
            </TabletScreen>
        </div>
    );
};

export default ChooseBehavior;
