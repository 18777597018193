import type React from 'react';
import { useEffect, useState } from 'react';

// scss
import styles from 'src/features/reusablecomponents/global/splashScreen/splashScreen.module.scss';

// components
import TabletScreen from 'src/features/devices/tablets/components/screens/TabletScreen';
import Button from 'src/features/reusablecomponents/buttons/Button';
import SocketService from 'src/services/SocketService';
import { useAppSelector } from 'src/services/hooks';
import { selectRoomName } from 'src/services/reducers/roomSlice';

import dataGlobal from 'src/services/datas/global.json';

interface SplashScreenProps {
    onConnect: () => void;
}

const SplashScreen: React.FC<SplashScreenProps> = ({ onConnect }) => {
    const [isConnected, setIsConnected] = useState(false);
    const roomName = useAppSelector(selectRoomName);
    const socket = SocketService.getInstance().getSocket();

    const handleClick = () => {
        onConnect();
        setIsConnected(true);
    };

    useEffect(() => {
        socket?.on('room_created', (data: { room: string }) => {
            socket?.emit('join_room', data);
        });
    }, [roomName, socket]);

    return (
        <div className={styles.container}>
            <div className={styles.centerContainer}>
                {!isConnected ? (
                    <Button
                        text="CONNECTER"
                        backgroundColor="green"
                        onClick={() => handleClick()}
                    />
                ) : (
                    <TabletScreen
                        header={{
                            title: dataGlobal.headers.inactive.title,
                            text: dataGlobal.headers.inactive.text,
                        }}
                        typeBackground="circle"
                    />
                )}
            </div>
        </div>
    );
};

export default SplashScreen;
