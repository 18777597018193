import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/services/hooks';
import { selectRoomName } from 'src/services/reducers/roomSlice';
import { getSocket } from 'src/utils/global/globalHandleFunctions';

// assets & styles
import { Scientist } from 'src/assets/images/characters';
import styles from 'src/features/devices/tv/components/welcome/welcomeScreen.module.scss';

interface WelcomeScreenProps {
    isWelcomeScreen: boolean;
}

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({ isWelcomeScreen }) => {
    const { t } = useTranslation('global');
    const roomName = useAppSelector(selectRoomName);

    const changeLanguage = (language: string) => {
        const responseSocket = {
            language,
            roomName,
        };
        const socket = getSocket();
        socket?.emit('send_update_language', responseSocket);
    };

    return (
        <div className={styles.welcomeScreenContainer}>
            <p>
                {isWelcomeScreen
                    ? t('gameContent.title')
                    : t('gameContent.finishText')}
            </p>
            <img src={Scientist} alt={t('gameContent.avatar')} />
            <div className={styles.languages}>
                <button onClick={() => changeLanguage('fr-FR')}>🇫🇷</button>
                <button onClick={() => changeLanguage('ko-KO')}>🇰🇷</button>
                <button onClick={() => changeLanguage('ja-JA')}>🇯🇵</button>
            </div>
        </div>
    );
};

export default WelcomeScreen;
