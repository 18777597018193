import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from 'src/services/store';

import headerDatas from 'src/services/datas/actionContent.json';
import dataGlobal from 'src/services/datas/global.json';

// utils
import {
    GameStatus,
    type Emotion,
    type Game2Action,
    type HeaderContent,
    type Item,
    type Stimulus,
} from 'src/utils/global/types';

export interface Environments {
    [key: string]: {
        title: string;
        stimuli: Stimulus[];
    };
}
export interface EnvironmentsKey {
    work: boolean;
    family: boolean;
    friends: boolean;
    sport: boolean;
}

export interface Game2Slice {
    isConfirmSelectionButton: boolean;
    itemsSelected: Game2Action;
    itemsFound: Item[];
    headerContent: HeaderContent;
}

const initialState: Game2Slice = {
    isConfirmSelectionButton: false,
    itemsSelected: {
        emotion: dataGlobal.emotions[0] as Emotion,
        work: {} as Stimulus,
        friends: {} as Stimulus,
        family: {} as Stimulus,
        sport: {} as Stimulus,
    },
    itemsFound: new Array(dataGlobal.emotions.length).fill(null),
    headerContent: headerDatas[GameStatus.GAME2ACTION].tv.headerContent,
};

export const Game2Slice = createSlice({
    name: 'game2',
    initialState: initialState,
    reducers: {
        updateItemsSelectedGame2: (
            state,
            action: PayloadAction<{
                item: Stimulus | Emotion;
                itemKey: keyof Game2Action;
            }>
        ) => {
            const { item, itemKey } = action.payload;
            state.itemsSelected = {
                ...state.itemsSelected,
                [itemKey]: item,
            };
        },
        resetItemsSelectedGame2: (state) => {
            state.itemsSelected = initialState.itemsSelected;
            state.isConfirmSelectionButton = false;
        },

        addEmotionToItemsSelected: (state) => {
            const emotionFound = state.itemsFound.findLast((emotion) => emotion);

            const index = dataGlobal.emotions.findIndex(
                (emotion) => emotion.id === emotionFound?.id
            );
            const nextEmotion = dataGlobal.emotions[index + 1];

            state.itemsSelected = {
                emotion: nextEmotion as Emotion,
                work: {} as Stimulus,
                friends: {} as Stimulus,
                family: {} as Stimulus,
                sport: {} as Stimulus,
            };
        },

        updateItemsFoundGame2: (state, action: PayloadAction<Emotion>) => {
            const newItemsFound = state.itemsFound;
            const indexItem = action.payload.id - 1;
            newItemsFound[indexItem] = action.payload;
            state.itemsFound = newItemsFound;
        },

        updateIsConfirmSelectionButton: (
            state,
            action: PayloadAction<{
                status: boolean;
            }>
        ) => {
            const { status } = action.payload;
            state.isConfirmSelectionButton = status;
        },
        updateNextEmotionGame2: (
            state,
            action: PayloadAction<{
                emotion: Emotion;
            }>
        ) => {
            const { emotion } = action.payload;
            state.itemsSelected!.emotion = emotion;
        },
        resetItemsFoundGame2: (state) => {
            state.itemsFound = initialState.itemsFound;
            state.itemsSelected = initialState.itemsSelected;
        },
    },
});

export const {
    updateItemsSelectedGame2,
    resetItemsSelectedGame2,
    updateItemsFoundGame2,
    updateIsConfirmSelectionButton,
    updateNextEmotionGame2,
    addEmotionToItemsSelected,
    resetItemsFoundGame2,
} = Game2Slice.actions;

export const selectItemsSelectedGame2 = (state: RootState): Game2Action =>
    state.game2.itemsSelected;

export const selectItemsFoundGame2 = (state: RootState): Item[] =>
    state.game2.itemsFound;

export const selectIsConfirmSelectionButtonGame2 = (state: RootState): boolean =>
    state.game2.isConfirmSelectionButton;

export default Game2Slice.reducer;
