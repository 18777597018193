import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { type RootState } from 'src/services/store';

// utils
import {
    type Game5Action,
    GameStatus,
    type HeaderContent,
    type ResponseSituation,
} from 'src/utils/global/types';

import headerDatas from 'src/services/datas/actionContent.json';

interface UpdateItemsSelectedPayload {
    item: ResponseSituation;
    itemKey: keyof Game5Action;
}

export interface Game5State {
    itemsSelected: Game5Action;
    headerContent: HeaderContent;
    currentIndex: number;
}

const initialState: Game5State = {
    itemsSelected: {} as Game5Action,
    headerContent: headerDatas[GameStatus.GAME5ACTION].tv.headerContent,
    currentIndex: 0,
};

export const Game5Slice = createSlice({
    name: 'game5',
    initialState,
    reducers: {
        updateItemsSelectedGame5: (
            state,
            action: PayloadAction<UpdateItemsSelectedPayload>
        ) => {
            const { itemKey: type, item } = action.payload;
            state.itemsSelected = {
                ...state.itemsSelected,
                [type]: item,
            };
        },
        resetItemsSelectedGame5: (state) => {
            state.itemsSelected = initialState.itemsSelected;
        },
        updateCurrentSituation: (state, action: PayloadAction<number>) => {
            state.currentIndex = action.payload;
        },
    },
});

export const {
    updateItemsSelectedGame5,
    resetItemsSelectedGame5,
    updateCurrentSituation,
} = Game5Slice.actions;

export const selectItemsSelectedGame5 = (state: RootState): Game5Action =>
    state.game5.itemsSelected;

export const selectCurrentIndexGame5 = (state: RootState): number =>
    state.game5.currentIndex;

export default Game5Slice.reducer;
