import { useAppSelector } from 'src/services/hooks';

// scss
import styles from 'src/features/devices/tablets/components/game5/vote.module.scss';

import { motion } from 'framer-motion';

// utils
import { handleItemSelected } from 'src/utils/device/deviceHandleFunctions';
import {
    type DeviceColor,
    type ItemsSelected,
    type Situation,
} from 'src/utils/global/types';

// services
import {
    selectCurrentGameStatus,
    selectGameResult,
} from 'src/services/reducers/gameSlice';
import { selectRoomName } from 'src/services/reducers/roomSlice';

// datas
import { useTranslation } from 'react-i18next';
import ActionButton from 'src/features/devices/tablets/components/actionButton/ActionButton';
import ChooseAnswer from 'src/features/devices/tablets/components/chooseAnswer/ChooseAnswer';
import StarField from 'src/features/reusablecomponents/stars/StarField';
import {
    selectCurrentIndexGame5,
    selectItemsSelectedGame5,
} from 'src/services/reducers/games/game5Slice';
import { selectCurrentOverlayType } from 'src/services/reducers/overlaySlice';

interface VoteProps {
    deviceColor: DeviceColor;
}

const Vote: React.FC<VoteProps> = ({ deviceColor }) => {
    const roomName = useAppSelector(selectRoomName);
    const gameStatus = useAppSelector(selectCurrentGameStatus);
    const currentIndex = useAppSelector(selectCurrentIndexGame5);
    const { t } = useTranslation(['global', 'actionContent']);
    const currentSituation = t(`${gameStatus}.situations.${currentIndex}`, {
        returnObjects: true,
    }) as Situation;

    const itemsSelected = useAppSelector(selectItemsSelectedGame5);

    const overlay = useAppSelector(selectCurrentOverlayType);

    const currentGameResult = useAppSelector(selectGameResult);

    return (
        <div className={styles.voteContainer}>
            {overlay === 'neutral' ? (
                <>
                    <ChooseAnswer
                        responses={currentSituation?.responses}
                        deviceColor={deviceColor}
                        status={currentGameResult}
                    />
                    <div className={styles.buttonsContainer}>
                        {currentSituation?.responses.map((response) => {
                            const isSelected =
                                itemsSelected[
                                    deviceColor as keyof typeof itemsSelected
                                ]?.id === response.id;
                            return (
                                <motion.button
                                    whileTap={{ scale: 0.9 }}
                                    key={response.id}
                                    onClick={() => {
                                        handleItemSelected(
                                            response as ItemsSelected,
                                            deviceColor,
                                            gameStatus,
                                            roomName
                                        );
                                    }}
                                    className={`${styles.button} ${
                                        isSelected && !currentGameResult
                                            ? styles.selected
                                            : undefined
                                    } ${
                                        isSelected && currentGameResult
                                            ? styles[currentGameResult]
                                            : undefined
                                    }`}
                                    disabled={currentGameResult !== ''}
                                >
                                    {t(
                                        `${gameStatus}.tv.bodyContent.responseTitle`,
                                        { ns: 'actionContent' }
                                    )}
                                    {response.id}
                                </motion.button>
                            );
                        })}
                        {currentGameResult === 'success' && <StarField />}
                    </div>
                </>
            ) : (
                <ActionButton
                    title={t(`buttons.continue`)}
                    type={overlay}
                    action="validateGame5"
                />
            )}
        </div>
    );
};

export default Vote;
