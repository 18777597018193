import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { type RootState } from 'src/services/store';

// utils
import {
    type Game4Action,
    GameStatus,
    type Behavior,
    type HeaderContent,
} from 'src/utils/global/types';

import headerDatas from 'src/services/datas/actionContent.json';
import dataGlobal from 'src/services/datas/global.json';
import i18n from 'src/i18n';

interface UpdateItemsSelectedPayload {
    item: Behavior | string;
    itemKey: keyof Game4Action;
}
export interface CarouselValues {
    [key: string]: number;
}

export interface CarouselPayload {
    key: 'rightIndex' | 'centerIndex' | 'leftIndex' | 'flowDirection';
    value: number;
}

export interface Game4State {
    itemsSelected: Game4Action;
    itemsFound: Behavior[];
    listBehaviors: Behavior[];
    headerContent: HeaderContent;
    carouselValues: CarouselValues;
}
const behaviorsObject = i18n.t('GAME4ACTION.behaviors', {
    returnObjects: true,
}) as Behavior[];

const initialState: Game4State = {
    itemsSelected: { behavior: behaviorsObject[0] } as Game4Action,
    listBehaviors: behaviorsObject,
    itemsFound: new Array(dataGlobal.emotions.length).fill(null),
    headerContent: headerDatas[GameStatus.GAME4ACTION].tv.headerContent,
    carouselValues: {
        flowDirection: 0,
        centerIndex: 0,
        leftIndex: behaviorsObject.length - 1,
        rightIndex: 1,
    },
};

export const Game4Slice = createSlice({
    name: 'game4',
    initialState,
    reducers: {
        updateItemsSelectedGame4: (
            state,
            action: PayloadAction<UpdateItemsSelectedPayload>
        ) => {
            const { itemKey: type, item } = action.payload;
            state.itemsSelected = {
                ...state.itemsSelected,
                [type]: item,
            };
        },
        resetItemsSelectedGame4: (state) => {
            state.itemsSelected.code = '';
        },
        updateItemsFoundGame4: (state, action: PayloadAction<Behavior>) => {
            const indexItem = action.payload.id - 1;
            state.itemsFound[indexItem] = action.payload;
            state.listBehaviors.find(
                (behavior) => behavior.emotionId === action.payload.id
            )!.behaviorFound = true;
        },
        updateCarouselValues: (state, action: PayloadAction<CarouselPayload>) => {
            const { value, key } = action.payload;
            state.carouselValues[key] = value;
        },
        resetItemsFoundGame4: (state) => {
            state.itemsFound = initialState.itemsFound;
            state.carouselValues = initialState.carouselValues;
            state.listBehaviors = initialState.listBehaviors;
            state.itemsSelected = initialState.itemsSelected;
        },
    },
});

export const {
    updateItemsSelectedGame4,
    resetItemsSelectedGame4,
    updateItemsFoundGame4,
    updateCarouselValues,
    resetItemsFoundGame4,
} = Game4Slice.actions;

export const selectItemsFoundGame4 = (state: RootState): Behavior[] =>
    state.game4.itemsFound;

export const selectItemsSelectedGame4 = (state: RootState): Game4Action =>
    state.game4.itemsSelected;

export const selectCarouselValues = (state: RootState): CarouselValues =>
    state.game4.carouselValues;

export const selectListBehaviors = (state: RootState): Behavior[] =>
    state.game4.listBehaviors;

export default Game4Slice.reducer;
