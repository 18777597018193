// scss
import globalStyle from 'src/styles/app.module.scss';

// components
import TabletScreen from 'src/features/devices/tablets/components/screens/TabletScreen';

// datas

// services
import DigitKeyboard from 'src/features/reusablecomponents/global/dial/DigitKeyboard';
import InputUnlockCode from 'src/features/reusablecomponents/global/inputUnlockCode/InputUnlockCode';
import { Device } from 'src/utils/global/types';

const ChooseCode: React.FC = () => {
    const deviceColor = Device.blue;
    return (
        <TabletScreen
            step="action"
            deviceColor={deviceColor}
            typeBackground="circle"
        >
            <div className={globalStyle.dialContainer}>
                <InputUnlockCode />
                <DigitKeyboard />
            </div>
        </TabletScreen>
    );
};

export default ChooseCode;
