import { type OverlayDescription } from 'src/services/reducers/overlaySlice';

export const isDevelopperMode = import.meta.env.VITE_DEVELOPPER_MODE === 'true';

export enum GameStatus {
    WAITING = 'WAITING',
    WELCOME_SCREEN = 'WELCOME_SCREEN',
    INTRODUCTION = 'INTRODUCTION',

    GAME1ACTION1INTRODUCTION = 'GAME1ACTION1INTRODUCTION',
    GAME1ACTION1 = 'GAME1ACTION1',

    GAME1ACTION2INTRODUCTION = 'GAME1ACTION2INTRODUCTION',
    GAME1ACTION2 = 'GAME1ACTION2',
    GAME1ACTION2PREVENTION = 'GAME1ACTION2PREVENTION',
    GAME1UNLOCK = 'GAME1UNLOCK',

    GAME2INTRODUCTION = 'GAME2INTRODUCTION',
    GAME2ACTION = 'GAME2ACTION',
    GAME2PREVENTION = 'GAME2PREVENTION',
    GAME2UNLOCK = 'GAME2UNLOCK',

    GAME3INTRODUCTION = 'GAME3INTRODUCTION',
    GAME3ACTION = 'GAME3ACTION',
    GAME3PREVENTION = 'GAME3PREVENTION',
    GAME3UNLOCK = 'GAME3UNLOCK',

    GAME4INTRODUCTION = 'GAME4INTRODUCTION',
    GAME4ACTION = 'GAME4ACTION',
    GAME4PREVENTION = 'GAME4PREVENTION',
    GAME4UNLOCK = 'GAME4UNLOCK',

    GAME5INTRODUCTION = 'GAME5INTRODUCTION',
    GAME5ACTION = 'GAME5ACTION',
    GAME5PREVENTION = 'GAME5PREVENTION',
    GAME5UNLOCK = 'GAME5UNLOCK',

    FINISHED = 'FINISHED',
}

export type GameStatusAction =
    | GameStatus.GAME1ACTION1
    | GameStatus.GAME1ACTION2
    | GameStatus.GAME2ACTION
    | GameStatus.GAME3ACTION
    | GameStatus.GAME4ACTION
    | GameStatus.GAME5ACTION;

export type GameStatusActionWithoutGame5 = Exclude<
    GameStatusAction,
    GameStatus.GAME5ACTION
>;

export type Message = {
    [key in GameStatus]: OverlayDescription[];
};

export type HeaderContent = {
    title: string;
    text: string;
};

export interface Item {
    id: number;
    slug: string;
    image?: string;
    title?: string;
}

export interface Emotion extends Item {
    description: string;
    successMessage: Message;
    errorMessage: Message;
}

export type EmotionSecondary = Omit<Emotion, 'successMessage' | 'errorMessage'>;

export type _Symbol = Item;

export interface Character extends Item {
    name: string;
}

export type ButtonsInformations = {
    title: string;
    color: string;
    action: string;
};

export type TypeBackground = 'circle' | 'banner' | 'empty';

export type OverlayType = 'neutral' | 'success' | 'error' | 'unlock';
export type DeviceColor = 'blue' | 'green' | 'orange' | 'red';

export interface ItemsSelected {
    emotion?: Emotion;
    strongEmotion?: EmotionSecondary;
    weakEmotion?: EmotionSecondary;
    symbol?: _Symbol;
    work?: Stimulus;
    family?: Stimulus;
    sport?: Stimulus;
    friends?: Stimulus;
    character?: Character;
    tetrispaul?: string;
    tetrisfanny?: string;
    tetriseric?: string;
    behavior?: Behavior;
    code?: string;
    red?: ResponseSituation;
    green?: ResponseSituation;
    blue?: ResponseSituation;
    orange?: ResponseSituation;
}

export interface Game1Action1 {
    emotion: Emotion;
    symbol: _Symbol;
}

export interface Game1Action2 {
    emotion: Emotion;
    weakEmotion: EmotionSecondary;
    strongEmotion: EmotionSecondary;
}

export interface Game2Action {
    emotion: Emotion;
    work: Stimulus;
    family: Stimulus;
    sport: Stimulus;
    friends: Stimulus;
}

export interface Game3Action {
    character: Character;
    emotion: Emotion;
    tetriseric: string;
    tetrispaul: string;
    tetrisfanny: string;
}

export interface Game4Action {
    behavior: Behavior;
    code: string;
}

export interface Game5Action {
    red: ResponseSituation;
    green: ResponseSituation;
    blue: ResponseSituation;
    orange: ResponseSituation;
}

export type IntensityData = {
    [key: string]: string;
};

export enum BlockLetter {
    X = 'X',
    I = 'I',
    J = 'J',
    B = 'B',
    L = 'L',
    O = 'O',
    S = 'S',
    T = 'T',
    U = 'U',
    A = 'A',
}

export type Block = {
    tetris: BlockLetter;
    character: string;
};

export enum EmptyCell {
    Empty = 'Empty',
}

export type CellOptions = Block | EmptyCell;

export type BoardShape = CellOptions[][];

export type BlockShape = readonly boolean[][];

export type ShapesTetris = Record<BlockLetter, { shape: BlockShape }>;

export const SHAPES: ShapesTetris = {
    X: {
        shape: [
            [false, true, false],
            [true, true, true],
            [false, true, false],
        ],
    },
    I: {
        shape: [
            [false, false, false],
            [true, true, true],
            [false, false, false],
        ],
    },
    J: {
        shape: [
            [false, false],
            [true, false],
            [true, true],
        ],
    },
    B: {
        shape: [
            [true, false],
            [true, false],
        ],
    },
    L: {
        shape: [
            [false, false, false],
            [false, false, true],
            [true, true, true],
        ],
    },
    O: {
        shape: [
            [true, true],
            [true, true],
        ],
    },
    S: {
        shape: [
            [false, false, false],
            [false, true, true],
            [true, true, false],
        ],
    },
    T: {
        shape: [
            [false, false, false],
            [false, true, false],
            [true, true, true],
        ],
    },
    U: {
        shape: [
            [false, false, false],
            [true, false, true],
            [true, true, true],
        ],
    },
    A: {
        shape: [[true]],
    },
};

export type BoardState = {
    board: BoardShape;
    droppingRow: number;
    droppingColumn: number;
    droppingBlock: Block;
    droppingShape: BlockShape;
};

export interface Behavior extends Item {
    name: string;
    behavior: string;
    emotionId: number;
    behaviorFound: boolean;
    audio: string;
}

export interface Stimulus extends Item {
    name: string;
    text: string;
    picto: string;
    sound: string;
}

export type ResponseSituation = {
    id: number;
    text: string;
};

export type Situation = {
    id: number;
    scientist: string;
    audioScientist: string;
    audioRobot: string;
    time: number;
    robot: string;
    responses: ResponseSituation[];
    errorMessage: OverlayDescription;
    successMessage: OverlayDescription;
};

export type Disagrement = {
    text: string;
    audio: string;
};

export const imageVariants = {
    initial: {
        y: 150,
        scale: 0,
    },
    animate: {
        y: 0,
        scale: 1,
        transition: {
            duration: 0.5,
            ease: 'easeInOut',
        },
    },
};

export enum Device {
    red = 'red',
    blue = 'blue',
    green = 'green',
    orange = 'orange',
    tv = 'tv',
    mediator = 'mediator',
}

export type ActionContentJson = {
    [key in GameStatus]: {
        [key in Device]: {
            headerContent: HeaderContent;
            footerContent?: HeaderContent;
        };
    };
};

export type GameStatusOverlayContent = {
    [outerKey in GameStatus]?: {
        tv: OverlayDescription[];
    } & { [innerKey in Device]?: { headerContent?: HeaderContent } };
};
