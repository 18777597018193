import { useAppSelector } from 'src/services/hooks';

// datas

// utils
import { handleItemSelected } from 'src/utils/device/deviceHandleFunctions';
import {
    type ItemsSelected,
    SHAPES,
    type ShapesTetris,
} from 'src/utils/global/types';

// services
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import { selectRoomName } from 'src/services/reducers/roomSlice';
import Shape from 'src/features/devices/tv/components/games/game3/shape/Shape';
import styles from 'src/features/devices/tablets/components/game3/chooseTetris.module.scss';
import { playSound } from 'src/utils/global/globalHandleFunctions';
import { ClickTetris } from 'src/assets/sounds/soundsEffect';

interface ChooseTetrisProps {
    character: string;
}
const ChooseTetris: React.FC<ChooseTetrisProps> = ({ character }) => {
    const roomName = useAppSelector(selectRoomName);
    const gameStatus = useAppSelector(selectCurrentGameStatus);

    const handleClickBox = (item: ItemsSelected) => {
        handleItemSelected(item, `tetris${character}`, gameStatus, roomName);
    };

    return (
        <div className={styles.chooseTetrisContainer}>
            {Object.keys(SHAPES).map((tetris, blockIndex) => {
                const shape = SHAPES[tetris as keyof ShapesTetris].shape.filter(
                    (row) => row.some((cell) => cell)
                );
                return (
                    <Shape
                        key={blockIndex}
                        shape={shape}
                        blockIndex={blockIndex}
                        block={tetris}
                        onClick={() => {
                            handleClickBox(tetris as ItemsSelected);
                            playSound(ClickTetris);
                        }}
                        character={character}
                        isUpcoming={false}
                    />
                );
            })}
        </div>
    );
};

export default ChooseTetris;
