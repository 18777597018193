import { useMemo } from 'react';
import { useAppSelector } from 'src/services/hooks';
import { selectItemsSelectedGame1Action1 } from 'src/services/reducers/games/game1Action1Slice';
import { selectItemsSelectedGame1Action2 } from 'src/services/reducers/games/game1Action2Slice';
import { selectItemsSelectedGame2 } from 'src/services/reducers/games/game2Slice';
import { selectItemsSelectedGame3 } from 'src/services/reducers/games/game3Slice';
import { selectItemsSelectedGame4 } from 'src/services/reducers/games/game4Slice';
import {
    GameStatus,
    type GameStatusActionWithoutGame5,
    type ItemsSelected,
} from 'src/utils/global/types';

export const useItemsSelected = (
    gameStatus: GameStatusActionWithoutGame5
): ItemsSelected => {
    const itemsSelectedGame1Action1 = useAppSelector(
        selectItemsSelectedGame1Action1
    );
    const itemsSelectedGame1Action2 = useAppSelector(
        selectItemsSelectedGame1Action2
    );
    const itemsSelectedGame2 = useAppSelector(selectItemsSelectedGame2);
    const itemsSelectedGame3 = useAppSelector(selectItemsSelectedGame3);
    const itemsSelectedGame4 = useAppSelector(selectItemsSelectedGame4);

    const itemsSelected = useMemo(
        () => ({
            [GameStatus.GAME1ACTION1]: itemsSelectedGame1Action1 as ItemsSelected,
            [GameStatus.GAME1ACTION2]: itemsSelectedGame1Action2 as ItemsSelected,
            [GameStatus.GAME2ACTION]: itemsSelectedGame2 as ItemsSelected,
            [GameStatus.GAME3ACTION]: itemsSelectedGame3 as ItemsSelected,
            [GameStatus.GAME4ACTION]: itemsSelectedGame4 as ItemsSelected,
        }),
        [
            itemsSelectedGame1Action1,
            itemsSelectedGame1Action2,
            itemsSelectedGame2,
            itemsSelectedGame3,
            itemsSelectedGame4,
        ]
    );

    return itemsSelected[gameStatus];
};
