// components
import ErrorAndSuccessBackground from 'src/features/reusablecomponents/global/errorAndSuccessBackground/ErrorAndSuccessBackground';

// scss
import styles from 'src/features/devices/tv/components/overlay/overlay.module.scss';

// assets
import {
    Scientist,
    ScientistError,
    ScientistSuccess,
} from 'src/assets/images/characters';

import {
    DiamondShape,
    Eye,
    PatternBackground,
    PatternHorizontal,
    PatternLeft,
    PatternRight,
    PatternTriangle,
} from 'src/assets/images/tv';
import { Lose, Win } from 'src/assets/sounds/soundsEffect';
import AudioComponent from 'src/features/reusablecomponents/global/AudioComponent';
import TextAnimation from 'src/features/reusablecomponents/text/TextAnimation';
import { type OverlayType } from 'src/utils/global/types';
import { useAppSelector } from 'src/services/hooks';
import {
    selectCurrentOverlayDescriptions,
    selectCurrentOverlayIndex,
} from 'src/services/reducers/overlaySlice';

interface OverlayProps {
    type?: OverlayType;
}

const Overlay: React.FC<OverlayProps> = ({ type = 'neutral' }) => {
    const handleAssets = (): { scientist: string; audio: string } => {
        switch (type) {
            case 'neutral':
                return { scientist: Scientist, audio: '' };
            case 'success':
                return { scientist: ScientistSuccess, audio: Win };
            case 'error':
                return { scientist: ScientistError, audio: Lose };
            default:
                return { scientist: Scientist, audio: '' };
        }
    };

    const currentOverlayDescriptions = useAppSelector(
        selectCurrentOverlayDescriptions
    );
    const currentOverlayIndex = useAppSelector(selectCurrentOverlayIndex);
    const currentTextAudio = currentOverlayDescriptions[currentOverlayIndex]?.audio;
    const currentTitle = currentOverlayDescriptions[currentOverlayIndex]?.title;
    const currentText = currentOverlayDescriptions[currentOverlayIndex]?.text;

    return (
        <div className={styles.overlayContainer}>
            <AudioComponent src={handleAssets().audio} />
            {currentTextAudio && <AudioComponent src={currentTextAudio} />}
            {type !== 'neutral' && type !== 'unlock' && (
                <ErrorAndSuccessBackground type={type} device="tv" />
            )}
            <img src={PatternHorizontal} alt="" className={styles.patternTop} />
            <img
                src={PatternBackground}
                alt="Arrière plan"
                className={styles.patternBackground}
            />
            <div
                className={
                    type === 'error'
                        ? styles.containerOverlayTvError
                        : styles.containerOverlayTv
                }
            >
                <img src={PatternLeft} alt="" className={styles.patternLeft} />
                {type === 'unlock' ? (
                    <div className={styles.containerUnlockGame}>
                        <h1>{currentTitle}</h1>
                        <img src={PatternTriangle} alt="" />
                        <div className={styles.codeContainer}>
                            {Array.isArray(currentText) &&
                                currentText.map((item, index) => (
                                    <div key={index}>
                                        <img src={DiamondShape} alt="" />
                                        <p>{item}</p>
                                    </div>
                                ))}
                        </div>
                    </div>
                ) : (
                    <>
                        <img
                            src={handleAssets().scientist}
                            alt="Scientifique"
                            className={styles.character}
                        />
                        <div className={styles.textContainer}>
                            <div>
                                <img src={Eye} alt="Icone" />
                                <h1>{currentTitle}</h1>
                            </div>
                            <TextAnimation text={currentText as string} />
                        </div>
                    </>
                )}
                <img src={PatternRight} alt="" className={styles.patternRight} />
            </div>
            <img src={PatternHorizontal} alt="" className={styles.patternBottom} />
        </div>
    );
};

export default Overlay;
