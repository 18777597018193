import { RightArrow, Rotate } from 'src/assets/images/global';
import { useAppSelector } from 'src/services/hooks';

// services
import { selectRoomName } from 'src/services/reducers/roomSlice';

import styles from 'src/features/devices/tablets/components/game3/remoteTetris.module.scss';
import { handleMouse } from 'src/utils/device/deviceHandleFunctions';

interface RemoteTetrisProps {
    type: 'up' | 'right' | 'left' | 'down';
}
const RemoteTetris: React.FC<RemoteTetrisProps> = ({ type }) => {
    const roomName = useAppSelector(selectRoomName);

    const handleImageSource = () => {
        switch (type) {
            case 'left':
            case 'right':
            case 'down':
                return RightArrow;
            case 'up':
                return Rotate;
        }
    };

    return (
        <button
            id={type}
            name={type}
            onMouseDown={() => {
                handleMouse('down', type, roomName);
            }}
            onMouseUp={() => {
                handleMouse('up', type, roomName);
            }}
        >
            {/* up = rotation */}
            {/* right = droite */}
            {/* left = gauche */}
            {/* down = speed */}
            <img src={handleImageSource()} alt="flèche" className={styles[type]} />
        </button>
    );
};

export default RemoteTetris;
