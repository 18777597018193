import styles from 'src/features/reusablecomponents/text/textWritter.module.scss';

interface TextWritterProps {
    buttonName?: string;
    text?: string;
}
const TextWritter: React.FC<TextWritterProps> = ({
    text = 'Lire les consignes',
}) => {
    return (
        <div className={styles.container}>
            <p>{text}</p>
        </div>
    );
};

export default TextWritter;
