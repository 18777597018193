import styles from 'src/features/reusablecomponents/global/inputUnlockCode/inputUnlockCode.module.scss';
import { DiamondShapeMini } from 'src/assets/images/tablets';

import { useAppSelector } from 'src/services/hooks';
import { selectCodeInputMaxLength } from 'src/services/reducers/dialSlice';
import { selectItemsSelectedGame4 } from 'src/services/reducers/games/game4Slice';

const InputUnlockCode: React.FC = () => {
    const maxCodeLength = useAppSelector(selectCodeInputMaxLength);

    const itemsSelected = useAppSelector(selectItemsSelectedGame4);
    const code = itemsSelected?.code?.split('') || [];

    return (
        <div className={styles.container}>
            {Array.from(Array(maxCodeLength).keys()).map((number, index) => {
                return (
                    <div key={number}>
                        {code && code[index] ? (
                            <p>{code[index]}</p>
                        ) : (
                            <img src={DiamondShapeMini} alt="icône losange" />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default InputUnlockCode;
