import { type Socket } from 'socket.io-client';
import {
    CircuitPattern,
    CircuitPatternError,
    CircuitPatternSuccess,
} from 'src/assets/images/tv';
import SocketService from 'src/services/SocketService';
import { type Device, type GameStatus } from './types';

export const getSocket = (): Socket | null => {
    const socketService = SocketService.getInstance();
    return socketService.getSocket();
};

export const handleReset = (roomName: string): void => {
    const socket = getSocket();
    socket?.emit('send_reset', {
        roomName,
    });
    location.reload();
};

export const handleSendGameStatus = (
    gameStatus: GameStatus,
    roomName: string
): void => {
    const socket = getSocket();
    socket?.emit('send_game_status', {
        roomName: roomName,
        status: gameStatus,
    });
};

export const handleStartGame = (color: Device, roomName: string): void => {
    const socket = getSocket();

    const responsesSocket = {
        roomName: roomName,
        color: color,
    };
    socket?.emit('send_start_game', responsesSocket);
};

export const handleUpdateDeviceActivatedStatus = (
    deviceColor: Device | string,
    status: boolean,
    roomName: string
): void => {
    const responsesSocket = {
        roomName: roomName,
        deviceColor: deviceColor,
        status: status,
    };
    const socket = getSocket();
    socket?.emit('send_device_activated_status', responsesSocket);
};

export const handleSendIsAudioPlaying = (
    isAudioPlaying: boolean,
    roomName: string
): void => {
    const socket = getSocket();
    const responsesSocket = {
        roomName: roomName,
        isAudioPlaying: isAudioPlaying,
    };
    socket?.emit('send_is_audio_playing', responsesSocket);
};

export const playSound = (audio: string): object => {
    const sound = new Audio(audio);
    return sound.play();
};

export const handlePatternImage = (
    gameResult: 'error' | 'success' | ''
): string => {
    if (gameResult === 'error') return CircuitPatternError;
    if (gameResult === 'success') return CircuitPatternSuccess;
    return CircuitPattern;
};

export const handleGameStatusToString = (gameStatus: GameStatus): string => {
    switch (gameStatus) {
        case 'GAME1ACTION1':
            return 'Game1Action1';
        case 'GAME1ACTION2':
            return 'Game1Action2';
        case 'GAME2ACTION':
            return 'Game2';
        case 'GAME3ACTION':
            return 'Game3';
        case 'GAME4ACTION':
            return 'Game4';
        case 'GAME5ACTION':
            return 'Game5';
        default:
            return '';
    }
};
