import { type Action, type ThunkAction, configureStore } from '@reduxjs/toolkit';

import gameReducer from 'src/services/reducers/gameSlice';
import roomReducer from 'src/services/reducers/roomSlice';
import deviceReducer from 'src/services/reducers/deviceSlice';
import game1Action1Reducer from 'src/services/reducers/games/game1Action1Slice';
import overlayReducer from 'src/services/reducers/overlaySlice';
import game1Action2Reducer from 'src/services/reducers/games/game1Action2Slice';
import game3Reducer from 'src/services/reducers/games/game3Slice';
import game2Reducer from 'src/services/reducers/games/game2Slice';
import game4Reducer from 'src/services/reducers/games/game4Slice';
import game5Reducer from 'src/services/reducers/games/game5Slice';
import dialReducer from 'src/services/reducers/dialSlice';

export const store = configureStore({
    reducer: {
        room: roomReducer,
        game: gameReducer,
        device: deviceReducer,
        overlay: overlayReducer,
        game1Action1: game1Action1Reducer,
        game1Action2: game1Action2Reducer,
        game2: game2Reducer,
        game3: game3Reducer,
        game4: game4Reducer,
        game5: game5Reducer,
        dial: dialReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
