import { BigCircle, SmallCircle } from 'src/assets/images/tablets';
import styles from 'src/features/devices/tablets/components/screens/circleAnimationScreen.module.scss';

interface CircleAnimationScreenProps {
    children: React.ReactNode;
}
const CircleAnimationScreen: React.FC<CircleAnimationScreenProps> = ({
    children,
}) => {
    return (
        <>
            <img src={BigCircle} alt="big" className={styles.bigCircle} />
            <img src={SmallCircle} alt="small" className={styles.smallCircle} />
            {children}
        </>
    );
};

export default CircleAnimationScreen;
