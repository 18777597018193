import { selectRoomName } from 'src/services/reducers/roomSlice';

import { useAppSelector } from 'src/services/hooks';

import MediatorScreen from 'src/features/devices/tablets/mediator/MediatorScreen';
import PreConnectionMediatorScreen from 'src/features/devices/tablets/mediator/PreConnectionMediatorScreen';
import globalStyle from 'src/styles/app.module.scss';

import { useEffect } from 'react';
import { selectDeviceStatus } from 'src/services/reducers/deviceSlice';
import {
    handleSendGameStatus,
    handleStartGame,
} from 'src/utils/global/globalHandleFunctions';
import { Device, GameStatus } from 'src/utils/global/types';

const MediatorTablet: React.FC = () => {
    const roomName = useAppSelector(selectRoomName);

    const { mediator, blue, orange, red, green } =
        useAppSelector(selectDeviceStatus);

    useEffect(() => {
        document.title = `⭐ ${mediator.label}`;
    }, [mediator.label]);
    const deviceType = Device.mediator;

    return (
        <div className={globalStyle.containerTablet}>
            {!mediator.isConnected && (
                <PreConnectionMediatorScreen
                    deviceStatuses={[
                        { label: 'Bleu', status: blue.isConnected, color: 'blue' },
                        {
                            label: 'Orange',
                            status: orange.isConnected,
                            color: 'orange',
                        },
                        { label: 'Rouge', status: red.isConnected, color: 'red' },
                        {
                            label: 'Verte',
                            status: green.isConnected,
                            color: 'green',
                        },
                    ]}
                    handleConnect={() => {
                        handleStartGame(deviceType, roomName);
                        handleSendGameStatus(GameStatus.WELCOME_SCREEN, roomName);
                    }}
                />
            )}
            {mediator.isConnected && <MediatorScreen />}
        </div>
    );
};

export default MediatorTablet;
