import { useAppSelector } from 'src/services/hooks';

// services

// components
import ItemBox from 'src/features/devices/tv/components/itemBox/ItemBox';

import StrongWeakEmotion from 'src/features/devices/tablets/components/game1action2/StrongWeakEmotion';
import styles from 'src/features/devices/tv/components/games/game1/game1Action1.module.scss';
import {
    selectCurrentGameStatus,
    selectGameResult,
} from 'src/services/reducers/gameSlice';
import { selectItemsSelectedGame1Action2 } from 'src/services/reducers/games/game1Action2Slice';
import { handlePatternImage } from 'src/utils/global/globalHandleFunctions';
import { useTranslation } from 'react-i18next';

const Game1Action2: React.FC = () => {
    const itemsSelected = useAppSelector(selectItemsSelectedGame1Action2);
    const gameResult = useAppSelector(selectGameResult);
    const { t } = useTranslation('actionContent');
    const currentGameStatus = useAppSelector(selectCurrentGameStatus);

    return (
        <div className={styles.mission1Action1Container}>
            <StrongWeakEmotion
                item={itemsSelected?.weakEmotion}
                isDisabled={false}
                description={t(`${currentGameStatus}.tv.bodyContent.weakIntensity`)}
                gameResult={gameResult}
            />
            <img src={handlePatternImage(gameResult)} alt="Circuit pattern" />
            <ItemBox
                image={itemsSelected?.emotion?.image ?? ''}
                text={
                    itemsSelected?.emotion?.title ??
                    t(`${currentGameStatus}.tv.bodyContent.title`)
                }
                gameResult={gameResult}
                additionalStyle={{ marginRight: 0 }}
                key={itemsSelected?.emotion?.slug}
            />
            <img src={handlePatternImage(gameResult)} alt="Circuit pattern" />
            <StrongWeakEmotion
                item={itemsSelected?.strongEmotion}
                isDisabled={false}
                description={t(
                    `${currentGameStatus}.tv.bodyContent.strongIntensity`
                )}
                gameResult={gameResult}
            />
        </div>
    );
};

export default Game1Action2;
