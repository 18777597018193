import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { type RootState } from 'src/services/store';

export interface DialState {
    value: string;
    reset: boolean;
    codeInputMaxLength: number;
}

const initialState: DialState = {
    value: '',
    reset: false,
    codeInputMaxLength: 2,
};

export const dialSlice = createSlice({
    name: 'dial',
    initialState,
    reducers: {
        changeValue: (state, action: PayloadAction<string>) => {
            state.value = action.payload;
        },
        launchReset: (state, action: PayloadAction<boolean>) => {
            state.reset = action.payload;
        },
    },
});

export const { changeValue, launchReset } = dialSlice.actions;

export const selectCodeInputMaxLength: (state: RootState) => number = (
    state: RootState
) => state.dial.codeInputMaxLength;

export const selectDialValue: (state: RootState) => string = (state: RootState) =>
    state.dial.value;

export const selectResetStatus: (state: RootState) => boolean = (
    state: RootState
) => state.dial.reset;

export default dialSlice.reducer;
