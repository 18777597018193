import { useState } from 'react';

// scss
import globalStyle from 'src/styles/app.module.scss';

// components
import ItemBoxTablet from 'src/features/devices/tablets/components/itemBox/ItemBoxTablet';
import TabletOverlay from 'src/features/devices/tablets/components/overlay/TabletOverlay';
import TabletScreen from 'src/features/devices/tablets/components/screens/TabletScreen';

// services
import { useAppSelector } from 'src/services/hooks';
import { selectItemsFoundGame1Action1 } from 'src/services/reducers/games/game1Action1Slice';
import { type Device, type Emotion } from 'src/utils/global/types';

const ListEmotionToFound: React.FC = () => {
    const emotionsFound = useAppSelector(selectItemsFoundGame1Action1) as Emotion[];

    const [overlayEmotionIndex, setOverlayEmotionIndex] = useState<number | null>(
        null
    );

    const handleCloseOverlay = () => {
        setOverlayEmotionIndex(null);
    };

    return (
        <>
            {overlayEmotionIndex !== null ? (
                <TabletOverlay
                    title={emotionsFound[overlayEmotionIndex].title}
                    description={emotionsFound[overlayEmotionIndex].description}
                    onClose={() => handleCloseOverlay()}
                />
            ) : (
                <TabletScreen
                    step="action"
                    deviceColor={'blue' as Device}
                    typeBackground="banner"
                >
                    <div className={globalStyle.itemBoxContainer}>
                        {emotionsFound.map((emotion, index) => (
                            <ItemBoxTablet
                                key={emotion ? emotion.slug : index}
                                item={emotion}
                                handleClick={() =>
                                    emotion && setOverlayEmotionIndex(index)
                                }
                                showName
                                isSymbol={false}
                            />
                        ))}
                    </div>
                </TabletScreen>
            )}
        </>
    );
};

export default ListEmotionToFound;
