import { useTranslation } from 'react-i18next';
import styles from 'src/features/devices/tv/components/games/game1/game1Action1.module.scss';

import ItemBoxTv from 'src/features/devices/tv/components/itemBox/ItemBoxTv';
import { useAppSelector } from 'src/services/hooks';
import {
    selectCurrentGameStatus,
    selectGameResult,
} from 'src/services/reducers/gameSlice';
import { selectItemsSelectedGame1Action1 } from 'src/services/reducers/games/game1Action1Slice';
import { handlePatternImage } from 'src/utils/global/globalHandleFunctions';

const Game1Action1: React.FC = () => {
    const itemsSelected = useAppSelector(selectItemsSelectedGame1Action1);
    const gameResult = useAppSelector(selectGameResult);
    const currentGameStatus = useAppSelector(selectCurrentGameStatus);

    const { t } = useTranslation('actionContent');

    return (
        <div className={styles.mission1Action1Container}>
            <img src={handlePatternImage(gameResult)} alt="Circuit pattern" />

            <ItemBoxTv
                image={itemsSelected.emotion?.image ?? ''}
                text={t(`${currentGameStatus}.tv.bodyContent.emotion`)}
                key={itemsSelected.emotion?.slug}
                gameResult={gameResult}
            />

            <ItemBoxTv
                image={itemsSelected.symbol?.image ?? ''}
                text={t(`${currentGameStatus}.tv.bodyContent.symbol`)}
                key={itemsSelected.symbol?.title}
                gameResult={gameResult}
            />

            <img src={handlePatternImage(gameResult)} alt="Circuit pattern" />
        </div>
    );
};

export default Game1Action1;
