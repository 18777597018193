import { type Socket, io } from 'socket.io-client';

class SocketService {
    private static instance: SocketService;
    private socket: Socket | null = null;

    public static getInstance(): SocketService {
        if (!SocketService.instance) {
            SocketService.instance = new SocketService();
        }

        return SocketService.instance;
    }

    public connect(url: string): void {
        this.socket = io(url);
    }

    public getSocket(): Socket | null {
        return this.socket;
    }
}

export default SocketService;
