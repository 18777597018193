import { useEffect } from 'react';
import { useAppSelector } from 'src/services/hooks';

// scss
import globalStyle from 'src/styles/app.module.scss';

// components
import TabletScreen from 'src/features/devices/tablets/components/screens/TabletScreen';
import Button from 'src/features/reusablecomponents/buttons/Button';

// datas
import dataGlobal from 'src/services/datas/global.json';

// utils
import { handleItemSelected } from 'src/utils/device/deviceHandleFunctions';
import {
    getSocket,
    handleUpdateDeviceActivatedStatus,
    playSound,
} from 'src/utils/global/globalHandleFunctions';
import { Device, type ItemsSelected } from 'src/utils/global/types';

// services
import ActionButton from 'src/features/devices/tablets/components/actionButton/ActionButton';
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import {
    selectItemsFoundGame3,
    selectItemsSelectedGame3,
    selectStatusTetris,
} from 'src/services/reducers/games/game3Slice';
import { selectCurrentOverlayType } from 'src/services/reducers/overlaySlice';
import { selectRoomName } from 'src/services/reducers/roomSlice';
import { ClickTetris } from 'src/assets/sounds/soundsEffect';
import { useTranslation } from 'react-i18next';

const ChooseGlobalFormTetris: React.FC = () => {
    const socket = getSocket();
    const roomName = useAppSelector(selectRoomName);
    const gameStatus = useAppSelector(selectCurrentGameStatus);
    const deviceColor = Device.red;
    const { t } = useTranslation(['global', 'actionContent']);

    const itemsSelected = useAppSelector(selectItemsSelectedGame3);
    const statusTetris = useAppSelector(selectStatusTetris);
    const overlayType = useAppSelector(selectCurrentOverlayType);
    const itemsFound = useAppSelector(selectItemsFoundGame3);

    const itemsFoundIds = itemsFound.map((item) => item?.id);

    const isAllTetrisSelected =
        itemsSelected.emotion &&
        itemsSelected.tetriseric &&
        itemsSelected.tetrisfanny &&
        itemsSelected.tetrispaul;
    const isErrorOrSuccessTetrisSelected =
        overlayType === 'success' || overlayType === 'error';

    useEffect(() => {
        if (
            itemsSelected.tetriseric &&
            itemsSelected.tetrisfanny &&
            itemsSelected.tetrispaul
        ) {
            handleUpdateDeviceActivatedStatus(Device.red, true, roomName);
        }
    }, [itemsSelected, roomName]);

    const handleClickBox = (item: ItemsSelected) => {
        handleItemSelected(item, 'emotion', gameStatus, roomName);
        handleUpdateDeviceActivatedStatus(Device.orange, true, roomName);
        handleUpdateDeviceActivatedStatus(Device.blue, true, roomName);
        handleUpdateDeviceActivatedStatus(Device.green, true, roomName);
        socket?.emit('send_update_status_tetris', {
            roomName,
            statusTetris: 'not-ready',
        });
    };
    if (isErrorOrSuccessTetrisSelected) {
        if (statusTetris === 'waiting-validation') {
            return (
                <TabletScreen
                    typeBackground="circle"
                    step="action"
                    deviceColor={deviceColor}
                >
                    <ActionButton
                        title={
                            overlayType === 'success'
                                ? t('buttons.continue')
                                : t('buttons.retry')
                        }
                        action={
                            overlayType === 'success'
                                ? 'successValidation'
                                : 'startTetris'
                        }
                        type={overlayType}
                    />
                </TabletScreen>
            );
        }
        return (
            <TabletScreen
                typeBackground="circle"
                step="action"
                deviceColor={deviceColor}
            >
                <ActionButton
                    title={
                        overlayType === 'success'
                            ? t('buttons.next')
                            : t('buttons.retry')
                    }
                    action={
                        overlayType === 'success'
                            ? 'startTetris'
                            : 'tryAgainShapeSelection'
                    }
                    type={overlayType}
                />
            </TabletScreen>
        );
    }

    if (isAllTetrisSelected) {
        return (
            <div className={globalStyle.itemBoxContainer}>
                <TabletScreen
                    header={{
                        title: t(
                            `${gameStatus}.${deviceColor}.headerContent.confirm`,
                            { ns: 'actionContent' }
                        ),
                        text: '',
                    }}
                    typeBackground="circle"
                >
                    <div className={globalStyle.itemBoxContainer}>
                        <ActionButton
                            title={t('buttons.next')}
                            action="validationListTetris"
                            type="neutral"
                        />
                    </div>
                </TabletScreen>
            </div>
        );
    }

    return (
        <div className={globalStyle.itemBoxContainer}>
            <TabletScreen
                step="action"
                deviceColor={deviceColor}
                typeBackground="circle"
            >
                {!itemsSelected.emotion && (
                    <div className={globalStyle.itemBoxContainer}>
                        {dataGlobal.emotions.map((emotion) => {
                            if (itemsFoundIds.includes(emotion.id)) {
                                return null;
                            }
                            return (
                                <Button
                                    text={`${t(
                                        `${gameStatus}.tv.bodyContent.footer`,
                                        { ns: 'actionContent' }
                                    )} ${emotion.id}`}
                                    onClick={() => {
                                        handleClickBox(emotion as ItemsSelected);
                                        playSound(ClickTetris);
                                    }}
                                    key={emotion.slug}
                                    backgroundColor="neutral"
                                />
                            );
                        })}
                    </div>
                )}
            </TabletScreen>
        </div>
    );
};

export default ChooseGlobalFormTetris;
