import { useCallback } from 'react';
import {
    SingleStarBigGreen,
    SingleStarMediumGreen,
    SingleStarSmallGreen,
} from 'src/assets/images/global';
import styles from 'src/features/reusablecomponents/stars/star.module.scss';

interface StarProps {
    style: React.CSSProperties;
}

const SMALL_STAR_THRESHOLD = 0.33;
const MEDIUM_STAR_THRESHOLD = 0.66;

const Star: React.FC<StarProps> = ({ style }) => {
    const getRandomStarSize = useCallback(() => {
        const randomNum = Math.random();
        if (randomNum < SMALL_STAR_THRESHOLD) return SingleStarSmallGreen;
        if (randomNum < MEDIUM_STAR_THRESHOLD) return SingleStarMediumGreen;
        return SingleStarBigGreen;
    }, []);

    return (
        <img
            src={getRandomStarSize()}
            className={styles.star}
            style={style}
            alt="star"
        />
    );
};

export default Star;
