// scss && assets
import { motion } from 'framer-motion';
import { PatternHorizontalCard } from 'src/assets/images/tablets/index';
import styles from 'src/features/devices/tablets/components/game2Action/chooseStimuli.module.scss';
import globalStyle from 'src/styles/app.module.scss';

// components
import TabletScreen from 'src/features/devices/tablets/components/screens/TabletScreen';

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from 'src/features/devices/tablets/components/actionButton/ActionButton';
import { useAppSelector } from 'src/services/hooks';
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import {
    type EnvironmentsKey,
    selectItemsSelectedGame2,
} from 'src/services/reducers/games/game2Slice';
import { selectRoomName } from 'src/services/reducers/roomSlice';
import { handleItemSelected } from 'src/utils/device/deviceHandleFunctions';
import { playSound } from 'src/utils/global/globalHandleFunctions';
import {
    type Device,
    type ItemsSelected,
    type Stimulus,
} from 'src/utils/global/types';
import { isObjectEmpty } from 'src/utils/device/deviceUtils';

interface ChooseStimuliProps {
    type: keyof EnvironmentsKey;
}

const ChooseStimuli: React.FC<ChooseStimuliProps> = ({ type }) => {
    const roomName = useAppSelector(selectRoomName);
    const itemsSelected = useAppSelector(selectItemsSelectedGame2);
    const { t } = useTranslation(['global', 'actionContent']);

    const [headerContent, setHeaderContent] = useState({
        title: '',
        text: '',
    });
    const gameStatus = useAppSelector(selectCurrentGameStatus);
    const handleClickBox = (item: ItemsSelected, type: string) => {
        handleItemSelected(item, type, gameStatus, roomName);
    };

    const handleTextHeader = useCallback(
        (type: string) => {
            const colors = {
                friends: 'orange' as Device,
                work: 'blue' as Device,
                family: 'red' as Device,
                sport: 'green' as Device,
            };

            const color = colors[type as keyof typeof colors];

            setHeaderContent({
                title: t(`${gameStatus}.${color}.headerContent.title`, {
                    ns: 'actionContent',
                }),
                text: t(`${gameStatus}.${color}.headerContent.text`, {
                    ns: 'actionContent',
                }),
            });
        },
        [t, gameStatus]
    );
    useEffect(() => {
        handleTextHeader(type);
    }, [type, itemsSelected, handleTextHeader]);

    const handleSelectedStimuli = (id: number) => {
        const { emotion, ...selectedItems } = itemsSelected;
        return Object.values(selectedItems).some((item) => item && item.id === id);
    };
    const stimuli = t(`GAME2ACTION.environments.${type}.stimuli`, {
        returnObjects: true,
    }) as Stimulus[];

    return (
        <TabletScreen
            header={{
                title: headerContent.title,
                text: headerContent.text,
            }}
            typeBackground="empty"
        >
            <div className={globalStyle.itemBoxContainer}>
                <div className={styles.stimuliContainer}>
                    {stimuli.map((stimulus) => (
                        <div className={styles.cardContainer} key={stimulus.id}>
                            <motion.button
                                whileTap={{ scale: 0.9 }}
                                onClick={() => {
                                    handleClickBox(stimulus as ItemsSelected, type);
                                    playSound(stimulus.sound);
                                }}
                                className={
                                    handleSelectedStimuli(
                                        stimulus.id as Stimulus['id']
                                    )
                                        ? styles.selected
                                        : ''
                                }
                            >
                                <img src={stimulus.image} alt="carte stimulus" />
                            </motion.button>
                            <img
                                src={PatternHorizontalCard}
                                alt=""
                                className={styles.patternHorizontalCard}
                            />
                            <p>{stimulus.name}</p>
                        </div>
                    ))}
                </div>
            </div>
            {!isObjectEmpty(itemsSelected.work) &&
                !isObjectEmpty(itemsSelected.family) &&
                !isObjectEmpty(itemsSelected.friends) &&
                !isObjectEmpty(itemsSelected.sport) && (
                    <div className={styles.btnBottom}>
                        <ActionButton
                            title={t('buttons.validate')}
                            action="handleIsConfirmSelectionButton"
                            type="neutral"
                        />
                    </div>
                )}
        </TabletScreen>
    );
};

export default ChooseStimuli;
