import { type OverlayDescription } from 'src/services/reducers/overlaySlice';

import { getSocket } from 'src/utils/global/globalHandleFunctions';
import { type OverlayType } from 'src/utils/global/types';
import { handleTabletStatus } from '../device/deviceHandleFunctions';

export const handleUpdateOverlayIndex = (
    overlayIndex: number,
    roomName: string
): void => {
    const socket = getSocket();
    const responsesSocket = {
        roomName: roomName,
        overlayIndex: overlayIndex,
    };
    socket?.emit('send_update_overlay_index', responsesSocket);
};

export const handleUpdateOverlayDescriptions = (
    overlayDescriptions: OverlayDescription[],
    overlayType: OverlayType,
    roomName: string
): void => {
    const socket = getSocket();
    const responsesSocket = {
        roomName,
        overlayDescriptions,
        overlayType,
    };
    socket?.emit('send_update_overlay_descriptions', responsesSocket);
};

export const handleStartTetris = (roomName: string): void => {
    const socket = getSocket();
    socket?.emit('send_update_status_tetris', {
        roomName,
        statusTetris: 'start',
    });
    handleUpdateOverlayDescriptions([], 'neutral', roomName);
    handleTabletStatus(
        {
            blue: true,
            green: true,
            orange: true,
            red: true,
        },
        roomName
    );
};
