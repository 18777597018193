import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/services/hooks';

// components
import ActionButton from 'src/features/devices/tablets/components/actionButton/ActionButton';
import ChooseIntensityEmotion from 'src/features/devices/tablets/components/game1action2/ChooseIntensityEmotion';
import TabletScreen from 'src/features/devices/tablets/components/screens/TabletScreen';
import SplashScreen from 'src/features/reusablecomponents/global/splashScreen/SplashScreen';

// utils
import { handleStartGame } from 'src/utils/global/globalHandleFunctions';

// reducers
import { selectDeviceStatus } from 'src/services/reducers/deviceSlice';
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import { selectRoomName } from 'src/services/reducers/roomSlice';

// assets
import ChooseStimuli from 'src/features/devices/tablets/components/game2Action/ChooseStimuli';
import ChooseTetris from 'src/features/devices/tablets/components/game3/ChooseTetris';
import RemoteTetris from 'src/features/devices/tablets/components/game3/RemoteTetris';
import OrangeGame1Action1 from 'src/features/devices/tablets/orange/games/game1action1/OrangeGame1Action1';
import ChooseBehavior from 'src/features/devices/tablets/orange/games/game4/ChooseBehavior';
import { selectStatusTetris } from 'src/services/reducers/games/game3Slice';
import globalStyle from 'src/styles/app.module.scss';

import Vote from 'src/features/devices/tablets/components/game5/Vote';
import NextButtonScreen from 'src/features/devices/tablets/components/screens/NextButtonScreen';
import { Device, GameStatus } from 'src/utils/global/types';

const OrangeActionSwitch: React.FC = () => {
    const roomName = useAppSelector(selectRoomName);
    const { t } = useTranslation('global');

    const currentGameStatus = useAppSelector(selectCurrentGameStatus);
    const { orange } = useAppSelector(selectDeviceStatus);
    const statusTetris = useAppSelector(selectStatusTetris);
    const deviceColor = Device.orange;

    const isTabletInactive = !orange.isActivated;

    useEffect(() => {
        document.title = `🟠 ${orange.label}`;
    }, [orange.label]);

    const handleRender = () => {
        switch (currentGameStatus) {
            case GameStatus.WAITING:
                return (
                    <SplashScreen
                        onConnect={() => handleStartGame(deviceColor, roomName)}
                    />
                );
            case GameStatus.WELCOME_SCREEN:
                return (
                    <TabletScreen
                        typeBackground="circle"
                        header={{
                            title: t('headers.welcome.title'),
                            text: t('headers.welcome.text'),
                        }}
                    >
                        <ActionButton
                            title={t('headers.welcome.buttonText')}
                            action="goToNextGameStatus"
                            type="neutral"
                        />
                    </TabletScreen>
                );
            case GameStatus.INTRODUCTION:
            case GameStatus.GAME1ACTION1INTRODUCTION:
            case GameStatus.GAME4INTRODUCTION:
                return (
                    <NextButtonScreen
                        deviceColor={deviceColor}
                        action="goToNextIntroductionOverlay"
                    />
                );

            case GameStatus.GAME1ACTION1:
                return <OrangeGame1Action1 />;
            case GameStatus.GAME1ACTION2:
                return <ChooseIntensityEmotion intensity="weakEmotion" />;
            case GameStatus.GAME2ACTION:
                return <ChooseStimuli type="friends" />;

            case GameStatus.GAME3ACTION:
                return (
                    <>
                        {statusTetris === 'start' ? (
                            <TabletScreen
                                header={{
                                    title: t(
                                        `${currentGameStatus}.remotes.rotation.title`
                                    ),
                                    text: t(
                                        `${currentGameStatus}.remotes.rotation.title`
                                    ),
                                }}
                                typeBackground="circle"
                            >
                                <RemoteTetris type="up" />
                            </TabletScreen>
                        ) : (
                            <TabletScreen
                                header={{
                                    title: t(
                                        `${currentGameStatus}.${deviceColor}.headerContent.title`,
                                        { ns: 'actionContent' }
                                    ),
                                    text: t(
                                        `${currentGameStatus}.${deviceColor}.headerContent.text`,
                                        { ns: 'actionContent' }
                                    ),
                                }}
                                typeBackground="circle"
                            >
                                <ChooseTetris character="paul" />
                            </TabletScreen>
                        )}
                    </>
                );
            case GameStatus.GAME4ACTION:
                return <ChooseBehavior />;
            case GameStatus.GAME5ACTION:
                return (
                    <TabletScreen
                        step="action"
                        deviceColor={deviceColor}
                        typeBackground="empty"
                    >
                        <Vote deviceColor={deviceColor} />
                    </TabletScreen>
                );
            case GameStatus.FINISHED:
                break;
        }
    };

    return (
        <div className={globalStyle.containerTablet}>
            {isTabletInactive ? (
                <TabletScreen
                    header={{
                        title: t('headers.inactive.title'),
                        text: t('headers.inactive.text'),
                    }}
                    typeBackground="circle"
                />
            ) : (
                <>{handleRender()}</>
            )}
        </div>
    );
};

export default OrangeActionSwitch;
