import { useAppDispatch, useAppSelector } from 'src/services/hooks';

// assets
import { motion } from 'framer-motion';
import styles from 'src/features/devices/tv/components/games/game5/game5Action.module.scss';

// datas
import { useCallback, useEffect, useState } from 'react';
import { Robot, Scientist } from 'src/assets/images/characters';
import Conversation from 'src/features/devices/tv/components/conversation/Conversation';
import ProgressBarTimer from 'src/features/devices/tv/components/progressBarTimer/ProgressBarTimer';
import StarField from 'src/features/reusablecomponents/stars/StarField';
import dataGlobal from 'src/services/datas/global.json';
import {
    selectCurrentGameStatus,
    selectGameResult,
} from 'src/services/reducers/gameSlice';
import { selectRoomName } from 'src/services/reducers/roomSlice';
import { validateGame5 } from 'src/utils/device/deviceHandleFunctions';
import {
    selectCurrentIndexGame5,
    selectItemsSelectedGame5,
} from 'src/services/reducers/games/game5Slice';
import { useTranslation } from 'react-i18next';
import { type Situation } from 'src/utils/global/types';

const NUMBER_SECONDS_TO_WAIT = 30;
const NUMBER_OF_PLAYERS = 4;

const variants = {
    initial: {
        opacity: 0,
        scale: 0,
    },
    animate: {
        opacity: 1,
        scale: 1,
    },
};

const Game5Action: React.FC = () => {
    const currentIndex = useAppSelector(selectCurrentIndexGame5);
    const itemsSelected = useAppSelector(selectItemsSelectedGame5);

    const dispatch = useAppDispatch();

    const roomName = useAppSelector(selectRoomName);
    const currentGameStatus = useAppSelector(selectCurrentGameStatus);
    const { t } = useTranslation(['global', 'actionContent']);

    const currentSituation = t(`${currentGameStatus}.situations.${currentIndex}`, {
        returnObjects: true,
    }) as Situation;

    const gameResult = useAppSelector(selectGameResult);

    const [showTimer, setShowTimer] = useState(false);
    const [isSecondTry, setIsSecondTry] = useState(false);
    const [isFirstAudioEnded, setIsFirstAudioEnded] = useState(false);
    const getPlayersAnswer = (idResponse: number) => {
        const devicesAnswer: string[] = [];
        Object.keys(itemsSelected)
            .filter(
                (itemSelected) =>
                    itemsSelected[itemSelected as keyof typeof itemsSelected]
                        ?.id === idResponse
            )
            .map((deviceColor) => {
                devicesAnswer.push(deviceColor);
            });
        return devicesAnswer;
    };

    const areAllPlayersAnswered =
        Object.keys(itemsSelected).length === NUMBER_OF_PLAYERS;

    const areAllPlayersAgree = useCallback(() => {
        const devicesAnswer: (number | undefined)[] = [];
        Object.keys(itemsSelected).forEach((itemSelected) => {
            const key = itemSelected as keyof typeof itemsSelected;
            if (itemsSelected[key]) {
                devicesAnswer.push(itemsSelected[key]?.id);
            }
        });
        return devicesAnswer.every((val, i, arr) => val === arr[0]);
    }, [itemsSelected]);

    useEffect(() => {
        setShowTimer(false);
        setIsSecondTry(false);
    }, [currentIndex]);

    useEffect(() => {
        if (areAllPlayersAnswered) {
            if (!areAllPlayersAgree() && !isSecondTry) {
                setShowTimer(true);
                setIsSecondTry(true);
            } else if (
                (!areAllPlayersAgree() && isSecondTry && !showTimer) ||
                (areAllPlayersAgree() && currentIndex === currentSituation.id - 1)
            ) {
                validateGame5(
                    itemsSelected,
                    roomName,
                    currentIndex + 1,
                    areAllPlayersAgree()
                );
            }
        }
    }, [
        areAllPlayersAgree,
        currentGameStatus,
        currentIndex,
        dispatch,
        isSecondTry,
        itemsSelected,
        roomName,
        currentSituation.id,
        areAllPlayersAnswered,
        showTimer,
    ]);

    const handleStyleBox = (response: { id: number; text: string }) => {
        const isGoodCombination =
            response.id ===
            dataGlobal.GAME5ACTION.goodCombinations[currentIndex].idResponse;

        if (gameResult === 'success' && isGoodCombination) {
            return styles.success;
        }

        if (gameResult === 'error' && !isGoodCombination) {
            return styles.error;
        }

        return undefined;
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsFirstAudioEnded(true);
            // Temporary time, for now it is 5 seconds.
        }, currentSituation.time);

        return () => {
            clearTimeout(timeout);
        };
    });

    return (
        <div className={styles.game5Container}>
            <img src={Scientist} alt="Scientifique" />
            <div className={styles.wrapper}>
                <div className={styles.answers}>
                    <Conversation
                        type={'scientist'}
                        currentSituation={currentSituation}
                        showTimer={showTimer}
                    />
                    {isFirstAudioEnded && (
                        <Conversation
                            type={'robot'}
                            currentSituation={currentSituation}
                        />
                    )}
                </div>
                {showTimer && (
                    <ProgressBarTimer
                        time={NUMBER_SECONDS_TO_WAIT}
                        callback={setShowTimer}
                    />
                )}
                <div className={styles.playerAnswerContainer}>
                    {currentSituation.responses.map((response) => {
                        return (
                            <div
                                key={response.id}
                                className={handleStyleBox(response)}
                            >
                                <p>
                                    {t(
                                        `${currentGameStatus}.tv.bodyContent.responseTitle`,
                                        { ns: 'actionContent' }
                                    )}
                                    {response.id}
                                </p>
                                <div>
                                    {getPlayersAnswer(response.id).map((device) => (
                                        <motion.span
                                            key={device}
                                            className={styles[device]}
                                            variants={variants}
                                            initial="initial"
                                            animate="animate"
                                        />
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <img src={Robot} alt="Robot" />
            {gameResult === 'success' && <StarField />}
        </div>
    );
};

export default Game5Action;
