import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { type RootState } from 'src/services/store';

import { GameStatus, type OverlayType } from 'src/utils/global/types';
import i18n from 'src/i18n';

export interface OverlayDescription {
    id?: number;
    title: string;
    text: string | string[];
    audio: string;
}

interface OverlayState {
    currentOverlayIndex: number;
    descriptions: OverlayDescription[];
    overlayType: OverlayType;
}

const initialState: OverlayState = {
    currentOverlayIndex: 0,
    descriptions: i18n.t(`${GameStatus.INTRODUCTION}.tv`, {
        ns: 'overlayContent',
        returnObjects: true,
    }),
    overlayType: 'neutral',
};

interface UpdateOverlayDescriptionsPayload {
    overlayDescriptions: OverlayDescription[];
    type: OverlayType;
}

export const OverlaySlice = createSlice({
    name: 'overlay',
    initialState: initialState,
    reducers: {
        updateCurrentOverlayIndex: (state, action: PayloadAction<number>) => {
            state.currentOverlayIndex = action.payload;
        },
        updateCurrentOverlayDescriptions: (
            state,
            {
                payload: { overlayDescriptions, type },
            }: PayloadAction<UpdateOverlayDescriptionsPayload>
        ) => {
            state.descriptions = overlayDescriptions;
            state.overlayType = type;
            state.currentOverlayIndex = 0;
        },
    },
});

export const { updateCurrentOverlayIndex, updateCurrentOverlayDescriptions } =
    OverlaySlice.actions;

export const selectCurrentOverlayType = (state: RootState): OverlayType =>
    state.overlay.overlayType;

export const selectCurrentOverlayIndex = (state: RootState): number =>
    state.overlay.currentOverlayIndex;

export const selectCurrentOverlayDescriptions = (
    state: RootState
): OverlayDescription[] => state.overlay.descriptions;

export default OverlaySlice.reducer;
