import styles from 'src/features/devices/tablets/components/actionButton/actionButton.module.scss';

import { useAppSelector } from 'src/services/hooks';

// components
import Button from 'src/features/reusablecomponents/buttons/Button';
import ErrorAndSuccessBackground from 'src/features/reusablecomponents/global/errorAndSuccessBackground/ErrorAndSuccessBackground';

// reducers
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import { selectRoomName } from 'src/services/reducers/roomSlice';

// selectors
import {
    selectCurrentOverlayDescriptions,
    selectCurrentOverlayIndex,
} from 'src/services/reducers/overlaySlice';

// utils
import { Click } from 'src/assets/sounds/soundsEffect';
import { selectIsConfirmSelectionButtonGame2 } from 'src/services/reducers/games/game2Slice';
import {
    errorValidation,
    getNextGameStatus,
    handleGoToGameStatus,
    handleIsConfirmSelectionButton,
    handleNextOrPreviousOverlayDescription,
    handleTabletStatus,
    successValidation,
    validateGameAction,
    validationListTetris,
} from 'src/utils/device/deviceHandleFunctions';
import {
    GameStatus,
    type GameStatusActionWithoutGame5,
    type OverlayType,
} from 'src/utils/global/types';

import { useItemsFound } from 'src/utils/hooks/useItemFounds';
import { useItemsSelected } from 'src/utils/hooks/useItemSelected';
import {
    handleStartTetris,
    handleUpdateOverlayDescriptions,
} from 'src/utils/tv/tvHandleFunctions';

type ActionHandlers = {
    [K in ActionType]: () => void;
};

interface ActionButtonProps {
    title: string;
    action: ActionType;
    type: OverlayType;
}

export type ActionType =
    | 'goToNextIntroductionOverlay'
    | 'goToNextGameStatus'
    | 'handleValidationGame1Action1'
    | 'handleValidationGame1Action2'
    | 'handleIsConfirmSelectionButton'
    | 'handleValidationGame2Action'
    | 'errorValidation'
    | 'startTetris'
    | 'successValidation'
    | 'handleValidationGame4'
    | 'validationListTetris'
    | 'tryAgainShapeSelection'
    | 'validateGame5'
    | 'goToNextPreventOverlay';

const ActionButton: React.FC<ActionButtonProps> = ({ title, action, type }) => {
    const roomName = useAppSelector(selectRoomName);

    const overlayInformations = useAppSelector(selectCurrentOverlayDescriptions);
    const currentOverlayIndex = useAppSelector(selectCurrentOverlayIndex);

    const currentGameStatus = useAppSelector(selectCurrentGameStatus);

    const isConfirmSelectionButton = useAppSelector(
        selectIsConfirmSelectionButtonGame2
    );

    const itemsSelected = useItemsSelected(
        currentGameStatus as GameStatusActionWithoutGame5
    );

    const itemsFound = useItemsFound(
        currentGameStatus as GameStatusActionWithoutGame5
    );

    const actionHandlers: ActionHandlers = {
        goToNextIntroductionOverlay: () =>
            handleNextOrPreviousOverlayDescription(
                'next',
                currentGameStatus,
                overlayInformations,
                currentOverlayIndex,
                roomName
            ),
        goToNextGameStatus: () =>
            handleGoToGameStatus(
                getNextGameStatus(currentGameStatus) as GameStatus,
                roomName
            ),
        handleValidationGame1Action1: () => {
            if (itemsSelected?.emotion && itemsSelected.symbol) {
                validateGameAction({
                    itemsSelected,
                    gameStatus: GameStatus.GAME1ACTION1,
                    roomName,
                });
                handleTabletStatus(
                    {
                        blue: false,
                        green: false,
                        orange: true,
                        red: false,
                    },
                    roomName
                );
            }
        },
        handleValidationGame1Action2: () => {
            validateGameAction({
                itemsSelected,
                gameStatus: GameStatus.GAME1ACTION2,
                roomName,
            });
            handleTabletStatus(
                {
                    blue: false,
                    green: false,
                    orange: false,
                    red: true,
                },
                roomName
            );
        },
        handleIsConfirmSelectionButton: () =>
            handleIsConfirmSelectionButton(!isConfirmSelectionButton, roomName),
        handleValidationGame2Action: () => {
            validateGameAction({
                itemsSelected,
                gameStatus: GameStatus.GAME2ACTION,
                roomName,
            });
            handleTabletStatus(
                {
                    blue: false,
                    green: false,
                    orange: false,
                    red: true,
                },
                roomName
            );
        },
        errorValidation: () => errorValidation(currentGameStatus, roomName),
        startTetris: () => handleStartTetris(roomName),
        successValidation: () =>
            itemsSelected &&
            successValidation(
                currentGameStatus,
                itemsSelected,
                itemsFound,
                roomName
            ),
        handleValidationGame4: () => {
            if (itemsSelected?.code && itemsSelected?.behavior) {
                validateGameAction({
                    itemsSelected,
                    gameStatus: currentGameStatus as GameStatusActionWithoutGame5,
                    roomName,
                });
                handleTabletStatus(
                    {
                        blue: false,
                        green: true,
                        orange: false,
                        red: false,
                    },
                    roomName
                );
            }
        },
        validationListTetris: () => {
            if (itemsSelected?.emotion) {
                validationListTetris(currentGameStatus, roomName, itemsSelected);
                handleTabletStatus(
                    {
                        blue: false,
                        green: false,
                        orange: false,
                        red: true,
                    },
                    roomName
                );
            }
        },
        tryAgainShapeSelection: () => {
            handleUpdateOverlayDescriptions([], 'neutral', roomName);
            handleTabletStatus(
                {
                    blue: true,
                    green: true,
                    orange: true,
                    red: true,
                },
                roomName
            );
        },
        validateGame5: () =>
            handleUpdateOverlayDescriptions([], 'neutral', roomName),
        goToNextPreventOverlay: () =>
            handleNextOrPreviousOverlayDescription(
                'next',
                currentGameStatus,
                overlayInformations,
                currentOverlayIndex,
                roomName
            ),
    };

    const handleClick = () => {
        const handler = actionHandlers[action];
        if (handler) {
            handler();
        }
    };

    return (
        <div className={styles.buttonContainer}>
            {type !== 'neutral' && type !== 'unlock' && (
                <ErrorAndSuccessBackground type={type} device="tablet" />
            )}
            <Button
                text={title}
                onClick={() => handleClick()}
                soundPath={Click}
                backgroundColor={type}
            />
        </div>
    );
};

export default ActionButton;
