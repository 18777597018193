import styles from 'src/features/devices/tv/components/games/game4/game4Action.module.scss';

import { useAppSelector } from 'src/services/hooks';
import {
    selectItemsFoundGame4,
    selectItemsSelectedGame4,
} from 'src/services/reducers/games/game4Slice';
import CarouselCharacter from 'src/features/devices/tv/components/games/game4/CarouselCharacter';
import { selectGameResult } from 'src/services/reducers/gameSlice';
import AudioComponent from 'src/features/reusablecomponents/global/AudioComponent';

const Game4Action: React.FC = () => {
    const itemsSelected = useAppSelector(selectItemsSelectedGame4);
    const itemsFound = useAppSelector(selectItemsFoundGame4);

    const gameResult = useAppSelector(selectGameResult);

    const handleStyleGameResult = () => {
        if (gameResult === 'error') return styles.error;
        if (gameResult === 'success') return styles.success;
        else return styles.neutral;
    };

    const isBehaviorFound = itemsFound.find(
        (item) => item?.id === itemsSelected?.behavior?.emotionId
    );

    return (
        <div className={styles.game4ActionContainer}>
            {itemsSelected.behavior?.audio && !isBehaviorFound && (
                <AudioComponent src={itemsSelected.behavior.audio} />
            )}
            <div className={handleStyleGameResult()}>
                <div className={styles.borderLeftBox}>
                    <h1>{itemsSelected?.behavior?.name}</h1>
                    <p>{itemsSelected?.behavior?.behavior}</p>
                </div>
            </div>
            <CarouselCharacter />
        </div>
    );
};

export default Game4Action;
