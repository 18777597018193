import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { type RootState } from 'src/services/store';

// utils
import {
    type Emotion,
    type Game1Action1,
    GameStatus,
    type HeaderContent,
    type Item,
} from 'src/utils/global/types';

import headerDatas from 'src/services/datas/actionContent.json';
import dataGlobal from 'src/services/datas/global.json';

interface UpdateItemsSelectedPayload {
    item: Item;
    itemKey: keyof Game1Action1;
}

export interface Game1Action1State {
    itemsSelected: Game1Action1;
    itemsFound: Item[];
    headerContent: HeaderContent;
}

const initialState: Game1Action1State = {
    itemsSelected: {} as Game1Action1,
    itemsFound: new Array(dataGlobal.emotions.length).fill(null),
    headerContent: headerDatas[GameStatus.GAME1ACTION1].tv.headerContent,
};

export const Game1Action1Slice = createSlice({
    name: 'game1Action1',
    initialState,
    reducers: {
        updateItemsSelectedGame1Action1: (
            state,
            action: PayloadAction<UpdateItemsSelectedPayload>
        ) => {
            const { itemKey: type, item } = action.payload;
            state.itemsSelected = {
                ...state.itemsSelected,
                [type]: item,
            };
        },
        resetItemsSelectedGame1Action1: (state) => {
            state.itemsSelected = initialState.itemsSelected;
        },
        updateItemsFoundGame1Action1: (state, action: PayloadAction<Emotion>) => {
            const indexItem = action.payload.id - 1;
            state.itemsFound[indexItem] = action.payload;
        },
        resetItemsFoundGame1Action1: (state) => {
            state.itemsFound = initialState.itemsFound;
        },
    },
});

export const {
    updateItemsSelectedGame1Action1,
    resetItemsSelectedGame1Action1,
    updateItemsFoundGame1Action1,
    resetItemsFoundGame1Action1,
} = Game1Action1Slice.actions;

export const selectItemsFoundGame1Action1 = (state: RootState): Item[] =>
    state.game1Action1.itemsFound;

export const selectItemsSelectedGame1Action1 = (state: RootState): Game1Action1 =>
    state.game1Action1.itemsSelected;

export default Game1Action1Slice.reducer;
