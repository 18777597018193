import { updateItemsSelectedGame1Action1 } from 'src/services/reducers/games/game1Action1Slice';
import { updateItemsSelectedGame1Action2 } from 'src/services/reducers/games/game1Action2Slice';
import { updateItemsSelectedGame2 } from 'src/services/reducers/games/game2Slice';
import { updateItemsSelectedGame3 } from 'src/services/reducers/games/game3Slice';
import { updateItemsSelectedGame4 } from 'src/services/reducers/games/game4Slice';
import { updateItemsSelectedGame5 } from 'src/services/reducers/games/game5Slice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dynamicFunctions: any = {
    updateItemsSelectedGame1Action1,
    updateItemsSelectedGame1Action2,
    updateItemsSelectedGame2,
    updateItemsSelectedGame3,
    updateItemsSelectedGame4,
    updateItemsSelectedGame5,
};

export default dynamicFunctions;
