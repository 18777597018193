import { useTranslation } from 'react-i18next';
import Cell from 'src/features/devices/tv/components/games/game3/Cell';
import styles from 'src/features/devices/tv/components/games/game3/Game3Action.module.scss';
import boardStyles from 'src/features/devices/tv/components/games/game3/board/board.module.scss';
import { useAppSelector } from 'src/services/hooks';
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import { selectItemsSelectedGame3 } from 'src/services/reducers/games/game3Slice';
import { type Block, type BoardShape } from 'src/utils/global/types';

interface BoardProps {
    currentBoard: BoardShape;
}

const Board: React.FC<BoardProps> = ({ currentBoard }) => {
    const itemsSelected = useAppSelector(selectItemsSelectedGame3);
    const { t } = useTranslation('actionContent');
    const gameStatus = useAppSelector(selectCurrentGameStatus);

    return (
        <div>
            <div className={styles.board}>
                {currentBoard?.map((row, rowIndex) => (
                    <div className={styles.row} key={`${rowIndex}`}>
                        {row.map((cell, colIndex) => (
                            <Cell
                                key={`${rowIndex}-${colIndex}`}
                                type={cell as Block}
                                colIndex={colIndex}
                                rowIndex={rowIndex}
                            />
                        ))}
                    </div>
                ))}
            </div>
            {itemsSelected.emotion ? (
                <p className={boardStyles.subtitle}>
                    {t(`${gameStatus}.tv.bodyContent.footer`)}{' '}
                    {itemsSelected?.emotion?.id}
                </p>
            ) : (
                <p className={boardStyles.subtitle}>
                    {t(`${gameStatus}.tv.bodyContent.choose`)}
                </p>
            )}
        </div>
    );
};

export default Board;
