import type React from 'react';
import MediatorAction from 'src/features/devices/tablets/mediator/MediatorAction';
import { useAppSelector } from 'src/services/hooks';
import { selectDeviceStatus } from 'src/services/reducers/deviceSlice';
import { selectRoomName } from 'src/services/reducers/roomSlice';
import { handleUpdateDeviceActivatedStatus } from 'src/utils/global/globalHandleFunctions';
import { Device } from 'src/utils/global/types';

const ToggleTabletStatus: React.FC = () => {
    const roomName = useAppSelector(selectRoomName);

    const { red, blue, orange, green } = useAppSelector(selectDeviceStatus);
    return (
        <div>
            <MediatorAction
                handleFunction={() =>
                    handleUpdateDeviceActivatedStatus(
                        Device.green,
                        !green.isActivated,
                        roomName
                    )
                }
                title={`${
                    green.isActivated ? 'Désactiver' : 'Activer'
                } la tablette verte`}
                description={`Cette action ${
                    green.isActivated ? 'désactive' : 'active'
                } la tablette verte.`}
                displayingCondition={true}
            />
            <MediatorAction
                handleFunction={() =>
                    handleUpdateDeviceActivatedStatus(
                        Device.orange,
                        !orange.isActivated,
                        roomName
                    )
                }
                title={`${
                    orange.isActivated ? 'Désactiver' : 'Activer'
                } la tablette orange`}
                description={`Cette action ${
                    orange.isActivated ? 'désactive' : 'active'
                } la tablette orange.`}
                displayingCondition={true}
            />
            <MediatorAction
                handleFunction={() =>
                    handleUpdateDeviceActivatedStatus(
                        Device.blue,
                        !blue.isActivated,
                        roomName
                    )
                }
                title={`${
                    blue.isActivated ? 'Désactiver' : 'Activer'
                } la tablette bleue`}
                description={`Cette action ${
                    blue.isActivated ? 'désactive' : 'active'
                } la tablette bleue.`}
                displayingCondition={true}
            />
            <MediatorAction
                handleFunction={() =>
                    handleUpdateDeviceActivatedStatus(
                        Device.red,
                        !red.isActivated,
                        roomName
                    )
                }
                title={`${
                    red.isActivated ? 'Désactiver' : 'Activer'
                } la tablette rouge`}
                description={`Cette action ${
                    red.isActivated ? 'désactive' : 'active'
                } la tablette rouge.`}
                displayingCondition={true}
            />
        </div>
    );
};

export default ToggleTabletStatus;
