import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/services/hooks';

// components
import Game1Action1 from 'src/features/devices/tv/components/games/game1/Game1Action1';
import Game1Action2 from 'src/features/devices/tv/components/games/game1/Game1Action2';
import Game2Action from 'src/features/devices/tv/components/games/game2/Game2Action';
import Game3Action from 'src/features/devices/tv/components/games/game3/Game3Action';
import Game4Action from 'src/features/devices/tv/components/games/game4/Game4Action';
import Game5Action from 'src/features/devices/tv/components/games/game5/Game5Action';
import WelcomeScreen from 'src/features/devices/tv/components/welcome/WelcomeScreen';
import SplashScreen from 'src/features/reusablecomponents/global/splashScreen/SplashScreen';

// reducers
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import { selectRoomName } from 'src/services/reducers/roomSlice';

//utils
import { getSocket, handleStartGame } from 'src/utils/global/globalHandleFunctions';

// datas

// scss
import styles from 'src/styles/app.module.scss';

import { clickButtonTetris } from 'src/services/reducers/games/game3Slice';
import {
    selectCurrentOverlayDescriptions,
    selectCurrentOverlayType,
} from 'src/services/reducers/overlaySlice';

import { Ambiance } from 'src/assets/sounds/soundsEffect';
import Layout from 'src/features/devices/tv/Layout';
import Overlay from 'src/features/devices/tv/components/overlay/Overlay';
import { Device, GameStatus } from 'src/utils/global/types';

const Tv: React.FC = () => {
    const roomName = useAppSelector(selectRoomName);
    const socket = getSocket();
    const dispatch = useAppDispatch();
    const currentGameStatus = useAppSelector(selectCurrentGameStatus);

    const currentOverlayDescriptions = useAppSelector(
        selectCurrentOverlayDescriptions
    );
    const isCurrentOverlay = currentOverlayDescriptions.length > 0;

    const overlayType = useAppSelector(selectCurrentOverlayType);

    const status = overlayType;

    const [isGameStarted, setIsGameStarted] = useState(false);

    useEffect(() => {
        if (isGameStarted) return;
        document.title = '🖥️ TV';
        if (
            currentGameStatus === GameStatus.WAITING ||
            currentGameStatus === GameStatus.WELCOME_SCREEN
        )
            return;
        const ambianceSound = new Audio(Ambiance);
        ambianceSound.loop = true;
        ambianceSound.volume = 0.2;
        ambianceSound.play();
        setIsGameStarted(true);
    }, [currentGameStatus, isGameStarted]);
    const deviceType = Device.tv;

    useEffect(() => {
        socket?.on('room_created', (data: { room: string }) => {
            socket?.emit('join_room', data);
        });
    }, [roomName, socket]);

    useEffect(() => {
        socket?.on(
            'receive_click_type_tetris',
            (data: { typeMouse: string; action: string }) => {
                dispatch(
                    clickButtonTetris({
                        typeMouse: data.typeMouse,
                        actionMouse: data.action,
                    })
                );
            }
        );
    }, [dispatch, socket]);

    const getActionGameComponent = (gameStatus: GameStatus) => {
        switch (gameStatus) {
            case GameStatus.GAME1ACTION1:
                return <Game1Action1 />;
            case GameStatus.GAME1ACTION2:
                return <Game1Action2 />;
            case GameStatus.GAME2ACTION:
                return <Game2Action />;
            case GameStatus.GAME3ACTION:
                return <Game3Action />;
            case GameStatus.GAME4ACTION:
                return <Game4Action />;
            case GameStatus.GAME5ACTION:
                return <Game5Action />;
            default:
        }
    };

    const handleRender = () => {
        switch (currentGameStatus) {
            case GameStatus.WAITING:
                return (
                    <SplashScreen
                        onConnect={() => {
                            handleStartGame(deviceType, roomName);
                        }}
                    />
                );
            case GameStatus.WELCOME_SCREEN:
                return <WelcomeScreen isWelcomeScreen />;
            case GameStatus.INTRODUCTION:
            case GameStatus.GAME1ACTION1INTRODUCTION:
            case GameStatus.GAME1ACTION2INTRODUCTION:
            case GameStatus.GAME2INTRODUCTION:
            case GameStatus.GAME3INTRODUCTION:
            case GameStatus.GAME4INTRODUCTION:
            case GameStatus.GAME5INTRODUCTION:
            case GameStatus.GAME1ACTION2PREVENTION:
            case GameStatus.GAME2PREVENTION:
            case GameStatus.GAME3PREVENTION:
            case GameStatus.GAME4PREVENTION:
            case GameStatus.GAME5PREVENTION:
                return <>{isCurrentOverlay && <Overlay type="neutral" />}</>;

            case GameStatus.GAME1ACTION1:
            case GameStatus.GAME1ACTION2:
            case GameStatus.GAME2ACTION:
            case GameStatus.GAME3ACTION:
            case GameStatus.GAME4ACTION:
            case GameStatus.GAME5ACTION:
                return (
                    <>
                        {isCurrentOverlay ? (
                            <Overlay type={overlayType} />
                        ) : (
                            <Layout>
                                {getActionGameComponent(currentGameStatus)}
                            </Layout>
                        )}
                    </>
                );

            case GameStatus.GAME1UNLOCK:
            case GameStatus.GAME2UNLOCK:
            case GameStatus.GAME3UNLOCK:
            case GameStatus.GAME4UNLOCK:
            case GameStatus.GAME5UNLOCK:
                return <Overlay type="unlock" />;

            case GameStatus.FINISHED:
                return <WelcomeScreen isWelcomeScreen={false} />;
        }
    };

    return <div className={styles[`${status}Container`]}>{handleRender()}</div>;
};

export default Tv;
