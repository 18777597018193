import { Scientist } from 'src/assets/images/characters';
import { Eye } from 'src/assets/images/tv';

import styles from 'src/features/devices/tv/components/HUD/footerTv.module.scss';
import overlatStyles from 'src/features/devices/tv/components/overlay/overlay.module.scss';
import TextWritter from 'src/features/reusablecomponents/text/TextWriter';
import { useAppSelector } from 'src/services/hooks';
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import { useTranslation } from 'react-i18next';

const FooterTv: React.FC = () => {
    const currentGameStatus = useAppSelector(selectCurrentGameStatus);
    const { t } = useTranslation('actionContent');

    const currentTitle = t(`${currentGameStatus}.tv.footerContent.title`);
    const currentText = t(`${currentGameStatus}.tv.footerContent.text`);

    return (
        <div className={styles.footerTvContainer}>
            <img src={Scientist} alt="Scientifique" />
            <div className={overlatStyles.textContainer}>
                <div>
                    <img src={Eye} alt="oeil" />
                    <h1>{currentTitle}</h1>
                </div>
                <TextWritter text={currentText} />
            </div>
        </div>
    );
};

export default FooterTv;
