import {
    GameStatus,
    type BoardShape,
    type GameStatusActionWithoutGame5,
    type Item,
    type ItemsSelected,
} from 'src/utils/global/types';
// services
import { type OverlayDescription } from 'src/services/reducers/overlaySlice';
import dynamicFunctions from 'src/utils/device/dynamicFunctions';
import {
    getSocket,
    handleGameStatusToString,
    handleSendGameStatus,
    handleUpdateDeviceActivatedStatus,
} from 'src/utils/global/globalHandleFunctions';
import { type Behavior, type Emotion, type Message } from 'src/utils/global/types';
import {
    handleUpdateOverlayDescriptions,
    handleUpdateOverlayIndex,
} from 'src/utils/tv/tvHandleFunctions';

// datas
import i18n from 'src/i18n';
import dataGlobal from 'src/services/datas/global.json';

interface GoodCombination {
    emotionId?: number;
    symbolId?: number;
    weakEmotionId?: number;
    strongEmotionId?: number;
    familyId?: number;
    friendsId?: number;
    sportId?: number;
    workId?: number;
    behaviorId?: number;
    code?: string;
    idSituation?: number;
    idResponse?: number;
}

interface ValidateGameActionParams {
    emotionId?: number;
    symbolId?: number;
    currentBoard?: BoardShape;
    itemsSelected: ItemsSelected;
    gameStatus: GameStatusActionWithoutGame5;
    roomName: string;
}

export const handleTabletStatus = (
    deviceActivationStatus: { [deviceColor: string]: boolean },
    roomName: string
): void => {
    Object.entries(deviceActivationStatus).forEach(([color, status]) => {
        handleUpdateDeviceActivatedStatus(color, status, roomName);
    });
};

/**
 *  config for tablet status on game status
 */
export const tabletStatusConfig = {
    [GameStatus.INTRODUCTION]: {
        blue: false,
        green: false,
        orange: true,
        red: false,
    },
    [GameStatus.GAME1ACTION1INTRODUCTION]: {
        blue: false,
        green: false,
        orange: true,
        red: false,
    },
    [GameStatus.GAME1ACTION1]: {
        blue: false,
        green: true,
        orange: true,
        red: true,
    },
    [GameStatus.GAME1ACTION2INTRODUCTION]: {
        blue: false,
        green: false,
        orange: false,
        red: true,
    },
    [GameStatus.GAME1ACTION2]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },

    [GameStatus.GAME1ACTION2PREVENTION]: {
        blue: false,
        green: false,
        orange: false,
        red: true,
    },
    [GameStatus.GAME1UNLOCK]: {
        blue: false,
        green: false,
        orange: false,
        red: true,
    },
    [GameStatus.GAME2INTRODUCTION]: {
        green: true,
        orange: false,
        red: false,
        blue: false,
    },
    [GameStatus.GAME2ACTION]: {
        green: true,
        orange: true,
        red: true,
        blue: true,
    },

    [GameStatus.GAME2PREVENTION]: {
        green: false,
        orange: false,
        red: true,
        blue: false,
    },
    [GameStatus.GAME2UNLOCK]: {
        green: false,
        orange: false,
        red: true,
        blue: false,
    },
    [GameStatus.GAME3INTRODUCTION]: {
        green: false,
        orange: false,
        red: false,
        blue: true,
    },
    [GameStatus.GAME3ACTION]: {
        green: false,
        orange: false,
        red: true,
        blue: false,
    },

    [GameStatus.GAME3PREVENTION]: {
        green: false,
        orange: false,
        red: true,
        blue: false,
    },
    [GameStatus.GAME3UNLOCK]: {
        green: false,
        orange: false,
        red: true,
        blue: false,
    },
    [GameStatus.GAME4INTRODUCTION]: {
        orange: true,
        blue: false,
        green: false,
        red: false,
    },
    [GameStatus.GAME4ACTION]: {
        orange: true,
        blue: true,
        green: true,
        red: false,
    },

    [GameStatus.GAME4PREVENTION]: {
        green: false,
        orange: false,
        red: true,
        blue: false,
    },
    [GameStatus.GAME4UNLOCK]: {
        green: false,
        orange: false,
        red: true,
        blue: false,
    },
    [GameStatus.GAME5INTRODUCTION]: {
        orange: false,
        blue: false,
        green: false,
        red: true,
    },
    [GameStatus.GAME5ACTION]: {
        orange: true,
        blue: true,
        green: true,
        red: true,
    },

    [GameStatus.GAME5PREVENTION]: {
        green: false,
        orange: false,
        red: true,
        blue: false,
    },
    [GameStatus.GAME5UNLOCK]: {
        green: false,
        orange: false,
        red: true,
        blue: false,
    },
    [GameStatus.FINISHED]: {
        green: false,
        orange: false,
        red: false,
        blue: false,
    },
};

export const handleNextOrPreviousOverlayDescription = (
    action: 'next' | 'previous',
    gameStatus: GameStatus,
    overlayDescription: OverlayDescription[] | undefined,
    currentOverlayIndex: number,
    roomName: string
): void => {
    const nextGameStatus = getNextGameStatus(gameStatus);

    if (action === 'next') {
        const isLastOverlayMessage =
            overlayDescription &&
            currentOverlayIndex === overlayDescription.length - 1;

        if (isLastOverlayMessage) {
            handleGoToGameStatus(nextGameStatus as GameStatus, roomName);
        } else {
            handleUpdateOverlayIndex(currentOverlayIndex + 1, roomName);
        }
    } else if (action === 'previous') {
        const isFirstMessage = overlayDescription && currentOverlayIndex === 0;
        if (!isFirstMessage)
            handleUpdateOverlayIndex(currentOverlayIndex - 1, roomName);
    }
};

export const handleItemDetails = ({
    gameStatus,
    itemsSelected,
    type,
}: {
    gameStatus: GameStatus;
    itemsSelected: ItemsSelected | undefined;
    type: string;
}): {
    listItems: Emotion[] | Behavior[];
    itemIndex: number | string;
} => {
    const listItems: Emotion[] = i18n.t('emotions', {
        ns: 'global',
        returnObjects: true,
    }) as Emotion[];
    let itemIndex: number | string = -1;

    if (itemsSelected) {
        if (type === 'validation') {
            const idToMatch =
                gameStatus === GameStatus.GAME4ACTION
                    ? itemsSelected?.behavior?.emotionId
                    : itemsSelected?.emotion?.id;
            itemIndex = listItems.findIndex((item) => item.id === idToMatch);
        } else if (type === 'found') {
            itemIndex =
                gameStatus === GameStatus.GAME4ACTION ? 'behavior' : 'emotion';
        }
    }
    return {
        listItems,
        itemIndex,
    };
};

export const validateGameAction = ({
    itemsSelected,
    gameStatus,
    currentBoard,
    roomName,
}: ValidateGameActionParams): void => {
    let goodCombination: GoodCombination | boolean | undefined;
    const { listItems, itemIndex } = handleItemDetails({
        gameStatus,
        itemsSelected,
        type: 'validation',
    });
    if (gameStatus === GameStatus.GAME1ACTION1) {
        goodCombination = (
            dataGlobal[gameStatus].goodCombinations as GoodCombination[]
        ).find(
            (combo) =>
                combo.emotionId === itemsSelected?.emotion?.id &&
                combo.symbolId === itemsSelected?.symbol?.id
        );
    } else if (gameStatus === GameStatus.GAME1ACTION2) {
        goodCombination = (
            dataGlobal[gameStatus].goodCombinations as GoodCombination[]
        ).find(
            (combo) =>
                combo.weakEmotionId === itemsSelected?.weakEmotion?.id &&
                combo.emotionId === itemsSelected?.emotion?.id &&
                combo.strongEmotionId === itemsSelected?.strongEmotion?.id
        );
    } else if (gameStatus === GameStatus.GAME2ACTION) {
        goodCombination = (
            dataGlobal[gameStatus].goodCombinations as GoodCombination[]
        ).find(
            (combo) =>
                combo.emotionId === itemsSelected?.emotion?.id &&
                combo.familyId === itemsSelected?.family?.id &&
                combo.friendsId === itemsSelected?.friends?.id &&
                combo.sportId === itemsSelected?.sport?.id &&
                combo.workId === itemsSelected?.work?.id
        );
    } else if (gameStatus === GameStatus.GAME3ACTION) {
        const index = itemsSelected?.emotion?.id ? itemsSelected.emotion.id - 1 : 0;
        const goodBoardTetris =
            dataGlobal[gameStatus].goodCombinations[index].goodBoard;

        const foundGoodBoard = goodBoardTetris.find(
            (goodBoard) =>
                JSON.stringify(goodBoard) === JSON.stringify(currentBoard)
        );
        goodCombination = foundGoodBoard ? true : undefined;
    } else if (gameStatus === GameStatus.GAME4ACTION) {
        goodCombination = dataGlobal[gameStatus].goodCombinations.find(
            (combo) =>
                combo.code === itemsSelected?.code &&
                combo.behaviorId === itemsSelected?.behavior?.id
        );
    }

    // magic number
    if (itemIndex !== -1) {
        const messageKey = goodCombination ? 'successMessage' : 'errorMessage';
        const item = listItems[itemIndex as number] as Emotion;
        const overlayDescriptions = item[messageKey][gameStatus as keyof Message];
        const indexSituation2 = 1;
        const responsesSocket = {
            roomName,
            result: goodCombination ? 'success' : 'error',
        };

        const socket = getSocket();
        socket?.emit('send_update_validation', responsesSocket);

        // Wait 2 second before update overlay to see the animation on the tv
        gameStatus === GameStatus.GAME1ACTION1 ||
        gameStatus === GameStatus.GAME1ACTION2 ||
        gameStatus === GameStatus.GAME4ACTION
            ? setTimeout(() => {
                  handleUpdateOverlayDescriptions(
                      overlayDescriptions,
                      goodCombination ? 'success' : 'error',
                      roomName
                  );
              }, 1500)
            : handleUpdateOverlayDescriptions(
                  gameStatus === GameStatus.GAME3ACTION
                      ? [overlayDescriptions[indexSituation2]]
                      : overlayDescriptions,
                  goodCombination ? 'success' : 'error',
                  roomName
              );
    }
};

export const validateGame5 = (
    itemsSelected: ItemsSelected,
    roomName: string,
    situationIndex: number,
    areAllPlayersAgree: boolean
): void => {
    const socket = getSocket();

    const responsesSocket = {
        roomName,
        result: 'error',
    };

    let goodCombination = false;

    if (areAllPlayersAgree) {
        goodCombination =
            dataGlobal.GAME5ACTION.goodCombinations.find(
                (combo) => combo.idSituation === situationIndex
            )?.idResponse ===
            (itemsSelected?.red?.id &&
                itemsSelected?.green?.id &&
                itemsSelected?.blue?.id &&
                itemsSelected?.orange?.id);

        responsesSocket.result = goodCombination ? 'success' : 'error';
    }

    socket?.emit('send_update_validation', responsesSocket);

    setTimeout(() => {
        const overlayDescriptions = i18n.t(
            `GAME5ACTION.situations.${situationIndex - 1}.${
                goodCombination ? 'successMessage' : 'errorMessage'
            }`,
            {
                ns: 'global',
                returnObjects: true,
            }
        ) as OverlayDescription;

        if (situationIndex === dataGlobal.GAME5ACTION.situations.length) {
            handleGoToGameStatus(GameStatus.GAME5PREVENTION, roomName);
        } else {
            socket?.emit('send_update_situation_game_5', {
                roomName,
                situationIndex,
            });
            if (areAllPlayersAgree) {
                handleUpdateOverlayDescriptions(
                    [overlayDescriptions],
                    goodCombination ? 'success' : 'error',
                    roomName
                );
            } else {
                handleUpdateOverlayDescriptions(
                    [overlayDescriptions],
                    'error',
                    roomName
                );
            }
        }
    }, 1500);

    socket?.emit('send_reset_items_selected', {
        roomName,
        gameStatus: GameStatus.GAME5ACTION,
    });
};

export const handleResetValidation = (
    roomName: string,
    gameStatus: GameStatus
): void => {
    const socket = getSocket();

    const responsesSocket = {
        roomName,
        gameStatus,
    };
    socket?.emit('send_reset_items_selected', responsesSocket);
    handleSendDialValue('', roomName);
};

export const handleItemSelected = (
    item: ItemsSelected,
    itemKey: string,
    gameStatus: GameStatus,
    roomName: string
): void => {
    const socket = getSocket();
    const responsesSocket = {
        item,
        itemKey,
        gameStatus,
        roomName,
    };
    const completed = handleGameStatusToString(gameStatus);
    const nameFunction = `updateItemsSelected${completed}`;
    const selectedFunction = dynamicFunctions[nameFunction];

    if (selectedFunction && typeof selectedFunction === 'function') {
        const itemToUpdate = { item: item, itemKey: itemKey };
        socket?.emit('send_update_item_selected', responsesSocket);
        selectedFunction(itemToUpdate);
    }
};

export const errorValidation = (gameStatus: GameStatus, roomName: string): void => {
    if (gameStatus === GameStatus.GAME3ACTION) {
        handleTabletStatus(
            {
                blue: false,
                green: false,
                orange: false,
                red: true,
            },
            roomName
        );
    } else {
        handleTabletStatus(
            {
                blue: true,
                green: true,
                orange: true,
                red: true,
            },
            roomName
        );
    }
    handleUpdateOverlayDescriptions([], 'neutral', roomName);
    handleResetValidation(roomName, gameStatus);
};

export const successValidation = (
    gameStatus: GameStatus,
    itemsSelected: ItemsSelected,
    itemsFound: Item[],
    roomName: string
): void => {
    const socket = getSocket();
    const { listItems, itemIndex } = handleItemDetails({
        gameStatus,
        itemsSelected,
        type: 'found',
    });

    const idFound =
        itemIndex === 'behavior'
            ? ('emotionId' as keyof Behavior)
            : ('id' as keyof Emotion);

    let itemFound: Emotion | Behavior | undefined;
    if (itemIndex === 'behavior' || itemIndex === 'emotion') {
        itemFound = listItems.find(
            (item: unknown) =>
                (item as Emotion | Behavior).id ===
                itemsSelected[itemIndex]?.[idFound as keyof typeof item]
        );
    }
    const responsesSocket = {
        roomName,
        itemFound,
    };

    const gameStatusString = handleGameStatusToString(gameStatus)?.toLowerCase();
    const titleSocket = `send_update_item_found_${gameStatusString}`;

    if (itemsFound.filter((item) => item?.id).length === listItems.length - 1) {
        handleGoToGameStatus(getNextGameStatus(gameStatus), roomName);
    } else {
        handleGoToGameStatus(gameStatus, roomName);
        if (gameStatus === GameStatus.GAME1ACTION1)
            handleTabletStatus(
                { blue: true, green: true, orange: true, red: true },
                roomName
            );
    }

    socket?.emit(titleSocket, responsesSocket);
    handleResetValidation(roomName, gameStatus);
};

// GAME 2
export const handleIsConfirmSelectionButton = (
    status: boolean,
    roomName: string
): void => {
    const socket = getSocket();
    const responsesSocket = {
        roomName: roomName,
        status: status,
    };

    socket?.emit('send_update_is_confirm_selection_button', responsesSocket);
    if (status) {
        handleTabletStatus(
            {
                blue: false,
                green: true,
                orange: false,
                red: false,
            },
            roomName
        );
    } else
        handleTabletStatus(
            {
                blue: true,
                green: true,
                orange: true,
                red: true,
            },
            roomName
        );
};

export const handleSendDialResetStatus = (
    dialResetStatus: boolean,
    roomName: string
): void => {
    const responseSocket = {
        roomName,
        value: dialResetStatus,
    };
    const socket = getSocket();
    socket?.emit('send_dial_reset_status', responseSocket);
};

export const handleSendDialValue = (dialValue: string, roomName: string): void => {
    const responseSocket = {
        roomName,
        value: dialValue,
    };
    const socket = getSocket();
    socket?.emit('send_dial_value', responseSocket);
};

export const validationListTetris = (
    gameStatus: GameStatus,
    roomName: string,
    itemsSelected?: ItemsSelected
): void => {
    const goodCombination = dataGlobal.GAME3ACTION.goodCombinations.find(
        (combo) =>
            combo.emotionId === itemsSelected?.emotion?.id &&
            combo.fanny === itemsSelected?.tetrisfanny &&
            combo.eric === itemsSelected?.tetriseric &&
            combo.paul === itemsSelected?.tetrispaul
    );

    const { listItems, itemIndex } = handleItemDetails({
        gameStatus,
        itemsSelected,
        type: 'validation',
    });

    const messageKey = goodCombination ? 'successMessage' : 'errorMessage';
    const item = listItems[itemIndex as number] as Emotion;
    const overlayDescriptions = item[messageKey][gameStatus as keyof Message];
    handleUpdateOverlayDescriptions(
        overlayDescriptions,
        goodCombination ? 'success' : 'error',
        roomName
    );
};

export const handleSendCarouselValues = (
    key: 'rightIndex' | 'centerIndex' | 'leftIndex' | 'flowDirection',
    value: number,
    roomName: string
): void => {
    const responseSocket = {
        roomName,
        value,
        key,
    };
    const socket = getSocket();
    socket?.emit('send_carousel_values', responseSocket);
};

export const getNextGameStatus = (gameStatus: GameStatus): GameStatus => {
    const indexCurrentGameStatus = Object.keys(GameStatus).findIndex(
        (status) => status === gameStatus
    );

    return Object.keys(GameStatus)[indexCurrentGameStatus + 1] as GameStatus;
};

export const handleGoToUnlockCode = (
    roomName: string,
    gameStatus: GameStatus
): void => {
    handleUpdateOverlayDescriptions(
        i18n.t(`${gameStatus}.tv`, {
            ns: 'overlayContent',
            returnObjects: true,
        }),
        'neutral',
        roomName
    );
    handleSendGameStatus(gameStatus, roomName);
    handleTabletStatus(
        {
            blue: false,
            green: false,
            orange: false,
            red: true,
        },
        roomName
    );
};

export const handleMouse = (
    typeMouse: string,
    actionType: string,
    roomName: string
): void => {
    const socket = getSocket();
    socket?.emit('send_click_type_tetris', {
        typeMouse,
        action: actionType,
        roomName,
    });
};

export const handleCarouselClick = (
    direction: string,
    itemsSelected: ItemsSelected,
    currentGameStatus: GameStatus,
    listBehaviors: Behavior[],
    leftIndex: number,
    centerIndex: number,
    rightIndex: number,
    roomName: string
): void => {
    const flowDirection = direction === 'next' ? 0 : 1;
    const indexChange = direction === 'next' ? 'decrement' : 'increment';

    handleNextItemSelected(
        direction,
        itemsSelected,
        listBehaviors,
        currentGameStatus,
        roomName
    );
    handleSendCarouselValues('flowDirection', flowDirection, roomName);
    handleIndexChange('leftIndex', indexChange, leftIndex, listBehaviors, roomName);
    handleIndexChange(
        'centerIndex',
        indexChange,
        centerIndex,
        listBehaviors,
        roomName
    );
    handleIndexChange(
        'rightIndex',
        indexChange,
        rightIndex,
        listBehaviors,
        roomName
    );
};

const handleIndexChange = (
    indexName: 'leftIndex' | 'rightIndex' | 'centerIndex',
    mode: 'increment' | 'decrement',
    currentIndex: number,
    listBehaviors: Behavior[],
    roomName: string
) => {
    const behaviorsCount = listBehaviors.length;

    let newIndex = 0;
    if (mode === 'increment') {
        newIndex = (currentIndex + 1) % behaviorsCount;
    } else if (mode === 'decrement') {
        newIndex = (currentIndex - 1 + behaviorsCount) % behaviorsCount;
    }

    handleSendCarouselValues(indexName, newIndex, roomName);
};

const handleNextItemSelected = (
    type: string,
    itemsSelected: ItemsSelected,
    listBehaviors: Behavior[],
    currentGameStatus: GameStatus,
    roomName: string
) => {
    if (!itemsSelected.behavior) return;

    const item = getBehavior(itemsSelected.behavior.id, type, listBehaviors);
    if (item) {
        handleItemSelected(
            item as unknown as ItemsSelected,
            'behavior',
            currentGameStatus,
            roomName
        );
    }
};

const getBehavior = (
    currentId: number,
    direction: string,
    listBehaviors: Behavior[]
) => {
    const behaviorsCount = listBehaviors.length;
    let newId = direction === 'next' ? currentId - 1 : currentId + 1;
    newId = ((newId + behaviorsCount - 1) % behaviorsCount) + 1;

    return listBehaviors.find((behavior) => behavior.id === newId);
};

/**
 *  handleGotoGameStatus init OverlayDescriptions with corresponding gameStatus datas and tablet configuration
 */
export const handleGoToGameStatus = (
    gameStatus: GameStatus,
    roomName: string
): void => {
    handleUpdateOverlayIndex(0, roomName);
    handleSendGameStatus(gameStatus, roomName);
    handleTabletStatus(
        tabletStatusConfig[gameStatus as keyof typeof tabletStatusConfig],
        roomName
    );

    const isGameAction =
        gameStatus === GameStatus.GAME1ACTION1 ||
        gameStatus === GameStatus.GAME1ACTION2 ||
        gameStatus === GameStatus.GAME2ACTION ||
        gameStatus === GameStatus.GAME3ACTION ||
        gameStatus === GameStatus.GAME4ACTION ||
        gameStatus === GameStatus.GAME5ACTION;

    if (isGameAction) {
        handleUpdateOverlayDescriptions([], 'neutral', roomName);
    } else {
        handleUpdateOverlayDescriptions(
            i18n.t(`${gameStatus}.tv`, {
                ns: 'overlayContent',
                returnObjects: true,
            }),
            'neutral',
            roomName
        );
    }
};
