import type React from 'react';
import { PatternHorizontalSmall } from 'src/assets/images/tablets';
import { PatternLeft, PatternRight } from 'src/assets/images/tv';

import styles from 'src/features/devices/tablets/components/banner/banner.module.scss';

interface BannerProps {
    children: React.ReactNode;
}
const Banner: React.FC<BannerProps> = ({ children }) => {
    return (
        <div className={styles.container}>
            <img
                src={PatternHorizontalSmall}
                alt="Patterne horizontal"
                className={styles.patternTop}
            />
            <div className={styles.innerContainer}>
                <img src={PatternRight} alt="" className={styles.patternRight} />
                {children}
                <img src={PatternLeft} alt="" className={styles.patternLeft} />
            </div>
            <img
                src={PatternHorizontalSmall}
                alt="Patterne horizontal"
                className={styles.patternBottom}
            />
        </div>
    );
};

export default Banner;
