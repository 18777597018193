import styles from 'src/features/devices/tablets/components/overlay/tabletOverlay.module.scss';
import { motion } from 'framer-motion';
import Button from 'src/features/reusablecomponents/buttons/Button';

interface TabletOverlayProps {
    title?: string;
    description?: string;
    onClose: () => void;
}

const TabletOverlay: React.FC<TabletOverlayProps> = ({
    title,
    description,
    onClose,
}) => {
    const containerVariants = {
        hidden: { opacity: 0, y: 600 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.3,
            },
        },
    };

    return (
        <motion.div
            className={styles.tabletOverlay}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
        >
            <div className={styles.overlayContent}>
                <h1>{title}</h1>
                <p>{description}</p>
            </div>
            <Button text="Retour" onClick={onClose} backgroundColor="error" />
        </motion.div>
    );
};

export default TabletOverlay;
