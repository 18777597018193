import { useAppSelector } from 'src/services/hooks';
import FooterTv from 'src/features/devices/tv/components/HUD/FooterTv';
import HeaderTvRecap from 'src/features/devices/tv/components/HUD/HeaderTvRecap';
import HeaderTv from 'src/features/devices/tv/components/HUD/HeaderTv';
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import { GameStatus } from 'src/utils/global/types';

interface LayoutProps {
    children: React.ReactNode;
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
    const isGame5 =
        useAppSelector(selectCurrentGameStatus) === GameStatus.GAME5ACTION;
    return (
        <>
            <HeaderTv />
            {!isGame5 && <HeaderTvRecap />}
            {children}
            {!isGame5 && <FooterTv />}
        </>
    );
};

export default Layout;
