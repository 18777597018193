import { motion } from 'framer-motion';
import { useAppSelector } from 'src/services/hooks';

// reducers
import { selectIsAudioPlaying } from 'src/services/reducers/gameSlice';

// utils
import { playSound } from 'src/utils/global/globalHandleFunctions';
import { isDevelopperMode } from 'src/utils/global/types';

// assets & styles
import styles from 'src/features/reusablecomponents/buttons/button.module.scss';

interface Button {
    text: string;
    backgroundColor?: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    icon?: string;
    additionalStyle?: React.CSSProperties;
    disabled?: boolean;
    soundPath?: string;
}

const Button: React.FC<Button> = ({
    text,
    backgroundColor,
    onClick,
    icon,
    additionalStyle,
    disabled,
    soundPath,
}) => {
    const isAudioPlaying = useAppSelector(selectIsAudioPlaying);

    return (
        <motion.button
            className={`${styles.btn} ${styles[`${backgroundColor}-btn`]}`}
            style={{ ...additionalStyle }}
            type="button"
            onClick={onClick}
            disabled={isDevelopperMode ? false : isAudioPlaying || disabled}
            whileTap={{
                scale: 0.9,
            }}
            onMouseDown={() => soundPath && playSound(soundPath)}
        >
            {icon && <img src={icon} alt={icon} />} {text}
        </motion.button>
    );
};

export default Button;
