import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { type RootState } from 'src/services/store';

export interface RoomState {
    name: string;
}

export const roomSlice = createSlice({
    name: 'room',
    initialState: {
        name: 'room',
    } as RoomState,
    reducers: {
        changeRoom: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
    },
});

export const { changeRoom } = roomSlice.actions;

export const selectRoomName = (state: RootState): string => state.room.name;

export default roomSlice.reducer;
