import { motion } from 'framer-motion';
import { Boop } from 'src/assets/sounds/soundsEffect';
import styles from 'src/features/devices/tablets/components/itemBox/itemBoxTablet.module.scss';
import { useAppSelector } from 'src/services/hooks';
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import { playSound } from 'src/utils/global/globalHandleFunctions';
import {
    type GameStatusActionWithoutGame5,
    type Item,
} from 'src/utils/global/types';
import { useItemsFound } from 'src/utils/hooks/useItemFounds';

interface ItemBoxTabletProps {
    item: Item;
    handleClick: () => void;
    showName?: boolean;
    isSymbol?: boolean;
    isSelected?: boolean;
    itemsFound?: Item[];
}

const ItemBoxTablet: React.FC<ItemBoxTabletProps> = ({
    item,
    handleClick,
    showName,
    isSymbol,
    isSelected,
}) => {
    const currentGameStatus = useAppSelector(selectCurrentGameStatus);
    const itemsFound = useItemsFound(
        currentGameStatus as GameStatusActionWithoutGame5
    );

    const isItemFound = itemsFound?.find((itemFound) => itemFound?.id === item?.id);

    return (
        <motion.button
            whileTap={{ scale: 0.8 }}
            className={`${styles.itemBox} ${
                isSelected && !isSymbol && styles[`${item.slug}-selected`]
            } ${isSelected && isSymbol && styles['symbol-selected']}
                    ${isSymbol && styles.symbol}`}
            onClick={() => {
                item && handleClick();
                playSound(Boop);
            }}
            disabled={!showName && isItemFound !== undefined}
        >
            <img src={item?.image ?? ''} alt="" />
            {showName && item !== null && 'title' in item && <p>{item?.title}</p>}
        </motion.button>
    );
};

export default ItemBoxTablet;
