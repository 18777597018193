import { motion } from 'framer-motion';
import styles from 'src/features/devices/tablets/mediator/mediatorAction.module.scss';

export type MediatorActionProps = {
    handleFunction: () => void;
    title: string;
    description?: string;
    displayingCondition?: boolean;
    type?: 'secondary' | 'tertiary';
};

const MediatorAction: React.FC<MediatorActionProps> = ({
    handleFunction,
    title,
    description,
    displayingCondition,
    type,
}) => {
    if (displayingCondition) {
        return (
            <div className={styles.mediatorAction}>
                <div>
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
                <motion.button
                    whileTap={{ scale: 0.85 }}
                    onClick={handleFunction}
                    className={type && styles[type]}
                >
                    <p> Exécuter </p>
                </motion.button>
            </div>
        );
    }
};

export default MediatorAction;
