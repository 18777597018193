import styles from 'src/features/devices/tv/components/games/game3/shape/shape.module.scss';
import { motion } from 'framer-motion';
import { type BlockShape } from 'src/utils/global/types';

interface ShapeProps {
    shape: BlockShape;
    blockIndex: number;
    block: string;
    onClick?: () => void;
    character: string;
    isUpcoming: boolean;
}

const Shape: React.FC<ShapeProps> = ({
    shape,
    blockIndex,
    block,
    onClick,
    character,
    isUpcoming,
}) => {
    return (
        <motion.button
            className={
                isUpcoming ? styles.shapeContainerUpcoming : styles.shapeContainer
            }
            onClick={onClick}
            whileTap={{
                scale: 0.9,
            }}
        >
            {shape.map((row, rowIndex) => {
                return (
                    <span key={rowIndex} className={styles.row}>
                        {row.map((isSet, cellIndex) => {
                            const cellClass = isSet ? block : 'hidden';
                            return (
                                <div
                                    key={`${blockIndex}-${rowIndex}-${cellIndex}`}
                                    className={`${styles.cell} ${
                                        styles[`${character}`]
                                    } ${styles[`${cellClass}`]}`}
                                />
                            );
                        })}
                    </span>
                );
            })}
        </motion.button>
    );
};

export default Shape;
