import { useEffect, useState } from 'react';

// selectors
import { useAppSelector } from 'src/services/hooks';
import { updateLocalDevice } from 'src/services/reducers/deviceSlice';
import { changeRoom, selectRoomName } from 'src/services/reducers/roomSlice';

// styles
import styles from 'src/features/reusablecomponents/global/waitingPlayers/createRoom.module.scss';
import { useTranslation } from 'react-i18next';

interface CreateRoomProps {
    onRoomIdChosen: (roomName: string) => void;
    type: string;
}

const CreateRoom: React.FC<CreateRoomProps> = ({ onRoomIdChosen, type }) => {
    const [text, setText] = useState('');
    const roomName = useAppSelector(selectRoomName);
    const { t } = useTranslation('global');

    useEffect(() => {
        updateLocalDevice(type);
    }, [roomName, type]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    const handleButtonClick = () => {
        changeRoom(text);
        onRoomIdChosen(text);
    };

    return (
        <div className={styles.container}>
            <div>
                <input
                    type="text"
                    value={text}
                    placeholder="Code PIN du jeu"
                    onChange={handleInputChange}
                />
                <button onClick={handleButtonClick}>{t('buttons.validate')}</button>
            </div>
        </div>
    );
};

export default CreateRoom;
