import type React from 'react';
import { useEffect } from 'react';
import { useAppSelector } from 'src/services/hooks';
import { useTranslation } from 'react-i18next';

// scss
import globalStyle from 'src/styles/app.module.scss';

//services
import { selectDeviceStatus } from 'src/services/reducers/deviceSlice';
import {
    selectCurrentGameStatus,
    selectGameResult,
} from 'src/services/reducers/gameSlice';
import { selectRoomName } from 'src/services/reducers/roomSlice';

// utils
import { handleStartGame } from 'src/utils/global/globalHandleFunctions';

// components
import ActionButton, {
    type ActionType,
} from 'src/features/devices/tablets/components/actionButton/ActionButton';
import ChooseStimuli from 'src/features/devices/tablets/components/game2Action/ChooseStimuli';
import TabletScreen from 'src/features/devices/tablets/components/screens/TabletScreen';
import ChooseGlobalFormTetris from 'src/features/devices/tablets/red/games/game3/ChooseGlobalFormTetris';
import SplashScreen from 'src/features/reusablecomponents/global/splashScreen/SplashScreen';

import { selectItemsSelectedGame1Action2 } from 'src/services/reducers/games/game1Action2Slice';
import { selectStatusTetris } from 'src/services/reducers/games/game3Slice';
import {
    selectCurrentOverlayDescriptions,
    selectCurrentOverlayType,
} from 'src/services/reducers/overlaySlice';

import { Device, GameStatus } from 'src/utils/global/types';
import ChooseItem from 'src/features/devices/tablets/components/itemBox/ChooseItem';
import NextButtonScreen from 'src/features/devices/tablets/components/screens/NextButtonScreen';
import Vote from 'src/features/devices/tablets/components/game5/Vote';

const RedActionSwitch: React.FC = () => {
    const roomName = useAppSelector(selectRoomName);
    const currentGameStatus = useAppSelector(selectCurrentGameStatus);
    const { red } = useAppSelector(selectDeviceStatus);
    const overlayType = useAppSelector(selectCurrentOverlayType);
    const currentOverlayDescriptions = useAppSelector(
        selectCurrentOverlayDescriptions
    );
    const itemsSelectedGame1Action2 = useAppSelector(
        selectItemsSelectedGame1Action2
    );
    const gameResult = useAppSelector(selectGameResult);
    const statusTetris = useAppSelector(selectStatusTetris);
    const { t } = useTranslation('global');

    const isTabletInactive = !red.isActivated;
    const showCurrentOverlay = currentOverlayDescriptions?.length > 0;
    const deviceColor = Device.red;

    useEffect(() => {
        document.title = `🔴 ${red.label}`;
    }, [red.label]);
    const handleRender = () => {
        switch (currentGameStatus) {
            case GameStatus.WAITING:
                return (
                    <SplashScreen
                        onConnect={() => handleStartGame(deviceColor, roomName)}
                    />
                );
            case GameStatus.GAME1ACTION2INTRODUCTION:
            case GameStatus.GAME5INTRODUCTION:
                return (
                    <NextButtonScreen
                        deviceColor={deviceColor}
                        action="goToNextIntroductionOverlay"
                    />
                );
            case GameStatus.GAME1ACTION1:
                return <ChooseItem />;
            case GameStatus.GAME1ACTION2: {
                return (
                    <TabletScreen
                        typeBackground="circle"
                        step="action"
                        deviceColor={deviceColor}
                    >
                        {showCurrentOverlay ? (
                            <ActionButton
                                title={
                                    overlayType === 'success'
                                        ? t('buttons.next')
                                        : t('buttons.retry')
                                }
                                action={`${overlayType}Validation` as ActionType}
                                type={overlayType}
                            />
                        ) : (
                            itemsSelectedGame1Action2?.emotion &&
                            itemsSelectedGame1Action2?.weakEmotion &&
                            itemsSelectedGame1Action2?.strongEmotion &&
                            gameResult === '' && (
                                <ActionButton
                                    title={t('buttons.validate')}
                                    action="handleValidationGame1Action2"
                                    type="neutral"
                                />
                            )
                        )}
                    </TabletScreen>
                );
            }
            case GameStatus.GAME1ACTION2PREVENTION:
            case GameStatus.GAME2PREVENTION:
            case GameStatus.GAME3PREVENTION:
            case GameStatus.GAME4PREVENTION:
            case GameStatus.GAME5PREVENTION:
                return (
                    <NextButtonScreen
                        deviceColor={deviceColor}
                        action="goToNextPreventOverlay"
                    />
                );

            case GameStatus.GAME1UNLOCK:
            case GameStatus.GAME2UNLOCK:
            case GameStatus.GAME3UNLOCK:
            case GameStatus.GAME4UNLOCK:
            case GameStatus.GAME5UNLOCK:
                return (
                    <NextButtonScreen
                        deviceColor={deviceColor}
                        action="goToNextGameStatus"
                    />
                );

            case GameStatus.GAME2ACTION: {
                return (
                    <>
                        {showCurrentOverlay ? (
                            <TabletScreen
                                typeBackground="circle"
                                step="action"
                                deviceColor={deviceColor}
                            >
                                <ActionButton
                                    title={
                                        overlayType === 'success'
                                            ? t('buttons.next')
                                            : t('buttons.retry')
                                    }
                                    action={
                                        `${overlayType}Validation` as ActionType
                                    }
                                    type={overlayType}
                                />
                            </TabletScreen>
                        ) : (
                            <ChooseStimuli type="family" />
                        )}
                    </>
                );
            }

            case GameStatus.GAME3ACTION:
                if (statusTetris === 'start') {
                    // disable speed until bug is fixed
                    // return (
                    //     <TabletScreen
                    //         header={{
                    //             title: 'Commande',
                    //             text: `Déplacer rapidement vers le bas`,
                    //         }}
                    //         typeBackground="circle"
                    //     >
                    //         <RemoteTetris type="down" />
                    //     </TabletScreen>
                    // );
                    return (
                        <TabletScreen
                            typeBackground="circle"
                            header={{
                                title: t('headers.inactive.title'),
                                text: t('headers.inactive.text'),
                            }}
                        />
                    );
                }
                return <ChooseGlobalFormTetris />;

            case GameStatus.GAME5ACTION:
                return (
                    <TabletScreen
                        step="action"
                        deviceColor={deviceColor}
                        typeBackground="empty"
                    >
                        <Vote deviceColor={deviceColor} />
                    </TabletScreen>
                );
            case GameStatus.FINISHED:
                break;
        }
    };

    return (
        <div className={globalStyle.containerTablet}>
            {isTabletInactive ? (
                <TabletScreen
                    header={{
                        title: t('headers.inactive.title'),
                        text: t('headers.inactive.text'),
                    }}
                    typeBackground="circle"
                />
            ) : (
                <>{handleRender()}</>
            )}
        </div>
    );
};

export default RedActionSwitch;
