import { useTranslation } from 'react-i18next';
import ActionButton, {
    type ActionType,
} from 'src/features/devices/tablets/components/actionButton/ActionButton';
import TabletScreen from 'src/features/devices/tablets/components/screens/TabletScreen';

import { useAppSelector } from 'src/services/hooks';
import {
    selectCurrentGameStatus,
    selectGameResult,
} from 'src/services/reducers/gameSlice';
import { selectItemsSelectedGame1Action1 } from 'src/services/reducers/games/game1Action1Slice';
import {
    selectCurrentOverlayDescriptions,
    selectCurrentOverlayType,
} from 'src/services/reducers/overlaySlice';
import { Device } from 'src/utils/global/types';

const OrangeGame1Action1: React.FC = () => {
    const currentOverlayDescriptions = useAppSelector(
        selectCurrentOverlayDescriptions
    );
    const currentGameStatus = useAppSelector(selectCurrentGameStatus);
    const deviceColor = Device.orange;
    const overlayType = useAppSelector(selectCurrentOverlayType);
    const showCurrentOverlay = currentOverlayDescriptions.length > 0;
    const { t } = useTranslation(['global', 'actionContent']);

    const itemsSelected = useAppSelector(selectItemsSelectedGame1Action1);

    const gameResult = useAppSelector(selectGameResult);

    return (
        <>
            {showCurrentOverlay ? (
                <TabletScreen
                    typeBackground="circle"
                    step="action"
                    deviceColor={deviceColor}
                >
                    <ActionButton
                        title={
                            overlayType === 'success'
                                ? t('buttons.next')
                                : t('buttons.retry')
                        }
                        action={`${overlayType}Validation` as ActionType}
                        type={overlayType}
                    />
                </TabletScreen>
            ) : (
                <TabletScreen
                    typeBackground="circle"
                    step="action"
                    deviceColor={deviceColor}
                >
                    {gameResult === '' && (
                        <>
                            {itemsSelected.symbol && itemsSelected.emotion ? (
                                <ActionButton
                                    title={t('buttons.validate')}
                                    action="handleValidationGame1Action1"
                                    type="neutral"
                                />
                            ) : (
                                <p>
                                    {t(
                                        `${currentGameStatus}.${deviceColor}.bodyContent.empty`,
                                        { ns: 'actionContent' }
                                    )}
                                </p>
                            )}
                        </>
                    )}
                </TabletScreen>
            )}
        </>
    );
};

export default OrangeGame1Action1;
