import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { type RootState } from 'src/services/store';
import { GameStatus } from 'src/utils/global/types';

export interface GameState {
    roomName: string;
    gameStatus: GameStatus;
    isAudioPlaying: boolean;
    gameResult: 'success' | 'error' | '';
}

export interface UpdateTutorialStepPayload {
    gameStatus: GameStatus;
}

const initialState: GameState = {
    roomName: '',
    gameStatus: GameStatus.WAITING,
    isAudioPlaying: false,
    gameResult: '',
};

export const gameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        updateGameStatus: (state, action: PayloadAction<GameStatus>) => {
            state.gameStatus = action.payload;
        },
        updateIsAudioPlaying: (state, action: PayloadAction<boolean>) => {
            state.isAudioPlaying = action.payload;
        },
        updateGameResult: (
            state,
            action: PayloadAction<'success' | 'error' | ''>
        ) => {
            state.gameResult = action.payload;
        },
    },
});

export const { updateGameStatus, updateIsAudioPlaying, updateGameResult } =
    gameSlice.actions;

export const selectCurrentGameStatus = (state: RootState): GameStatus =>
    state.game.gameStatus;

export const selectIsAudioPlaying = (state: RootState): boolean =>
    state.game.isAudioPlaying;

export const selectGameResult = (state: RootState): 'success' | 'error' | '' =>
    state.game.gameResult;

export default gameSlice.reducer;
