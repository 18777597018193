import { motion } from 'framer-motion';

interface TextAnimationProps {
    text: string;
}

const TextAnimation: React.FC<TextAnimationProps> = ({ text }) => {
    return (
        <p key={text}>
            {typeof text === 'string' &&
                text.split(' ').map((word, i) => (
                    <motion.span
                        key={word + i}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            duration: 0.5,
                            delay: i / 5,
                        }}
                    >
                        {word}{' '}
                    </motion.span>
                ))}
        </p>
    );
};

export default TextAnimation;
