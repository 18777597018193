import i18n from 'src/i18n';
import dataGlobal from 'src/services/datas/global.json';
import datas from 'src/services/datas/mediator/actionsGames.json';
import {
    handleItemSelected,
    handleSendCarouselValues,
} from 'src/utils/device/deviceHandleFunctions';
import { getSocket } from 'src/utils/global/globalHandleFunctions';
import {
    type Behavior,
    type Emotion,
    type GameStatus,
    type ItemsSelected,
    type ResponseSituation,
    type Situation,
    type _Symbol,
} from 'src/utils/global/types';

const actionsGames: ActionsGamesMapping = datas;
export interface ActionsGamesMapping {
    [key: string]: ActionsGamesContent;
}

interface MediatorButtonAction {
    [key: string]: {
        btnTitle: string;
        description: string;
        nameFunctions: string[];
    };
}

interface ActionsGamesContent {
    title: string;
    itemSelectedType?: string[];
    actions?: MediatorButtonAction;
    preventStatus?: GameStatus | string;
    unlockCodeStatus?: GameStatus | string;
}

export const handleTextCurrentGameStatus = (gameStatus: GameStatus): string => {
    return actionsGames[gameStatus].title;
};

// In the JSON file actionsGames, we retrieve the itemsSelectedType array of the current game and use it for mapping.
// Examples: In game 1, we need to select an emotion and a symbol; in game 4, a behavior and a code.
// For each itemsSelectedType, we will fetch the correct response and store it in array itemsSelected of redux store.
export const handleGoodCombination = (
    selection: Emotion | Behavior | Situation,
    gameStatus: GameStatus,
    roomName: string
): void => {
    const itemSelectedTypes = actionsGames[gameStatus].itemSelectedType;
    if (itemSelectedTypes) {
        itemSelectedTypes.forEach((type) => {
            handleItemSelected(
                getCorrectItem(selection?.id, type) as ItemsSelected,
                type,
                gameStatus,
                roomName
            );
        });
    }
};

export const getCorrectItem = (
    selectionId: number,
    type?: string
):
    | ItemsSelected
    | _Symbol
    | Emotion
    | Behavior
    | undefined
    | number
    | ResponseSituation => {
    const goodCombinationsGame1Action1 = dataGlobal.GAME1ACTION1.goodCombinations;
    const goodCombinationsGame1Action2 = dataGlobal.GAME1ACTION2.goodCombinations;
    const goodCombinationsGame2 = dataGlobal.GAME2ACTION.goodCombinations;
    const goodCombinationsGame3 = dataGlobal.GAME3ACTION.goodCombinations;
    const goodCombinationsGame4 = dataGlobal.GAME4ACTION.goodCombinations;
    const goodCombinationsGame5 = dataGlobal.GAME5ACTION.goodCombinations;
    const symbolId = goodCombinationsGame1Action1.find(
        (element) => element.emotionId === selectionId
    )?.symbolId;

    const strongEmotionId = goodCombinationsGame1Action2.find(
        (element) => element.emotionId === selectionId
    )?.strongEmotionId;

    const weakEmotionId = goodCombinationsGame1Action2.find(
        (element) => element.emotionId === selectionId
    )?.weakEmotionId;

    const workId = goodCombinationsGame2.find(
        (element) => element.emotionId === selectionId
    )?.workId;

    const familyId = goodCombinationsGame2.find(
        (element) => element.emotionId === selectionId
    )?.familyId;

    const friendsId = goodCombinationsGame2.find(
        (element) => element.emotionId === selectionId
    )?.friendsId;

    const sportId = goodCombinationsGame2.find(
        (element) => element.emotionId === selectionId
    )?.sportId;

    const tetrisPaul = goodCombinationsGame3.find(
        (element) => element.emotionId === selectionId
    )?.paul;

    const tetriseric = goodCombinationsGame3.find(
        (element) => element.emotionId === selectionId
    )?.eric;

    const tetrisFanny = goodCombinationsGame3.find(
        (element) => element.emotionId === selectionId
    )?.fanny;

    const code = goodCombinationsGame4.find(
        (element) => element.behaviorId === selectionId
    )?.code;

    const idResponse = goodCombinationsGame5.find(
        (element) => element.idSituation === selectionId
    )?.idResponse;

    const selectedSituation = dataGlobal.GAME5ACTION.situations.find(
        (situation) => situation.id === selectionId
    );

    const behaviors = i18n.t('GAME4ACTION.behaviors', {
        returnObjects: true,
    }) as Behavior[];

    switch (type) {
        case 'emotion':
            return dataGlobal.emotions.find(
                (emotion) => emotion.id === selectionId
            ) as ItemsSelected;
        case 'symbol':
            return dataGlobal.GAME1ACTION1.symbol.find(
                (symbol) => symbol.id === symbolId
            ) as ItemsSelected;
        case 'strongEmotion':
            return dataGlobal.GAME1ACTION2.strongEmotion.find(
                (strongEmotion) => strongEmotion.id === strongEmotionId
            ) as ItemsSelected;
        case 'weakEmotion':
            return dataGlobal.GAME1ACTION2.weakEmotion.find(
                (weakEmotion) => weakEmotion.id === weakEmotionId
            ) as ItemsSelected;
        case 'family':
            return dataGlobal.GAME2ACTION.environments.family.stimuli.find(
                (stimulus) => stimulus.id === familyId
            ) as ItemsSelected;
        case 'sport':
            return dataGlobal.GAME2ACTION.environments.sport.stimuli.find(
                (stimulus) => stimulus.id === sportId
            ) as ItemsSelected;
        case 'work':
            return dataGlobal.GAME2ACTION.environments.work.stimuli.find(
                (stimulus) => stimulus.id === workId
            ) as ItemsSelected;
        case 'friends':
            return dataGlobal.GAME2ACTION.environments.friends.stimuli.find(
                (stimulus) => stimulus.id === friendsId
            ) as ItemsSelected;
        case 'tetrispaul':
            return tetrisPaul as ItemsSelected;
        case 'tetriseric':
            return tetriseric as ItemsSelected;
        case 'tetrisfanny':
            return tetrisFanny as ItemsSelected;

        case 'behavior':
            return behaviors.find(
                (behavior) => behavior.id === selectionId
            ) as Behavior;
        case 'code':
            return code as ItemsSelected;
        case 'green':
        case 'red':
        case 'orange':
        case 'blue':
            return selectedSituation?.responses.find(
                (response) => response.id === idResponse
            ) as ItemsSelected;

        default:
            return undefined;
    }
};

export const handleResetItemsFound = (
    gameStatus: GameStatus,
    roomName: string
): void => {
    const socket = getSocket();

    const responsesSocket = {
        roomName,
        gameStatus,
    };
    socket?.emit('send_reset_items_found', responsesSocket);
};

export const handleCarouselValuesFromChoiceEmotion = (
    selection: Behavior | undefined,
    roomName: string
): void => {
    if (selection?.slug === 'joy') {
        handleSendCarouselValues('flowDirection', 0, roomName);
        handleSendCarouselValues('centerIndex', 3, roomName);
        handleSendCarouselValues('leftIndex', 2, roomName);
        handleSendCarouselValues('rightIndex', 0, roomName);
    }
    if (selection?.slug === 'angry') {
        handleSendCarouselValues('flowDirection', 0, roomName);
        handleSendCarouselValues('centerIndex', 0, roomName);
        handleSendCarouselValues('leftIndex', 3, roomName);
        handleSendCarouselValues('rightIndex', 1, roomName);
    }
    if (selection?.slug === 'fear') {
        handleSendCarouselValues('flowDirection', 0, roomName);
        handleSendCarouselValues('centerIndex', 2, roomName);
        handleSendCarouselValues('leftIndex', 1, roomName);
        handleSendCarouselValues('rightIndex', 3, roomName);
    }
    if (selection?.slug === 'sadness') {
        handleSendCarouselValues('flowDirection', 0, roomName);
        handleSendCarouselValues('centerIndex', 1, roomName);
        handleSendCarouselValues('leftIndex', 0, roomName);
        handleSendCarouselValues('rightIndex', 2, roomName);
    }
};
