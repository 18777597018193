import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TabletBackground, TvImage } from 'src/assets/images/global';
import { type RootState } from 'src/services/store';

export interface DeviceStatus {
    isConnected: boolean;
    isActivated: boolean;
    isReconnected: boolean;
    label: string;
    image?: string;
    indexMessage: number;
}

export interface DeviceState {
    deviceStatus: {
        [key: string]: DeviceStatus;
    };
    localDevice: string;
}

const initialState: DeviceState = {
    deviceStatus: {
        red: {
            isConnected: false,
            isActivated: false,
            isReconnected: false,
            label: 'Tablette rouge',
            image: TabletBackground,
            indexMessage: 0,
        },
        blue: {
            isConnected: false,
            isActivated: false,
            isReconnected: false,
            label: 'Tablette bleue',
            image: TabletBackground,
            indexMessage: 0,
        },
        green: {
            isConnected: false,
            isActivated: false,
            isReconnected: false,
            label: 'Tablette verte',
            image: TabletBackground,
            indexMessage: 0,
        },
        orange: {
            isConnected: false,
            isActivated: true,
            isReconnected: false,
            label: 'Tablette orange',
            image: TabletBackground,
            indexMessage: 0,
        },
        tv: {
            isConnected: true,
            isActivated: true,
            isReconnected: false,
            label: 'TV',
            image: TvImage,
            indexMessage: 0,
        },
        mediator: {
            isConnected: false,
            isActivated: true,
            isReconnected: false,
            label: 'Tablette Médiateur',
            indexMessage: 0,
        },
    },
    localDevice: '',
};

interface DeviceStatusPayload {
    deviceColor: string;
    status: boolean;
}

export const deviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers: {
        updateDeviceConnectedStatus: (
            state,
            { payload: { deviceColor, status } }: PayloadAction<DeviceStatusPayload>
        ) => {
            const device = state.deviceStatus[deviceColor];
            device.isConnected = status;
            device.isReconnected = false;
        },
        updateDeviceActivatedStatus: (
            state,
            { payload: { deviceColor, status } }: PayloadAction<DeviceStatusPayload>
        ) => {
            state.deviceStatus[deviceColor].isActivated = status;
        },
        updateLocalDevice: (state, action: PayloadAction<string>) => {
            state.localDevice = action.payload;
        },
    },
});

export const selectDeviceStatus = (
    state: RootState
): { [key: string]: DeviceStatus } => state.device.deviceStatus;

export const {
    updateDeviceConnectedStatus,
    updateDeviceActivatedStatus,
    updateLocalDevice,
} = deviceSlice.actions;

export default deviceSlice.reducer;
