import styles from 'src/features/devices/tv/components/itemBox/itemBox.module.scss';
import { motion } from 'framer-motion';
import { imageVariants } from 'src/utils/global/types';

interface ItemBoxProps {
    image: string;
    text: string;
    gameResult?: 'success' | 'error' | '';
    additionalStyle?: React.CSSProperties;
}

const ItemBox: React.FC<ItemBoxProps> = ({
    image,
    text,
    additionalStyle,
    gameResult,
}) => {
    const imageName = image.split('/').pop()?.split('.')[0];

    return (
        <div className={styles.itemBoxContainer} style={{ ...additionalStyle }}>
            <div className={styles[imageName ?? '']}>
                <motion.img
                    src={image}
                    variants={imageVariants}
                    initial="initial"
                    animate="animate"
                />
            </div>
            <p className={gameResult ? styles[gameResult] : undefined}>{text}</p>
        </div>
    );
};

export default ItemBox;
