import { useAppSelector } from 'src/services/hooks';

// scss
import globalStyle from 'src/styles/app.module.scss';

// components
import ItemBoxTablet from 'src/features/devices/tablets/components/itemBox/ItemBoxTablet';
import TabletScreen from 'src/features/devices/tablets/components/screens/TabletScreen';

// datas
import dataGlobal from 'src/services/datas/global.json';

// utils
import {
    handleItemSelected,
    handleTabletStatus,
} from 'src/utils/device/deviceHandleFunctions';
import {
    Device,
    GameStatus,
    type Emotion,
    type GameStatusActionWithoutGame5,
    type ItemsSelected,
} from 'src/utils/global/types';

// services
import { useTranslation } from 'react-i18next';
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import { selectRoomName } from 'src/services/reducers/roomSlice';
import { useItemsSelected } from 'src/utils/hooks/useItemSelected';

interface ChooseItemProps {
    isSymbol?: boolean;
}

const ChooseItem: React.FC<ChooseItemProps> = ({ isSymbol = false }) => {
    const roomName = useAppSelector(selectRoomName);
    const gameStatus = useAppSelector(selectCurrentGameStatus);
    const deviceColor = Device.red;
    const { t } = useTranslation('global');

    const itemsSelected = useItemsSelected(
        gameStatus as GameStatusActionWithoutGame5
    );

    const handleClickBox = (item: ItemsSelected) => {
        handleItemSelected(
            item,
            isSymbol ? 'symbol' : 'emotion',
            gameStatus,
            roomName
        );
        if (gameStatus === GameStatus.GAME1ACTION2) {
            handleTabletStatus(
                {
                    green: true,
                    orange: true,
                    red: true,
                    blue: true,
                },
                roomName
            );
        }
    };

    const items =
        gameStatus === GameStatus.GAME1ACTION1 && isSymbol
            ? dataGlobal.GAME1ACTION1.symbol
            : (t('emotions', { returnObjects: true }) as Emotion[]);

    return (
        <div className={globalStyle.itemBoxContainer}>
            <TabletScreen
                step="action"
                deviceColor={deviceColor}
                typeBackground="banner"
            >
                <div className={globalStyle.itemBoxContainer}>
                    {items.map((item) => (
                        <ItemBoxTablet
                            key={item.id}
                            item={item}
                            handleClick={() =>
                                handleClickBox(item as ItemsSelected)
                            }
                            isSelected={
                                isSymbol
                                    ? item.id === itemsSelected?.symbol?.id
                                    : item.id === itemsSelected.emotion?.id
                            }
                            isSymbol={isSymbol}
                        />
                    ))}
                </div>
            </TabletScreen>
        </div>
    );
};

export default ChooseItem;
