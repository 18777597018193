import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RobotAnswer, ScientistAnswer } from 'src/assets/images/global';
import { TimerTicking } from 'src/assets/sounds/soundsEffect';
import styles from 'src/features/devices/tv/components/conversation/conversation.module.scss';
import AudioComponent from 'src/features/reusablecomponents/global/AudioComponent';
import TextAnimation from 'src/features/reusablecomponents/text/TextAnimation';
import { useAppSelector } from 'src/services/hooks';
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import { type Disagrement } from 'src/utils/global/types';

interface ConversationPropsInterface {
    type: 'scientist' | 'robot';
    currentSituation: {
        id: number;
        scientist: string;
        robot: string;
        audioRobot: string;
        audioScientist: string;
        time: number;
        responses: {
            id: number;
            text: string;
        }[];
    };
    showTimer?: boolean;
}

const Conversation: React.FC<ConversationPropsInterface> = ({
    type,
    currentSituation,
    showTimer,
}) => {
    const audioPath =
        type === 'scientist'
            ? currentSituation.audioScientist
            : currentSituation.audioRobot;

    const currentGameStatus = useAppSelector(selectCurrentGameStatus);

    const { t } = useTranslation('global');
    const { text, audio } = t(`${currentGameStatus}.disagrement`, {
        returnObjects: true,
    }) as Disagrement;
    const [isShowDisagrement, setIsShowDisagrement] = useState(false);
    const [
        hasTextSituationBeenDisplayedOnce,
        setHasTextSituationBeenDisplayedOnce,
    ] = useState(false);

    useEffect(() => {
        if (showTimer) {
            setIsShowDisagrement(true);
            setHasTextSituationBeenDisplayedOnce(true);
        }
        setTimeout(() => setIsShowDisagrement(false), 6000);
    }, [showTimer]);

    return (
        <div className={styles.conversationContainer}>
            {!hasTextSituationBeenDisplayedOnce && !isShowDisagrement && (
                <AudioComponent src={audioPath} />
            )}
            {isShowDisagrement && <AudioComponent src={audio} />}
            {showTimer && <AudioComponent src={TimerTicking} />}

            <img
                src={type === 'scientist' ? ScientistAnswer : RobotAnswer}
                alt="character"
            />
            <TextAnimation
                text={isShowDisagrement ? text : currentSituation[type]}
            />
        </div>
    );
};

export default Conversation;
