import { useEffect } from 'react';
import { useAppSelector } from 'src/services/hooks';

// scss
import globalStyle from 'src/styles/app.module.scss';

// services
import { selectDeviceStatus } from 'src/services/reducers/deviceSlice';
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import { selectRoomName } from 'src/services/reducers/roomSlice';

// utils
import { handleStartGame } from 'src/utils/global/globalHandleFunctions';

// components
import ListEmotionToFound from 'src/features/devices/tablets/blue/games/game1Action1/ListEmotionToFound';
import ChooseCode from 'src/features/devices/tablets/blue/games/game4/ChooseCode';
import ChooseStimuli from 'src/features/devices/tablets/components/game2Action/ChooseStimuli';
import ChooseTetris from 'src/features/devices/tablets/components/game3/ChooseTetris';
import TabletScreen from 'src/features/devices/tablets/components/screens/TabletScreen';
import SplashScreen from 'src/features/reusablecomponents/global/splashScreen/SplashScreen';
import RemoteTetris from 'src/features/devices/tablets/components/game3/RemoteTetris';

// datas
import {
    selectItemsFoundGame4,
    selectItemsSelectedGame4,
} from 'src/services/reducers/games/game4Slice';

import { selectStatusTetris } from 'src/services/reducers/games/game3Slice';

import { Device, GameStatus } from 'src/utils/global/types';
import ChooseItem from 'src/features/devices/tablets/components/itemBox/ChooseItem';
import NextButtonScreen from 'src/features/devices/tablets/components/screens/NextButtonScreen';
import Vote from 'src/features/devices/tablets/components/game5/Vote';
import { useTranslation } from 'react-i18next';

const BlueActionSwitch: React.FC = () => {
    const roomName = useAppSelector(selectRoomName);
    const currentGameStatus = useAppSelector(selectCurrentGameStatus);
    const { blue } = useAppSelector(selectDeviceStatus);
    const statusTetris = useAppSelector(selectStatusTetris);
    const { t } = useTranslation(['global', 'actionContent']);

    const isTabletInactive = !blue.isActivated;
    const deviceColor = Device.blue;

    // game4 handle display of Digit Keyboard
    const itemsSelectedGame4 = useAppSelector(selectItemsSelectedGame4);
    const itemsFoundGame4 = useAppSelector(selectItemsFoundGame4);
    const isBehaviorFound = itemsFoundGame4.find(
        (item) => item?.id === itemsSelectedGame4?.behavior?.emotionId
    );

    useEffect(() => {
        document.title = `🔵 ${blue.label}`;
    }, [blue.label]);

    const handleRender = () => {
        switch (currentGameStatus) {
            case GameStatus.WAITING:
                return (
                    <SplashScreen
                        onConnect={() => handleStartGame(deviceColor, roomName)}
                    />
                );

            case GameStatus.GAME1ACTION1:
                return <ListEmotionToFound />;
            case GameStatus.GAME1ACTION2:
                return <ChooseItem />;
            case GameStatus.GAME2ACTION:
                return <ChooseStimuli type="work" />;
            case GameStatus.GAME3INTRODUCTION:
                return (
                    <NextButtonScreen
                        deviceColor={deviceColor}
                        action="goToNextIntroductionOverlay"
                    />
                );
            case GameStatus.GAME3ACTION:
                return (
                    <>
                        {statusTetris === 'start' ? (
                            <TabletScreen
                                header={{
                                    title: t(
                                        `${currentGameStatus}.remotes.right.title`
                                    ),
                                    text: t(
                                        `${currentGameStatus}.remotes.right.description`
                                    ),
                                }}
                                typeBackground="circle"
                            >
                                <RemoteTetris type="right" />
                            </TabletScreen>
                        ) : (
                            <TabletScreen
                                header={{
                                    title: t(
                                        `${currentGameStatus}.${deviceColor}.headerContent.title`,
                                        { ns: 'actionContent' }
                                    ),
                                    text: t(
                                        `${currentGameStatus}.${deviceColor}.headerContent.text`,
                                        { ns: 'actionContent' }
                                    ),
                                }}
                                typeBackground="circle"
                            >
                                <ChooseTetris character="eric" />
                            </TabletScreen>
                        )}
                    </>
                );
            case GameStatus.GAME4ACTION:
                if (isBehaviorFound) {
                    return (
                        <TabletScreen
                            header={{
                                title: t('headers.inactive.title'),
                                text: t('headers.inactive.text'),
                            }}
                            typeBackground="circle"
                        />
                    );
                } else {
                    return <ChooseCode />;
                }
            case GameStatus.GAME5ACTION:
                return (
                    <TabletScreen
                        step="action"
                        deviceColor={deviceColor}
                        typeBackground="empty"
                    >
                        <Vote deviceColor={deviceColor} />
                    </TabletScreen>
                );
            case GameStatus.FINISHED:
                break;
        }
    };

    return (
        <div className={globalStyle.containerTablet}>
            {isTabletInactive ? (
                <TabletScreen
                    header={{
                        title: t('headers.inactive.title'),
                        text: t('headers.inactive.text'),
                    }}
                    typeBackground="circle"
                />
            ) : (
                <>{handleRender()}</>
            )}
        </div>
    );
};

export default BlueActionSwitch;
