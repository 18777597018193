import { useMemo } from 'react';
import { useAppSelector } from 'src/services/hooks';
import { selectItemsFoundGame1Action1 } from 'src/services/reducers/games/game1Action1Slice';
import { selectItemsFoundGame1Action2 } from 'src/services/reducers/games/game1Action2Slice';
import { selectItemsFoundGame2 } from 'src/services/reducers/games/game2Slice';
import { selectItemsFoundGame3 } from 'src/services/reducers/games/game3Slice';
import { selectItemsFoundGame4 } from 'src/services/reducers/games/game4Slice';
import {
    GameStatus,
    type GameStatusActionWithoutGame5,
    type Item,
} from 'src/utils/global/types';

export const useItemsFound = (gameStatus: GameStatusActionWithoutGame5): Item[] => {
    const itemsFoundGame1Action1 = useAppSelector(selectItemsFoundGame1Action1);
    const itemsFoundGame1Action2 = useAppSelector(selectItemsFoundGame1Action2);
    const itemsFoundGame2 = useAppSelector(selectItemsFoundGame2);
    const itemsFoundGame3 = useAppSelector(selectItemsFoundGame3);
    const itemsFoundGame4 = useAppSelector(selectItemsFoundGame4);

    const itemsFound = useMemo(
        () => ({
            [GameStatus.GAME1ACTION1]: itemsFoundGame1Action1,
            [GameStatus.GAME1ACTION2]: itemsFoundGame1Action2,
            [GameStatus.GAME2ACTION]: itemsFoundGame2,
            [GameStatus.GAME3ACTION]: itemsFoundGame3,
            [GameStatus.GAME4ACTION]: itemsFoundGame4,
        }),
        [
            itemsFoundGame1Action1,
            itemsFoundGame1Action2,
            itemsFoundGame2,
            itemsFoundGame3,
            itemsFoundGame4,
        ]
    );

    return itemsFound[gameStatus];
};
