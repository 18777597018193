import { motion } from 'framer-motion';
import { Stars, Waiting } from 'src/assets/images/global';
import styles from 'src/features/devices/tablets/components/game1action2/strongWeakEmotion.module.scss';
import { type EmotionSecondary } from 'src/utils/global/types';

interface StrongWeakEmotionProps {
    item?: EmotionSecondary;
    isSelected?: boolean;
    isDisabled?: boolean;
    handleClick?: () => void;
    description?: string;
    gameResult?: 'success' | 'error' | '';
}

const variants = {
    // Fade in animation
    initial: {
        opacity: 0,
        scale: 0.9,
    },
    animate: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.7,
            ease: 'easeInOut',
        },
    },
};
// TODO: Rename
const StrongWeakEmotion: React.FC<StrongWeakEmotionProps> = ({
    item,
    isSelected,
    isDisabled,
    handleClick,
    description,
    gameResult,
}) => {
    return (
        <motion.button
            whileTap={{ scale: 0.9 }}
            disabled={isDisabled}
            onClick={() => item && handleClick?.()}
            className={styles.strongWeakEmotionContainer}
            variants={variants}
            initial="initial"
            animate="animate"
            key={item?.slug}
        >
            {description !== undefined && <p>{description}</p>}
            <img
                src={item?.image ?? Waiting}
                alt=""
                className={`${isSelected && styles.selected} ${
                    gameResult && styles[gameResult]
                }`}
            />
            {isSelected && <img src={Stars} alt="Stars" />}
        </motion.button>
    );
};

export default StrongWeakEmotion;
