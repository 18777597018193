import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/services/hooks';

// scss
import globalStyle from 'src/styles/app.module.scss';

// services
import { selectDeviceStatus } from 'src/services/reducers/deviceSlice';
import {
    selectCurrentGameStatus,
    selectGameResult,
} from 'src/services/reducers/gameSlice';
import {
    selectCurrentOverlayDescriptions,
    selectCurrentOverlayType,
} from 'src/services/reducers/overlaySlice';
import { selectRoomName } from 'src/services/reducers/roomSlice';

// utils
import { handleStartGame } from 'src/utils/global/globalHandleFunctions';

// components
import ActionButton, {
    type ActionType,
} from 'src/features/devices/tablets/components/actionButton/ActionButton';
import ChooseIntensityEmotion from 'src/features/devices/tablets/components/game1action2/ChooseIntensityEmotion';
import ChooseStimuli from 'src/features/devices/tablets/components/game2Action/ChooseStimuli';
import ChooseTetris from 'src/features/devices/tablets/components/game3/ChooseTetris';
import TabletScreen from 'src/features/devices/tablets/components/screens/TabletScreen';
import SplashScreen from 'src/features/reusablecomponents/global/splashScreen/SplashScreen';

// datas
import RemoteTetris from 'src/features/devices/tablets/components/game3/RemoteTetris';
import { selectIsConfirmSelectionButtonGame2 } from 'src/services/reducers/games/game2Slice';
import { selectStatusTetris } from 'src/services/reducers/games/game3Slice';
import {
    selectItemsFoundGame4,
    selectItemsSelectedGame4,
} from 'src/services/reducers/games/game4Slice';

import Vote from 'src/features/devices/tablets/components/game5/Vote';
import ChooseItem from 'src/features/devices/tablets/components/itemBox/ChooseItem';
import NextButtonScreen from 'src/features/devices/tablets/components/screens/NextButtonScreen';
import { Device, GameStatus } from 'src/utils/global/types';

const GreenActionSwitch: React.FC = () => {
    const roomName = useAppSelector(selectRoomName);
    const { t } = useTranslation('global');

    const currentOverlayDescriptions = useAppSelector(
        selectCurrentOverlayDescriptions
    );
    const overlayType = useAppSelector(selectCurrentOverlayType);
    const showCurrentOverlay = currentOverlayDescriptions.length > 0;
    const currentGameStatus = useAppSelector(selectCurrentGameStatus);
    const green = useAppSelector(selectDeviceStatus).green;
    const isConfirmSelectionButton = useAppSelector(
        selectIsConfirmSelectionButtonGame2
    );
    const statusTetris = useAppSelector(selectStatusTetris);

    const itemsSelectedGame4 = useAppSelector(selectItemsSelectedGame4);
    const itemsFoundGame4 = useAppSelector(selectItemsFoundGame4);
    const isBehaviorFound = itemsFoundGame4.find(
        (item) => item?.id === itemsSelectedGame4?.behavior?.emotionId
    );

    const isTabletInactive = !green.isActivated;

    const gameResult = useAppSelector(selectGameResult);
    const deviceColor = Device.green;

    useEffect(() => {
        document.title = `🟢 ${green.label}`;
    }, [green.label]);

    const handleRender = () => {
        switch (currentGameStatus) {
            case GameStatus.WAITING:
                return (
                    <SplashScreen
                        onConnect={() => handleStartGame(deviceColor, roomName)}
                    />
                );
            case GameStatus.GAME1ACTION1:
                return <ChooseItem isSymbol />;
            case GameStatus.GAME1ACTION2:
                return <ChooseIntensityEmotion intensity="strongEmotion" />;
            case GameStatus.GAME2INTRODUCTION:
                return (
                    <NextButtonScreen
                        deviceColor={deviceColor}
                        action="goToNextIntroductionOverlay"
                    />
                );
            case GameStatus.GAME2ACTION: {
                return isConfirmSelectionButton ? (
                    <div className={globalStyle.containerButtons}>
                        <ActionButton
                            title={t('buttons.confirm')}
                            action="handleValidationGame2Action"
                            type="neutral"
                        />
                        <ActionButton
                            title={t('buttons.modify')}
                            action="handleIsConfirmSelectionButton"
                            type="neutral"
                        />
                    </div>
                ) : (
                    <ChooseStimuli type="sport" />
                );
            }
            case GameStatus.GAME3ACTION:
                return (
                    <>
                        {statusTetris === 'start' ? (
                            <TabletScreen
                                header={{
                                    title: t(
                                        `${currentGameStatus}.remotes.left.title`
                                    ),
                                    text: t(
                                        `${currentGameStatus}.remotes.left.description`
                                    ),
                                }}
                                typeBackground="circle"
                            >
                                <RemoteTetris type="left" />
                            </TabletScreen>
                        ) : (
                            <TabletScreen
                                header={{
                                    title: t(
                                        `${currentGameStatus}.${deviceColor}.headerContent.title`,
                                        { ns: 'actionContent' }
                                    ),
                                    text: t(
                                        `${currentGameStatus}.${deviceColor}.headerContent.text`,
                                        { ns: 'actionContent' }
                                    ),
                                }}
                                typeBackground="circle"
                            >
                                <ChooseTetris character="fanny" />
                            </TabletScreen>
                        )}
                    </>
                );
            case GameStatus.GAME4ACTION:
                return (
                    <>
                        {showCurrentOverlay ? (
                            <TabletScreen
                                typeBackground="circle"
                                step="action"
                                deviceColor={deviceColor}
                            >
                                <ActionButton
                                    title={
                                        overlayType === 'success'
                                            ? t('buttons.next')
                                            : t('buttons.retry')
                                    }
                                    action={
                                        `${overlayType}Validation` as ActionType
                                    }
                                    type={overlayType}
                                />
                            </TabletScreen>
                        ) : (
                            <>
                                {isBehaviorFound ? (
                                    <TabletScreen
                                        header={{
                                            title: t('headers.inactive.title'),
                                            text: t('headers.inactive.text'),
                                        }}
                                        typeBackground="circle"
                                    />
                                ) : (
                                    <TabletScreen
                                        typeBackground="circle"
                                        step="action"
                                        deviceColor={deviceColor}
                                    >
                                        {gameResult === '' &&
                                            itemsSelectedGame4.code &&
                                            itemsSelectedGame4.code.length >= 2 && (
                                                <ActionButton
                                                    title={t('buttons.validate')}
                                                    action="handleValidationGame4"
                                                    type="neutral"
                                                />
                                            )}
                                    </TabletScreen>
                                )}
                            </>
                        )}
                    </>
                );
            case GameStatus.GAME5ACTION:
                return (
                    <TabletScreen
                        step="action"
                        deviceColor={deviceColor}
                        typeBackground="empty"
                    >
                        <Vote deviceColor={deviceColor} />
                    </TabletScreen>
                );
            case GameStatus.FINISHED:
                break;
        }
    };

    return (
        <div className={globalStyle.containerTablet}>
            {isTabletInactive ? (
                <TabletScreen
                    header={{
                        title: t('headers.inactive.title'),
                        text: t('headers.inactive.text'),
                    }}
                    typeBackground="circle"
                />
            ) : (
                <>{handleRender()}</>
            )}
        </div>
    );
};

export default GreenActionSwitch;
