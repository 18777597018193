import { useAppSelector } from 'src/services/hooks';

// reducers
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';

// utils
import { type GameStatusActionWithoutGame5 } from 'src/utils/global/types';
// assets
import { PatternHorizontalHud } from 'src/assets/images/tv';

// scss
import styles from 'src/features/devices/tv/components/HUD/headerTvRecap.module.scss';
import { useItemsFound } from 'src/utils/hooks/useItemFounds';

const HeaderTvRecap: React.FC = () => {
    const currentGameStatus = useAppSelector(selectCurrentGameStatus);

    const itemsFound = useItemsFound(
        currentGameStatus as GameStatusActionWithoutGame5
    );

    return (
        <>
            <div className={styles.headerTvRecapContainer}>
                <img
                    src={PatternHorizontalHud}
                    alt="hud horizontal bar"
                    className={styles.patternTop}
                />
                <div className={styles.listContainer}>
                    {itemsFound.map((recapElement, index) => {
                        return (
                            <div key={index} className={styles.squaresContainer}>
                                <div>
                                    {/* TODO ADD image fake */}
                                    <img
                                        src={
                                            recapElement !== null
                                                ? recapElement.image
                                                : ''
                                        }
                                        alt=""
                                    />
                                </div>
                                <p>
                                    {recapElement !== null
                                        ? recapElement.title
                                        : '?'}
                                </p>
                            </div>
                        );
                    })}
                </div>
                <img
                    src={PatternHorizontalHud}
                    alt=""
                    className={styles.patternBottom}
                />
            </div>
        </>
    );
};

export default HeaderTvRecap;
