import { useCallback, useEffect, useState } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import i18n from 'src/i18n';
import { useAppDispatch, useAppSelector } from 'src/services/hooks';
import SocketService from 'src/services/SocketService';

// components
import BlueTablet from 'src/features/devices/tablets/blue/BlueActionSwitch';
import GreenTablet from 'src/features/devices/tablets/green/GreenActionSwitch';
import MediatorTablet from 'src/features/devices/tablets/mediator/MediatorTablet';
import OrangeTablet from 'src/features/devices/tablets/orange/OrangeActionSwitch';
import RedTablet from 'src/features/devices/tablets/red/RedActionSwitch';
import Tv from 'src/features/devices/tv/Tv';
import CreateRoom from 'src/features/reusablecomponents/global/waitingPlayers/CreateRoom';

// services

import {
    updateDeviceActivatedStatus,
    updateDeviceConnectedStatus,
} from 'src/services/reducers/deviceSlice';
import {
    resetItemsFoundGame1Action1,
    resetItemsSelectedGame1Action1,
    updateItemsFoundGame1Action1,
    updateItemsSelectedGame1Action1,
} from 'src/services/reducers/games/game1Action1Slice';
import {
    resetItemsFoundGame1Action2,
    resetItemsSelectedGame1Action2,
    updateItemsFoundGame1Action2,
    updateItemsSelectedGame1Action2,
} from 'src/services/reducers/games/game1Action2Slice';
import {
    updateGameResult,
    updateGameStatus,
    updateIsAudioPlaying,
} from 'src/services/reducers/gameSlice';

// utils
import {
    type OverlayDescription,
    updateCurrentOverlayDescriptions,
    updateCurrentOverlayIndex,
} from 'src/services/reducers/overlaySlice';
import { selectRoomName } from 'src/services/reducers/roomSlice';

import { changeValue } from 'src/services/reducers/dialSlice';
import {
    addEmotionToItemsSelected,
    resetItemsFoundGame2,
    resetItemsSelectedGame2,
    updateIsConfirmSelectionButton,
    updateItemsFoundGame2,
    updateItemsSelectedGame2,
} from 'src/services/reducers/games/game2Slice';
import {
    resetItemsFoundGame3,
    resetItemsSelectedGame3,
    updateItemsFoundGame3,
    updateItemsSelectedGame3,
    updateStatusTetris,
} from 'src/services/reducers/games/game3Slice';
import {
    resetItemsFoundGame4,
    resetItemsSelectedGame4,
    updateCarouselValues,
    updateItemsFoundGame4,
    updateItemsSelectedGame4,
} from 'src/services/reducers/games/game4Slice';
import {
    resetItemsSelectedGame5,
    updateCurrentSituation,
    updateItemsSelectedGame5,
} from 'src/services/reducers/games/game5Slice';
import { getAreAllDevicesConnected } from 'src/utils/device/deviceUtils';
import { handleSendGameStatus } from 'src/utils/global/globalHandleFunctions';
import {
    type Behavior,
    type Emotion,
    type Game1Action1,
    type Game1Action2,
    type Game2Action,
    type Game3Action,
    type Game4Action,
    type Game5Action,
    GameStatus,
    type OverlayType,
    type ResponseSituation,
    type Situation,
    type Stimulus,
    type _Symbol,
    isDevelopperMode,
} from 'src/utils/global/types';

const App: React.FC = () => {
    const dispatch = useAppDispatch();
    const areAllDevicesConnected = useAppSelector(getAreAllDevicesConnected);
    const roomName = useAppSelector(selectRoomName);

    const [room, setRoom] = useState('');

    // sockets
    const socketService = SocketService.getInstance();
    socketService.connect(import.meta.env.VITE_BACKEND_URL as string);
    const socket = socketService.getSocket();

    const handleStartGameDevMode = useCallback(
        (deviceColor: string) => {
            dispatch(
                updateDeviceConnectedStatus({
                    deviceColor,
                    status: true,
                })
            );
            handleSendGameStatus(GameStatus.WELCOME_SCREEN, roomName);
            const responsesSocket = {
                roomName: 'roomTest',
                color: deviceColor,
            };
            socket?.emit('send_start_game', responsesSocket);
        },
        [dispatch, socket, roomName]
    );

    useEffect(() => {
        socket?.emit('join_room', roomName);
        socket?.on(
            'receive_start_game',
            (data: { roomName: string; color: string }) => {
                dispatch(
                    updateDeviceConnectedStatus({
                        deviceColor: data.color,
                        status: true,
                    })
                );
            }
        );
        socket?.on('receive_game_status', (data: { status: GameStatus }) => {
            dispatch(updateGameStatus(data.status));
        });
        socket?.on(
            'receive_device_activated_status',
            (data: { roomName: string; deviceColor: string; status: boolean }) => {
                dispatch(
                    updateDeviceActivatedStatus({
                        deviceColor: data.deviceColor,
                        status: data.status,
                    })
                );
            }
        );
        socket?.on('receive_reset', () => {
            location.reload();
        });
        socket?.on(
            'receive_is_audio_playing',
            (data: { roomName: string; isAudioPlaying: boolean }) => {
                dispatch(updateIsAudioPlaying(data.isAudioPlaying));
            }
        );
        socket?.on(
            'receive_update_overlay_index',
            (data: { roomName: string; overlayIndex: number }) => {
                dispatch(updateCurrentOverlayIndex(data.overlayIndex));
            }
        );
        socket?.on(
            'receive_update_overlay_descriptions',
            (data: {
                roomName: string;
                overlayDescriptions: OverlayDescription[];
                overlayType: OverlayType;
            }) => {
                dispatch(
                    updateCurrentOverlayDescriptions({
                        overlayDescriptions: data.overlayDescriptions,
                        type: data.overlayType,
                    })
                );
                dispatch(updateGameResult(''));
            }
        );
        socket?.on(
            'receive_reset_items_selected',
            (data: { roomName: string; gameStatus: GameStatus }) => {
                switch (data.gameStatus) {
                    case GameStatus.GAME1ACTION1:
                        dispatch(resetItemsSelectedGame1Action1());
                        break;
                    case GameStatus.GAME1ACTION2:
                        dispatch(resetItemsSelectedGame1Action2());
                        break;
                    case GameStatus.GAME2ACTION:
                        dispatch(resetItemsSelectedGame2());
                        dispatch(addEmotionToItemsSelected());
                        break;
                    case GameStatus.GAME3ACTION:
                        dispatch(resetItemsSelectedGame3());
                        break;
                    case GameStatus.GAME4ACTION:
                        dispatch(resetItemsSelectedGame4());
                        break;
                    case GameStatus.GAME5ACTION:
                        dispatch(resetItemsSelectedGame5());
                        break;
                    default:
                        break;
                }
            }
        );
        socket?.on(
            'receive_update_item_found_game1action1',
            (data: { roomName: string; itemFound: Emotion }) => {
                dispatch(updateItemsFoundGame1Action1(data.itemFound));
            }
        );
        socket?.on(
            'receive_update_item_selected',
            (data: {
                roomName: string;
                item: Emotion | _Symbol | Stimulus | Behavior | Situation;
                itemKey: string;
                gameStatus: GameStatus;
            }) => {
                switch (data.gameStatus) {
                    case GameStatus.GAME1ACTION1:
                        dispatch(
                            updateItemsSelectedGame1Action1({
                                item: data.item as Emotion | _Symbol,
                                itemKey: data.itemKey as keyof Game1Action1,
                            })
                        );
                        break;
                    case GameStatus.GAME1ACTION2:
                        dispatch(
                            updateItemsSelectedGame1Action2({
                                item: data.item as Emotion,
                                itemKey: data.itemKey as keyof Game1Action2,
                            })
                        );
                        break;
                    case GameStatus.GAME2ACTION:
                        dispatch(
                            updateItemsSelectedGame2({
                                item: data.item as Stimulus,
                                itemKey: data.itemKey as keyof Game2Action,
                            })
                        );
                        break;
                    case GameStatus.GAME3ACTION:
                        dispatch(
                            updateItemsSelectedGame3({
                                item: data.item as Emotion,
                                itemKey: data.itemKey as keyof Game3Action,
                            })
                        );
                        break;
                    case GameStatus.GAME4ACTION:
                        dispatch(
                            updateItemsSelectedGame4({
                                item: data.item as Behavior,
                                itemKey: data.itemKey as keyof Game4Action,
                            })
                        );
                        break;
                    case GameStatus.GAME5ACTION:
                        dispatch(
                            updateItemsSelectedGame5({
                                item: data.item as ResponseSituation,
                                itemKey: data.itemKey as keyof Game5Action,
                            })
                        );
                        break;
                    default:
                        break;
                }
            }
        );
        socket?.on(
            'receive_update_item_found_game1action2',
            (data: { roomName: string; itemFound: Emotion }) => {
                dispatch(updateItemsFoundGame1Action2(data.itemFound));
            }
        );

        socket?.on(
            'receive_update_is_confirm_selection_button',
            (data: { roomName: string; status: boolean }) => {
                dispatch(
                    updateIsConfirmSelectionButton({
                        status: data.status,
                    })
                );
            }
        );
        socket?.on(
            'receive_update_validation',
            (data: { roomName: string; result: 'success' | 'error' | '' }) => {
                dispatch(updateGameResult(data.result));
            }
        );
        socket?.on(
            'receive_update_item_found_game2',
            (data: { roomName: string; itemFound: Emotion }) => {
                dispatch(updateItemsFoundGame2(data.itemFound));
            }
        );
        socket?.on(
            'receive_dial_value',
            (data: { roomName: string; value: string }) => {
                dispatch(changeValue(data.value));
            }
        );
        socket?.on(
            'receive_update_item_found_game4',
            (data: { roomName: string; itemFound: Behavior }) => {
                dispatch(updateItemsFoundGame4(data.itemFound));
            }
        );
        socket?.on(
            'receive_update_status_tetris',
            (data: { roomName: string; statusTetris: string }) => {
                dispatch(updateStatusTetris(data.statusTetris));
            }
        );
        socket?.on(
            'receive_update_item_found_game3',
            (data: { roomName: string; itemFound: Emotion }) => {
                dispatch(updateItemsFoundGame3(data.itemFound));
            }
        );
        socket?.on('receive_update_add_emotion_to_items_selected', () => {
            dispatch(addEmotionToItemsSelected());
        });
        socket?.on(
            'receive_update_situation_game_5',
            (data: { roomName: string; situationIndex: number }) => {
                dispatch(updateCurrentSituation(data.situationIndex));
            }
        );
        socket?.on(
            'receive_carousel_values',
            (data: {
                roomName: string;
                value: number;
                key: 'rightIndex' | 'centerIndex' | 'leftIndex' | 'flowDirection';
            }) => {
                dispatch(
                    updateCarouselValues({
                        value: data.value,
                        key: data.key,
                    })
                );
            }
        );
        socket?.on(
            'receive_reset_items_found',
            (data: { roomName: string; gameStatus: GameStatus }) => {
                switch (data.gameStatus) {
                    case GameStatus.GAME1ACTION1:
                        dispatch(resetItemsFoundGame1Action1());
                        break;
                    case GameStatus.GAME1ACTION2:
                        dispatch(resetItemsFoundGame1Action2());
                        break;
                    case GameStatus.GAME2ACTION:
                        dispatch(resetItemsFoundGame2());
                        break;
                    case GameStatus.GAME3ACTION:
                        dispatch(resetItemsFoundGame3());
                        break;
                    case GameStatus.GAME4ACTION:
                        dispatch(resetItemsFoundGame4());
                        break;
                    case GameStatus.GAME5ACTION:
                        dispatch(resetItemsFoundGame4());
                        break;
                    default:
                        break;
                }
            }
        );
        socket?.on(
            'receive_update_language',
            (data: { roomName: string; language: string }) => {
                i18n.changeLanguage(data.language);
            }
        );
        return () => {
            socket?.off('receive_start_game');
            socket?.off('receive_game_status');
            socket?.off('receive_device_activated_status');
            socket?.off('receive_reset');
            socket?.off('receive_is_audio_playing');
            socket?.off('receive_update_overlay_index');
            socket?.off('receive_update_overlay_descriptions');
            socket?.off('receive_reset_items_selected');
            socket?.off('receive_update_item_found_game1action1');
            socket?.off('receive_update_item_selected');
            socket?.off('receive_update_item_found_game1action2');
            socket?.off('receive_update_is_confirm_selection_button');
            socket?.off('receive_update_validation');
            socket?.off('receive_update_item_found_game2');
            socket?.off('receive_dial_value');
            socket?.off('receive_update_item_found_game4');
            socket?.off('receive_update_status_tetris');
            socket?.off('receive_update_add_emotion_to_items_selected');
            socket?.off('receive_carousel_values');
            socket?.off('receive_reset_items_found');
            socket?.off('receive_update_language');
        };
    }, [dispatch, roomName, socket]);

    // If developper mode is on, it sets the room to roomTest then connects the devices
    useEffect(() => {
        if (isDevelopperMode) {
            setRoom('roomTest');
            const devices = ['tv', 'red', 'blue', 'green', 'orange', 'mediator'];
            devices.forEach((device) => {
                handleStartGameDevMode(device);
            });
        }
    }, [handleStartGameDevMode]);

    // When all devices are connected, it updates the state of the game
    useEffect(() => {
        if (areAllDevicesConnected) {
            dispatch(updateGameStatus(GameStatus.WELCOME_SCREEN));
        }
    }, [areAllDevicesConnected, dispatch, roomName]);

    const router = createBrowserRouter([
        {
            path: '/tv',
            element: room ? (
                <Tv />
            ) : (
                <CreateRoom type="tv" onRoomIdChosen={setRoom} />
            ),
        },
        {
            path: '/red-tablet',
            element: room ? (
                <RedTablet />
            ) : (
                <CreateRoom type="red" onRoomIdChosen={setRoom} />
            ),
        },
        {
            path: '/blue-tablet',
            element: room ? (
                <BlueTablet />
            ) : (
                <CreateRoom type="blue" onRoomIdChosen={setRoom} />
            ),
        },
        {
            path: '/green-tablet',
            element: room ? (
                <GreenTablet />
            ) : (
                <CreateRoom type="green" onRoomIdChosen={setRoom} />
            ),
        },
        {
            path: '/orange-tablet',
            element: room ? (
                <OrangeTablet />
            ) : (
                <CreateRoom type="orange" onRoomIdChosen={setRoom} />
            ),
        },
        {
            path: '/mediator-tablet',
            element: room ? (
                <MediatorTablet />
            ) : (
                <CreateRoom type="mediator" onRoomIdChosen={setRoom} />
            ),
        },
    ]);

    return <RouterProvider router={router} />;
};

export default App;
