import styles from 'src/features/devices/tv/components/itemBox/itemBoxTv.module.scss';
import stylesBox from 'src/features/devices/tv/components/itemBox/itemBox.module.scss';

import { motion } from 'framer-motion';
import { imageVariants } from 'src/utils/global/types';

interface ItemBoxProps {
    image: string;
    text: string;
    gameResult: 'success' | 'error' | '';
}

const ItemBoxTv: React.FC<ItemBoxProps> = ({ image, text, gameResult }) => {
    const imageName = image.split('/').pop()?.split('.')[0];
    const isSymbol = imageName?.includes('symbol');

    return (
        <div className={styles.itemBoxContainer}>
            <div
                className={`${styles[imageName ?? '']} ${
                    isSymbol && styles.symbol
                } ${styles[gameResult]}`}
            >
                <motion.img
                    src={image}
                    variants={imageVariants}
                    initial="initial"
                    animate="animate"
                />
            </div>
            <p className={gameResult ? stylesBox[gameResult] : undefined}>{text}</p>
        </div>
    );
};

export default ItemBoxTv;
