import { useAppSelector } from 'src/services/hooks';

// scss
import styles from 'src/features/devices/tablets/components/game1action2/strongWeakEmotion.module.scss';

// components
import TabletScreen from 'src/features/devices/tablets/components/screens/TabletScreen';

// datas
import dataGlobal from 'src/services/datas/global.json';

// utils
import { handleItemSelected } from 'src/utils/device/deviceHandleFunctions';
import {
    Device,
    type Emotion,
    type EmotionSecondary,
    type ItemsSelected,
} from 'src/utils/global/types';

// services
import { useEffect, useState } from 'react';
import { Stars } from 'src/assets/sounds/soundsEffect';
import StrongWeakEmotion from 'src/features/devices/tablets/components/game1action2/StrongWeakEmotion';
import { selectItemsSelectedGame1Action2 } from 'src/services/reducers/games/game1Action2Slice';
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import { selectRoomName } from 'src/services/reducers/roomSlice';
import { playSound } from 'src/utils/global/globalHandleFunctions';

interface ChooseIntensityEmotionProps {
    intensity: 'weakEmotion' | 'strongEmotion';
}
const ChooseIntensityEmotion: React.FC<ChooseIntensityEmotionProps> = ({
    intensity,
}) => {
    const roomName = useAppSelector(selectRoomName);
    const itemsSelected = useAppSelector(selectItemsSelectedGame1Action2);
    const gameStatus = useAppSelector(selectCurrentGameStatus);
    const deviceColor = intensity === 'weakEmotion' ? Device.orange : Device.green;

    const handleClickBox = (item: ItemsSelected) => {
        handleItemSelected(item, intensity, gameStatus, roomName);
    };

    const [shuffledEmotions, setShuffledEmotions] = useState<EmotionSecondary[]>();

    useEffect(() => {
        const intensityMap = {
            weakEmotion: dataGlobal.GAME1ACTION2.weakEmotion,
            strongEmotion: dataGlobal.GAME1ACTION2.strongEmotion,
        };
        setShuffledEmotions(intensityMap[intensity]);
    }, [intensity]);

    useEffect(() => {
        setShuffledEmotions((prevEmotions) => {
            if (prevEmotions) {
                return [...prevEmotions].sort(() => Math.random() - 0.5);
            }
            return [];
        });
    }, []);

    return (
        <TabletScreen
            step="action"
            deviceColor={deviceColor}
            typeBackground="empty"
        >
            <div className={styles.itemBoxContainer}>
                {shuffledEmotions?.map((emotion) => {
                    return (
                        <StrongWeakEmotion
                            key={emotion.id}
                            item={emotion as Emotion}
                            handleClick={() => {
                                handleClickBox(emotion as ItemsSelected);
                                playSound(Stars);
                            }}
                            isSelected={
                                emotion.id === itemsSelected?.[intensity]?.id
                            }
                        />
                    );
                })}
            </div>
        </TabletScreen>
    );
};

export default ChooseIntensityEmotion;
