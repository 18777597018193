import { type Dispatch, useReducer } from 'react';
import dataGlobal from 'src/services/datas/global.json';
import {
    type Block,
    BlockLetter,
    type BlockShape,
    type BoardShape,
    type BoardState,
    EmptyCell,
    SHAPES,
} from 'src/utils/global/types';

export const BOARD_WIDTH = 10;
export const BOARD_HEIGHT = 10;
const INITIAL_COLUMN = 3;
const INITIAL_ROW = 0;
const INITIAL_BLOCK = {
    tetris: BlockLetter.I,
    character: 'eric',
};
const INITIAL_SHAPE = SHAPES.I.shape;

type Action = {
    type:
        | 'start'
        | 'drop'
        | 'commit'
        | 'move'
        | 'waiting-validation'
        | 'tetris-auto-completed';
    newBoard?: BoardShape;
    newBlock?: Block;
    isPressingLeft?: boolean;
    isPressingRight?: boolean;
    isRotating?: boolean;
    firstBlock?: Block;
    emotionId?: number;
};

export const initializeEmptyBoard = (height = BOARD_HEIGHT): BoardShape => {
    return Array(height)
        .fill(null)
        .map(() => Array(BOARD_WIDTH).fill(EmptyCell.Empty));
};

const getInitialState = (): BoardState => {
    return {
        board: initializeEmptyBoard(),
        droppingRow: INITIAL_ROW,
        droppingColumn: INITIAL_COLUMN,
        droppingBlock: INITIAL_BLOCK,
        droppingShape: INITIAL_SHAPE,
    };
};

export const useTetrisBoard = (): [BoardState, Dispatch<Action>] => {
    const [boardState, dispatchBoardState] = useReducer(
        boardReducer,
        getInitialState()
    );
    return [boardState, dispatchBoardState];
};

export const hasCollisions = (
    board: BoardShape,
    currentShape: BlockShape,
    row: number,
    column: number
): boolean => {
    return currentShape
        .filter((shapeRow) => shapeRow.some((isSet) => isSet))
        .some((shapeRow: boolean[], rowIndex: number) =>
            shapeRow.some(
                (isSet: boolean, colIndex: number) =>
                    isSet &&
                    (row + rowIndex >= board?.length ||
                        column + colIndex >= board[0]?.length ||
                        column + colIndex < 0 ||
                        board[row + rowIndex][column + colIndex] !==
                            EmptyCell.Empty)
            )
        );
};

const rotateBlock = (shape: BlockShape): BlockShape => {
    const columns = shape[0].length;

    return shape[0].map((_, column) =>
        shape.map((_, row) => shape[row][columns - 1 - column])
    );
};

const handleStart = (action: Action): BoardState => {
    return {
        board: initializeEmptyBoard(),
        droppingRow: INITIAL_ROW,
        droppingColumn: INITIAL_COLUMN,
        droppingBlock: action.firstBlock!,
        droppingShape: SHAPES[action.firstBlock!.tetris].shape,
    };
};

const handleDrop = (state: BoardState): BoardState => {
    const newState = { ...state };
    newState.droppingRow++;
    return newState;
};

const handleCommit = (action: Action): BoardState => {
    return {
        board: [
            ...initializeEmptyBoard(BOARD_HEIGHT - action.newBoard!.length),
            ...action.newBoard!,
        ],
        droppingRow: INITIAL_ROW,
        droppingColumn: INITIAL_COLUMN,
        droppingBlock: action.newBlock!,
        droppingShape: SHAPES[action!.newBlock!.tetris].shape,
    };
};

const handleMove = (
    state: BoardState,
    rotatedShape: BlockShape,
    columnOffset: number
): BoardState => {
    const newState = { ...state };
    if (
        !hasCollisions(
            newState.board,
            rotatedShape,
            newState.droppingRow,
            newState.droppingColumn + columnOffset
        )
    ) {
        newState.droppingColumn += columnOffset;
        newState.droppingShape = rotatedShape;
    }
    return newState;
};
const handleCompletionTetris = (
    state: BoardState,
    emotionId: number | undefined
): BoardState => {
    const newState = { ...state };

    const goodBoard = dataGlobal.GAME3ACTION.goodCombinations.find(
        (element) => element.emotionId === emotionId
    )?.goodBoard[0];

    return {
        ...newState,
        board: goodBoard as unknown as BoardShape,
    };
};

const boardReducer = (state: BoardState, action: Action): BoardState => {
    const newState = { ...state };

    const rotatedShape = action.isRotating
        ? rotateBlock(newState.droppingShape)
        : newState.droppingShape;
    let columnOffset = action.isPressingLeft ? -1 : 0;
    columnOffset = action.isPressingRight ? 1 : columnOffset;
    switch (action.type) {
        case 'start':
            return handleStart(action);
        case 'drop':
            return handleDrop(state);
        case 'commit':
            return handleCommit(action);
        case 'move':
            return handleMove(state, rotatedShape, columnOffset);
        case 'tetris-auto-completed':
            return handleCompletionTetris(state, action.emotionId);
        default:
            break;
    }
    return newState;
};
