import { type RootState } from 'src/services/store';

export const getAreAllDevicesConnected = (state: RootState): boolean => {
    const { red, blue, green, orange, tv, mediator } = state.device.deviceStatus;
    return (
        red.isConnected &&
        blue.isConnected &&
        green.isConnected &&
        orange.isConnected &&
        tv.isConnected &&
        mediator.isConnected
    );
};

export const isObjectEmpty = (obj?: object): boolean => {
    if (!obj) return true;
    return Object.keys(obj).length === 0;
};
