import { motion } from 'framer-motion';
import { DeleteDialButton } from 'src/assets/images/tablets';
import { PadClick } from 'src/assets/sounds/soundsEffect';
import styles from 'src/features/reusablecomponents/global/dial/digitKeyboard.module.scss';
import { useAppSelector } from 'src/services/hooks';
import { selectCodeInputMaxLength } from 'src/services/reducers/dialSlice';
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import { selectItemsSelectedGame4 } from 'src/services/reducers/games/game4Slice';
import { selectRoomName } from 'src/services/reducers/roomSlice';
import { handleItemSelected } from 'src/utils/device/deviceHandleFunctions';
import { playSound } from 'src/utils/global/globalHandleFunctions';
import { type ItemsSelected } from 'src/utils/global/types';

const KEYBOARD_SIZE = 10;

const DigitKeyboard: React.FC = () => {
    const roomName = useAppSelector(selectRoomName);
    const gameStatus = useAppSelector(selectCurrentGameStatus);
    const maxCodeLength = useAppSelector(selectCodeInputMaxLength);
    const itemsSelected = useAppSelector(selectItemsSelectedGame4);
    const itemsSelectedCode = itemsSelected.code || '';

    const handleStyleSelectedDial = (number: string) => {
        if (itemsSelectedCode?.includes(number)) {
            return styles.selected;
        }
    };
    const handleClick = (type: 'add' | 'remove', number?: string) => {
        let code;
        if (type === 'add' && number) {
            code = itemsSelectedCode?.concat(number);
        }
        if (type === 'remove') {
            code = itemsSelectedCode?.slice(0, -1);
        }
        handleItemSelected(code as ItemsSelected, 'code', gameStatus, roomName);
    };

    return (
        <div className={styles.padContainer}>
            {Array.from(Array(KEYBOARD_SIZE).keys()).map((number) => {
                if (number === 0) {
                    return;
                }
                return (
                    <motion.button
                        disabled={itemsSelectedCode?.length === maxCodeLength}
                        className={`${styles.button}
                        ${handleStyleSelectedDial(number.toString())}

                        `}
                        key={number}
                        whileTap={{
                            scale: 0.8,
                        }}
                        onClick={() => {
                            handleClick('add', number.toString());
                            playSound(PadClick);
                        }}
                    >
                        <span>{number}</span>
                    </motion.button>
                );
            })}
            <motion.button
                disabled={itemsSelectedCode?.length === maxCodeLength}
                className={`${styles.button} ${handleStyleSelectedDial('0')}`}
                whileTap={{
                    scale: 0.8,
                }}
                onClick={() => {
                    handleClick('add', '0');
                }}
            >
                <span>0</span>
            </motion.button>
            <motion.button
                className={`${styles.button}`}
                whileTap={{
                    scale: 0.8,
                    background:
                        'radial-gradient(109698.01% 100% at 50.01% 0%,#86ede7 0%,#3aa8a1 100%)',
                }}
                onClick={() => {
                    handleClick('remove');
                }}
            >
                <img src={DeleteDialButton} alt="supprimer entrée" />
            </motion.button>
        </div>
    );
};

export default DigitKeyboard;
