import type React from 'react';
import { HeaderTop } from 'src/assets/images/tablets';
import styles from 'src/features/devices/tablets/components/header/header.module.scss';

interface HeaderProps {
    title?: string;
    text?: string;
}

const Header: React.FC<HeaderProps> = ({ title, text }) => {
    return (
        <div className={styles.headerContainer}>
            <img src={HeaderTop} alt="" />
            <h1>{title}</h1>
            <p>{text}</p>
            <img src={HeaderTop} alt="" />
        </div>
    );
};

export default Header;
