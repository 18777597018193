import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from 'src/services/store';

// services
import headerDatas from 'src/services/datas/actionContent.json';
import dataGlobal from 'src/services/datas/global.json';

// utils
import {
    GameStatus,
    type Emotion,
    type Game1Action2,
    type HeaderContent,
    type Item,
} from 'src/utils/global/types';

interface UpdateItemsSelectedPayload {
    item: Item;
    itemKey: keyof Game1Action2;
}

export interface Game1Action2Slice {
    itemsSelected: Game1Action2;
    itemsFound: Item[];
    headerContent: HeaderContent;
}

const initialState: Game1Action2Slice = {
    itemsSelected: {} as Game1Action2,
    itemsFound: new Array(dataGlobal.emotions.length).fill(null),
    headerContent: headerDatas[GameStatus.GAME1ACTION2].tv.headerContent,
};

export const Game1Action2Slice = createSlice({
    name: 'game1Action2',
    initialState,
    reducers: {
        updateItemsSelectedGame1Action2: (
            state,
            action: PayloadAction<UpdateItemsSelectedPayload>
        ) => {
            const { item, itemKey } = action.payload;
            state.itemsSelected = {
                ...state.itemsSelected,
                [itemKey]: item,
            };
        },
        resetItemsSelectedGame1Action2: (state) => {
            state.itemsSelected = initialState.itemsSelected;
        },
        updateItemsFoundGame1Action2: (state, action: PayloadAction<Emotion>) => {
            const indexItem = action.payload.id - 1;
            state.itemsFound[indexItem] = action.payload;
        },
        resetItemsFoundGame1Action2: (state) => {
            state.itemsFound = initialState.itemsFound;
        },
    },
});

export const {
    updateItemsSelectedGame1Action2,
    resetItemsSelectedGame1Action2,
    updateItemsFoundGame1Action2,
    resetItemsFoundGame1Action2,
} = Game1Action2Slice.actions;

export const selectItemsFoundGame1Action2 = (state: RootState): Item[] =>
    state.game1Action2.itemsFound;

export const selectItemsSelectedGame1Action2 = (state: RootState): Game1Action2 =>
    state.game1Action2.itemsSelected;

export default Game1Action2Slice.reducer;
