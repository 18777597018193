import type React from 'react';
import { useAppSelector } from 'src/services/hooks';
import { selectRoomName } from 'src/services/reducers/roomSlice';
import styles from 'src/features/devices/tablets/mediator/mediatorAction.module.scss';
import {
    handleCarouselClick,
    handleMouse,
} from 'src/utils/device/deviceHandleFunctions';
import {
    selectCarouselValues,
    selectListBehaviors,
} from 'src/services/reducers/games/game4Slice';
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import { type ItemsSelected } from 'src/utils/global/types';

interface ButtonsDirectionsProps {
    remoteName: 'tetris' | 'carousel';
    itemsSelected?: ItemsSelected | undefined;
}
const ButtonsDirections: React.FC<ButtonsDirectionsProps> = ({
    remoteName,
    itemsSelected,
}) => {
    const roomName = useAppSelector(selectRoomName);
    const gameStatus = useAppSelector(selectCurrentGameStatus);

    const { rightIndex, centerIndex, leftIndex } =
        useAppSelector(selectCarouselValues);
    const listBehaviors = useAppSelector(selectListBehaviors);

    const buttonConfigurations = {
        tetris: [
            { type: 'left', label: 'Gauche' },
            { type: 'up', label: 'Rotation' },
            { type: 'right', label: 'Droite' },
            // disable speed until bug is fixed
            // { type: 'down', label: 'Speed' },
        ],
        carousel: [
            { type: 'previous', label: 'Gauche' },
            { type: 'next', label: 'Droite' },
        ],
    };
    return (
        <div className={styles.mediatorAction}>
            {buttonConfigurations[remoteName].map((button) => (
                <button
                    key={button.type}
                    className={styles.tertiary}
                    onMouseDown={
                        remoteName === 'tetris'
                            ? () => handleMouse('down', button.type, roomName)
                            : () =>
                                  handleCarouselClick(
                                      button.type,
                                      itemsSelected as ItemsSelected,
                                      gameStatus,
                                      listBehaviors,
                                      leftIndex,
                                      centerIndex,
                                      rightIndex,
                                      roomName
                                  )
                    }
                    onMouseUp={
                        remoteName === 'tetris'
                            ? () => handleMouse('up', button.type, roomName)
                            : undefined
                    }
                >
                    {button.label}
                </button>
            ))}
        </div>
    );
};

export default ButtonsDirections;
