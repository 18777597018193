import { useCallback, useEffect, useState } from 'react';
import Banner from 'src/features/devices/tablets/components/banner/Banner';
import Header from 'src/features/devices/tablets/components/header/Header';
import CircleAnimationScreen from 'src/features/devices/tablets/components/screens/CircleAnimationScreen';
import styles from 'src/features/devices/tablets/components/screens/tabletScreen.module.scss';
import {
    type Device,
    type HeaderContent,
    type TypeBackground,
} from 'src/utils/global/types';

import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/services/hooks';
import { selectCurrentGameStatus } from 'src/services/reducers/gameSlice';
import { selectCurrentOverlayType } from 'src/services/reducers/overlaySlice';

interface TabletScreenProps {
    typeBackground: TypeBackground;
    header?: HeaderContent;
    deviceColor?: Device;
    step?: 'overlay' | 'action';
    children?: React.ReactNode;
}

const TabletScreen: React.FC<TabletScreenProps> = ({
    typeBackground,
    header,
    deviceColor,
    step,
    children,
}) => {
    const currentGameStatus = useAppSelector(selectCurrentGameStatus);
    const { t } = useTranslation(['global', 'overlayContent', 'actionContent']);

    const [headerContent, setHeaderContent] = useState<{
        title: string | undefined;
        text: string | undefined;
    }>({
        title: '',
        text: '',
    });

    const overlayType = useAppSelector(selectCurrentOverlayType);

    const getHeaderContentForOverlay = useCallback((): void => {
        if (!deviceColor) return;
        setHeaderContent({
            title: t(`${currentGameStatus}.${deviceColor}.headerContent.title`, {
                ns: 'overlayContent',
            }),
            text: t(`${currentGameStatus}.${deviceColor}.headerContent.text`, {
                ns: 'overlayContent',
            }),
        });
    }, [currentGameStatus, deviceColor, t]);

    const getHeaderContentForAction = useCallback(() => {
        if (!deviceColor) return;
        if (overlayType === 'error' || overlayType === 'success') {
            // if message error or success while action
            setHeaderContent({
                title: t(`headers.${overlayType}.title`),
                text: t(`headers.${overlayType}.text`),
            });
        } else {
            // if gameplay enigma and overlayType is neutral
            setHeaderContent({
                title: t(
                    `${currentGameStatus}.${deviceColor}.headerContent.title`,
                    { ns: 'actionContent' }
                ),
                text: t(`${currentGameStatus}.${deviceColor}.headerContent.text`, {
                    ns: 'actionContent',
                }),
            });
        }
    }, [currentGameStatus, deviceColor, overlayType, t]);

    useEffect(() => {
        if (header) {
            setHeaderContent({
                title: header.title,
                text: header.text,
            });
        }
        if (step === 'overlay') {
            // step overlay is either introduction or prevent or unlock page
            getHeaderContentForOverlay();
        } else if (step === 'action') {
            // step action is for gameplay enigma
            getHeaderContentForAction();
        }
    }, [header, step, getHeaderContentForAction, getHeaderContentForOverlay]);

    return (
        <div className={styles.tabletScreenContainer}>
            <Header title={headerContent.title} text={headerContent.text} />
            {typeBackground === 'banner' && <Banner>{children}</Banner>}
            {typeBackground === 'circle' && (
                <CircleAnimationScreen>{children}</CircleAnimationScreen>
            )}
            {typeBackground !== 'banner' && typeBackground !== 'circle' && children}
        </div>
    );
};

export default TabletScreen;
